let uniqueField = 'documentsGrid';
import Axios from '@/misc/axios';

let l = (key) => {
  // @TODO: Implement localization
  return key;
}

export default (premiseId) => {
  return {
    endpoint: process.env.VUE_APP_FRONTENDV2_API_URL + `/rem/premise/${premiseId}/documents`,
    idkey: "id",
    defaultOrderKey: 'id',
    defaultOrderDirection: 'desc',
    orderLocal: false,
    rootkey: "content",
    pagination: {
      gridUniqueId: uniqueField,
      active: true,
      page: 0,
      size: 10,
      // possiblePageSizes: [10, 20, 50, 100]
    },
    filtering: {
      active: true,
      local: false,
      //simple: true,
      //simpleHelpText: l('rvg-simple-filter-help-text'),
    },
    bulkOperation: {
      active: false
    },
    columnSelector: {
      active: true,
      gridUniqueId: uniqueField,
      mode: 'server', // server or local/null
      serverGridKey: 'worksGridColumns'
    },
    xlsxExport: {
      active: true
    },
    refreshable: {
      manualActive: true,
      autoActive: true,
      autoCanBeDisabled: true,
      gridUniqueId: uniqueField,
      autoValues: [{
        key: '30sec',
        refreshInterval: 30,
        title: l('rvg-automatic-list-refresh-30-sec'),
        default: true
      }, {
        key: '1min',
        refreshInterval: 60,
        title: l('rvg-automatic-list-refresh-1-min')
      }, {
        key: '5min',
        refreshInterval: 300,
        title: l('rvg-automatic-list-refresh-5-min')
      }]
    },
    mapping: {
      "$operations": {
        title: "",
        visible: true,
        filterable: false,
        selectable: false,
        orderable: false,
        width: '50px',
        formatter: {
          type: 'Operations',
          config: {
            buttons: (vm, callMeToRefreshTheGrid, rowid, record) => {
              var buttons = [];

              buttons.push({
                title: '',
                tooltip: 'Edit property',
                icon: '<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-4"><path stroke-linecap="round" stroke-linejoin="round" d="M12 3L2 12H5V20H10V14H14V15.11L19.43 9.68L12 3M21.04 11.14C20.9 11.14 20.76 11.2 20.65 11.3L19.65 12.3L21.7 14.35L22.7 13.35C22.91 13.14 22.91 12.79 22.7 12.58L21.42 11.3C21.32 11.2 21.18 11.14 21.04 11.14M19.06 12.88L13 18.94V21H15.06L21.11 14.93L19.06 12.88Z" /></svg>',
                classList: 'btn btn-secondary btn-circle btn-sm hover:!opacity-80',
                testValueAttribute: 'propertyEditButton',
                dropdowned: false,
                //disabled: record.endDate ? false : true,
                onClick: () => {
                  
                  //console.log(record.id); 
                  Axios.get(process.env.VUE_APP_FRONTENDV2_API_URL + `/rem/document/${record.id}`).then(
                    (resp) => {console.log(resp)}
                  )
                }
              })

              return buttons;
            }
          }
        }
      },
      "id": {
        title: "id",
        visible: true,
        selectable: false, // non-optional, must be selected
        filterable: {
          active: true,
          type: 'Number'
        },
        exportable: true,
        orderable: true,
      },
      "document.name": {
        title: "Dokumentum neve",
        visible: true,
        selectable: true, // non-optional, must be selected
        filterable: {
          active: false,
          type: 'Text'
        },
        exportable: true,
        orderable: false,
        middleware: (value, record) => {
          return record.name ? record.name : 'N/A'
        }
      },
      "document.mainType": {
        title: "Főtípus",
        visible: true,
        selectable: true, // non-optional, must be selected
        filterable: {
          active: false,
          type: 'Text'
        },
        exportable: true,
        orderable: false,
        middleware: (value, record) => {    
          return record.typeId ? record.typeId : 'N/A'
        },             
      },
      "document.subType": {
        title: "Altípus",
        visible: true,
        selectable: true, // non-optional, must be selected
        filterable: {
          active: false,
          type: 'Text'
        },
        exportable: true,
        orderable: false,
        middleware: (value, record) => {    
          return record.subTypeId ? record.subTypeId : 'N/A'
        },             
      },
      "document.uploaderName": {
        title: "Feltöltő neve",
        visible: true,
        selectable: true, // non-optional, must be selected
        filterable: {
          active: false,
          type: 'Text'
        },
        exportable: true,
        orderable: false,
        middleware: (value, record) => {    
          return record.uploaderName ? record.uploaderName : 'N/A'
        },             
      },
      "document.uploadAt": {
        title: "Feltöltés dátuma",
        visible: true,
        selectable: true, // non-optional, must be selected
        filterable: {
          active: false,
          type: 'Text'
        },
        exportable: true,
        orderable: false,
        middleware: (value, record) => {    
          return record.uploadAt ? new Date(record.uploadAt).toLocaleString() : 'N/A'
        },             
      },
    }
  }
}
