<template>
  <div class="flex flex-row items-start justify-start text-left m-auto">
    <button :disabled="!data" @click="() => { modalShown = true }" data-test="openButton" class="btn disabled:opacity-40 h-8 w-8 !p-0 font-medium text-white bg-primary hover:bg-primary-focus focus:bg-primary-focus active:bg-primary-focus/90 dark:bg-accent dark:hover:bg-accent-focus dark:focus:bg-accent-focus dark:active:bg-accent/90" title="Megnyitás">
      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-6">
        <path stroke-linecap="round" stroke-linejoin="round" d="M8.25 6.75h12M8.25 12h12m-12 5.25h12M3.75 6.75h.007v.008H3.75V6.75Zm.375 0a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0ZM3.75 12h.007v.008H3.75V12Zm.375 0a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Zm-.375 5.25h.007v.008H3.75v-.008Zm.375 0a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Z" />
      </svg>
    </button>
    <CustomContentModal :show="modalShown" :title="config.formatter.title ? config.formatter.title : getLang('untitled')" cancelButtonTitle="" okButtonTitle="" :disableButtons="true" :close="() => { modalShown = false }">
      <template #content>
        <div>
          <table v-if="affectedColumns && affectedColumns.length > 0" class="w-full text-left text-tiny+">
            <thead>
              <tr class="border-y border-transparent border-b-slate-200 dark:border-b-navy-500">
                <th class="whitespace-nowrap px-2 py-3 font-semibold uppercase text-slate-800 dark:text-navy-100">
                  {{ getLang('auditlog_columnName') }}
                </th>
                <th class="whitespace-nowrap px-2 py-3 font-semibold uppercase text-slate-800 dark:text-navy-100">
                  {{ getLang('auditlog_oldValue') }}
                </th>
                <th class="whitespace-nowrap px-2 py-3 font-semibold uppercase text-slate-800 dark:text-navy-100">
                  {{ getLang('auditlog_newValue') }}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(column, key) in affectedColumns" :key="key" class="border-y border-transparent border-b-slate-200 dark:border-b-navy-500">
                <td class="whitespace-nowrap px-2 py-3 font-bold uppercase text-slate-4500">
                  {{ getLang(column + "_grid_title") }}
                </td>
                <td class="whitespace-nowrap px-2 py-3 ">
                  {{ getOldValue(column) }}
                </td>
                <td class="whitespace-nowrap px-2 py-3 ">
                  {{ getNewValue(column) }}
                </td>
              </tr>
            </tbody>
          </table>
          <div v-else>
            Nincs megjeleníthető adat.
          </div>
        </div>
      </template>
    </CustomContentModal>
  </div>
</template>

<script>
import CustomContentModal from '../Components/CustomContentModal.vue'
import l from '../Helpers/lang.js'

export default {
  name: "RVGJsonViewer",
  components: {
    CustomContentModal
  },
  props: ['data', 'config', 'rowid', 'record'],
  mounted() {
  },
  data() {
    return {
      modalShown: false
    }
  },
  computed: {
    affectedColumns() {
      try {
        return JSON.parse(this.record.affectedColumns)
      } catch (e) {
        return [];
      }
      
      // let cols = [];
      // try {
      //   let oldCols = JSON.parse(this.record.oldValues);
      //   let newCols = JSON.parse(this.record.newValues);

      //   if(!oldCols) { oldCols = {} }
      //   if(!newCols) { newCols = {} }

      //   oldCols = Object.keys(oldCols);
      //   newCols = Object.keys(newCols);

      //   let merged = [...oldCols ,...newCols];
      //   return [...new Set(merged)]
      // } catch (e) {
      //   console.log(e)
      //   return cols;
      // }
    }
  },
  methods: {
    getLang(key) {
      key = key.charAt(0).toLowerCase() + key.slice(1)
      return l(key)
    },
    getOldValue(key) {
      try {
        let oldValues = JSON.parse(this.record.oldValues);
        return oldValues[key];
      } catch (e) {
        return '';
      }
    },
    getNewValue(key) {
      try {
        let newValues = JSON.parse(this.record.newValues);
        return newValues[key];
      } catch (e) {
        return '';
      }
    },
  }
};
</script>

<style scoped>
</style>
