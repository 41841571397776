<template>
  <div id="BuildingPage" class="h-screen">
    <BaseLayout>
      <template #mainContent>
        <div class="p4 mt-4 rounded-xl px-8 w-full">
          <!--Header-->
          <header class="w-full shadow-sm body-font">
            <div class="items-start  pb-6 mx-auto md:flex-row mb-4">
              <div class="flex justify-between mb-4">
                <h1 class="flex mb text-xl text-secondary md:mb-0 uppercase font-bold">
                  Helyiség {{ premiseId }}
                </h1>

                <!-- <nav class="flex items-start mt-4 pl-3 text-gray-300 md:mr-auto">
                  <a
                    @click="toLevelSelector"
                    class="mr-5 font-medium hover:text-secondary hover:cursor-pointer"
                    >Alaprajz (siteDesign térképnézet)</a
                  >
                  <a href="#_" class="mr-5 font-medium hover:text-secondary"
                    >Energetika</a
                  >
                </nav> -->
                <RemUpButton remType="premise" />
              </div>
              <hr class="mb-8">
              <div class="flex ">
                <button @click="router.push({ name: 'energeticsPage' })"
                  class="flex btn btn-md btn-secondary  normal-case text-sm im-round-btn">{{
                    $t("message.energetics") }}</button>
              </div>
            </div>

          </header>

          <div class="flex md:flex-row md:columns-2 flex-col w-full ">
            <div
              class="flex flex-row md:flex-col im-sidemenu-contanier rounded-xl mb-4 md:mb-0 flex-wrap md:flex-nowrap w-full md:md:w-auto justify-center">
              <div class="flex-row mb-4">
                <label class="tooltip tooltip-bottom">
                  <a @click="() => { state.tab = 1;}" class="btn btn-md btn-secondary btn-circle normal-case text-sm " :class="{'im-button-active' : state.tab == 1}">
                    <div class="im-btn-icon">
                      <SVGRenderer svgKey="data_icon"></SVGRenderer>
                    </div>
                    <span class="im-btn-text">Alap adatok</span>
                  </a>
                </label>
              </div>

              <div class="flex-row mb-4">
                <label class="tooltip tooltip-bottom">
                  <a @click="() => { state.tab = 2;}" class="btn btn-md btn-secondary btn-circle normal-case text-sm " :class="{'im-button-active' : state.tab == 2}">
                    <div class="im-btn-icon">
                      <SVGRenderer svgKey="legal_icon"></SVGRenderer>
                    </div>
                    <span class="im-btn-text">Jogi helyzet</span>
                  </a>
                </label>
              </div>
              <div class="flex-row mb-4">
                <label class="tooltip tooltip-bottom">
                  <a @click="() => { state.tab = 3;}" class="btn btn-md btn-secondary btn-circle normal-case text-sm " :class="{'im-button-active' : state.tab == 3}">
                    <div class="im-btn-icon">
                      <SVGRenderer svgKey="system_settings_icon"></SVGRenderer>
                    </div>
                    <span class="im-btn-text">Hasznosítás</span>
                  </a>
                </label>
              </div>
              <div class="flex-row mb-4">
                <label class="tooltip tooltip-bottom">
                  <a @click="() => { state.tab = 4;}" class="btn btn-md btn-secondary btn-circle normal-case text-sm " :class="{'im-button-active' : state.tab == 4}">
                    <div class="im-btn-icon">
                      <SVGRenderer svgKey="meter_icon"></SVGRenderer>
                    </div>
                    <span class="im-btn-text">Mérőórák</span>
                  </a>
                </label>
              </div>
              <div class="flex-row mb-4">
                <label class="tooltip tooltip-bottom">
                  <a @click="() => { state.tab = 9;}" class="btn btn-md btn-secondary btn-circle normal-case text-sm " :class="{'im-button-active' : state.tab == 9}">
                    <div class="im-btn-icon">
                      <SVGRenderer svgKey="partner_icon"></SVGRenderer>
                    </div>
                    <span class="im-btn-text">Partner</span>
                  </a>
                </label>
              </div>
              <div class="flex-row mb-4">
                <label class="tooltip tooltip-bottom">
                  <a @click="() => { state.tab = 6;}" class="btn btn-md btn-secondary btn-circle normal-case text-sm " :class="{'im-button-active' : state.tab == 6}">
                    <div class="im-btn-icon">
                      <SVGRenderer svgKey="document_icon"></SVGRenderer>
                    </div>
                    <span class="im-btn-text">Dokumentumok</span>
                  </a>
                </label>
              </div>
              
            </div>
            <PropertyManagementNavbar propEntity="premise" />
            <TransitionGroup name="fade">
              <!-------------------------Alapadatok ------------>
              <div class="flex flex-col im-main-card rounded-xl im-main-card-starter" v-if="state.tab == 1">
                <div>
                  <h2 class="text-xl text-secondary w-full font-bold">
                    Alapadatok
                  </h2>
                  <div class="im-card-form">
                    <div v-for="item in state.basicData" v-bind:key="item.model" class="mb-8">
                      <ImFormInput v-model="item.value" :title="item.title" :type="item.type"
                        :valueLabel="item.valueLabel" :optionValue="item.optionValue" :endPoint="item.endPoint"
                        :displayValue="item.endPoint" :searchable="item.searchable">
                      </ImFormInput>
                    </div>
                    <div v-if="state.isStaire">
                      <h2 class="text-xl text-secondary w-full font-bold">
                        Lépcső
                      </h2>
                      <hr class="mb-8" />
                      <div v-for="item in state.staircaseData" v-bind:key="item.model" class="mb-8">
                        <ImFormInput v-model="item.value" :title="item.title" :type="item.type"
                          :valueLabel="item.valueLabel" :optionValue="item.optionValue" :endPoint="item.endPoint"
                          :displayValue="item.endPoint" :searchable="item.searchable">
                        </ImFormInput>
                      </div>
                    </div>

                    <div v-if="state.isElevator">
                      <h2 class="text-xl text-secondary w-full font-bold">
                        Lift
                      </h2>
                      <hr class="mb-8" />
                      <div v-for="item in state.elevatorData" v-bind:key="item.model" class="mb-8">
                        <ImFormInput v-model="item.value" :title="item.title" :type="item.type"
                          :valueLabel="item.valueLabel" :optionValue="item.optionValue" :endPoint="item.endPoint"
                          :displayValue="item.endPoint" :searchable="item.searchable">
                        </ImFormInput>
                      </div>
                    </div>

                    <button @click="savePremise" class="btn btn-md btn-secondary  normal-case text-sm im-round-btn">{{
                      $t("message.save") }}</button>
                  </div>
                </div>
              </div>

              <!-------------------------Jogi helyzet ------------>
              <div class="flex flex-col im-main-card rounded-xl im-main-card-starter" v-if="state.tab == 2">
                <div>
                  <h2 class="text-xl text-secondary w-full font-bold mb-8">
                    Jogi helyzet
                  </h2>
                  <button @click="
                    state.tab = 8;
                  state.legalsitId = 0;
                  clearData(state.legalsitData);
                  " class="btn btn-md btn-secondary normal-case text-sm im-round-btn mb-8">
                    Új
                  </button>
                  <hr class="mb-8" />
                  <div class="im-card-form">
                    <RobberVueGrid ref="legalsitGrid" :config="legalsitGridConfigComputed" />
                  </div>
                </div>
              </div>

              <!------------------ Jogi helyzet - szerkesztés ---------------->
              <div class="flex flex-col im-main-card rounded-xl im-main-card-starter" v-if="state.tab == 8">
                <div>
                  <button class="btn btn-secondary btn-sm btn-circle mb-8" @click="state.tab = 2">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                      stroke="currentColor" class="w-6 h-6">
                      <path stroke-linecap="round" stroke-linejoin="round"
                        d="M19.5 12h-15m0 0l6.75 6.75M4.5 12l6.75-6.75" />
                    </svg>
                  </button>
                  <h2 class="text-xl text-secondary w-full font-bold">
                    Jogi helyzet
                  </h2>
                  <div class="im-card-form">
                    <div v-for="item in state.legalsitData" v-bind:key="item.model" class="mb-8">
                      <ImFormInput v-model="item.value" :title="item.title" :type="item.type"
                        :valueLabel="item.valueLabel" :optionValue="item.optionValue" :endPoint="item.endPoint"
                        :displayValue="item.displayValue" :searchable="item.searchable">
                      </ImFormInput>
                    </div>

                    <button @click="saveLegalsit" class="btn btn-md btn-secondary  normal-case text-sm im-round-btn">{{
                      $t("message.save") }}</button>
                  </div>
                </div>
              </div>

              <!------------------ Hasznosítás - Lista ---------------->
              <div class="flex flex-col im-main-card rounded-xl im-main-card-starter" v-if="state.tab == 3">
                <div>
                  <h2 class=" text-xl text-secondary w-full font-bold mb-8">Hasznosítás</h2>
                  <button @click="state.tab = 7; state.utilizationId = 0; clearData(state.utilizationData)"
                    class="btn btn-md btn-secondary  normal-case text-sm im-round-btn mb-8">Új</button>
                  <hr class="mb-8" />
                  <div class="im-card-form">
                    <RobberVueGrid ref="utilizationGrid" :config="utilizationGridConfigComputed" />
                  </div>
                </div>
              </div>

              <!------------------ Hasznosítás - szerkesztés ---------------->
              <div class="flex flex-col im-main-card rounded-xl im-main-card-starter" v-if="state.tab == 7">
                <div>
                  <button class="btn btn-secondary btn-sm btn-circle mb-8" @click="state.tab = 3">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                      stroke="currentColor" class="w-6 h-6">
                      <path stroke-linecap="round" stroke-linejoin="round"
                        d="M19.5 12h-15m0 0l6.75 6.75M4.5 12l6.75-6.75" />
                    </svg>
                  </button>
                  <h2 class="text-xl text-secondary w-full font-bold">
                    Hasznosítás
                  </h2>
                  <div class="im-card-form">
                    <div v-for="item in state.utilizationData" v-bind:key="item.model" class="mb-8">
                      <ImFormInput v-model="item.value" :title="item.title" :type="item.type"
                        :valueLabel="item.valueLabel" :optionValue="item.optionValue" :endPoint="item.endPoint"
                        :displayValue="item.displayValue" :searchable="item.searchable">
                      </ImFormInput>
                    </div>

                    <button @click="saveUtilization"
                      class="btn btn-md btn-secondary  normal-case text-sm im-round-btn">{{ $t("message.save")
                      }}</button>
                  </div>
                </div>
              </div>

              <!------------------ Speciális adtaok ---------------->
              <div class="flex flex-col im-main-card rounded-xl im-main-card-starter" v-if="state.tab == 4">
                <div>
                  <h2 class="text-xl text-secondary w-full font-bold">
                    Speciális adatok
                  </h2>
                  <div class="im-card-form">

                    <div v-for="item in state.specialData" v-bind:key="item.model" class="mb-8">
                      <ImFormInput v-model="item.value" :title="item.title" :type="item.type"
                        :valueLabel="item.valueLabel" :optionValue="item.optionValue" :endPoint="item.endPoint"
                        :displayValue="item.displayValue" :searchable="item.searchable">
                      </ImFormInput>
                    </div>

                    <button @click="savePremiseSpecial"
                      class="btn btn-md btn-secondary  normal-case text-sm im-round-btn">{{ $t("message.save")
                      }}</button>
                  </div>
                </div>
              </div>

              <!------------------ Mérőórák ---------------->
              <div class="flex flex-col im-main-card rounded-xl im-main-card-starter" v-if="state.tab == 5">
                <div>
                  <h2 class="text-xl text-secondary w-full font-bold">
                    Mérőórák
                  </h2>
                  <div class="im-card-form">
                    <div v-for="item in state.basicData" v-bind:key="item.model" class="mb-8">
                      <ImFormInput v-model="item.value" :title="item.title" :type="item.type"
                        :valueLabel="item.valueLabel" :optionValue="item.optionValue" :endPoint="item.endPoint"
                        :displayValue="item.displayValue" :searchable="item.searchable">
                      </ImFormInput>
                    </div>

                    <button @click="asyncSave">save</button>
                  </div>
                </div>
              </div>

              <!-------------------------Partner ------------>
              <div class="flex flex-col im-main-card rounded-xl im-main-card-starter" v-if="state.tab == 9">
                <div>
                  <h2 class="text-xl text-secondary w-full font-bold mb-8">
                    Partner
                  </h2>
                  <button @click="
                    state.tab = 10;
                  state.partnerId = 0;
                  clearData(state.partnerData);
                  " class="btn btn-md btn-secondary normal-case text-sm im-round-btn mb-8">
                    Új
                  </button>
                  <hr class="mb-8" />
                  <div class="im-card-form">
                    <RobberVueGrid ref="legalsitGrid" :config="partnerGridConfigComputed" />
                    <RobberVueGrid ref="legalsitGrid" :config="addPartnerGridConfigComputed" />
                  </div>
                </div>
              </div>

              <!------------------ Partner - szerkesztés ---------------->
              <div class="flex flex-col im-main-card rounded-xl im-main-card-starter" v-if="state.tab == 10">
                <div>
                  <button class="btn btn-secondary btn-sm btn-circle mb-8" @click="state.tab = 9">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                      stroke="currentColor" class="w-6 h-6">
                      <path stroke-linecap="round" stroke-linejoin="round"
                        d="M19.5 12h-15m0 0l6.75 6.75M4.5 12l6.75-6.75" />
                    </svg>
                  </button>
                  <h2 class="text-xl text-secondary w-full font-bold">
                    Partner
                  </h2>
                  <div class="im-card-form">
                    <div v-for="item in state.partnerData" v-bind:key="item.model" class="mb-8">
                      <ImFormInput v-model="item.value" :title="item.title" :type="item.type"
                        :valueLabel="item.valueLabel" :optionValue="item.optionValue" :endPoint="item.endPoint"
                        :displayValue="item.displayValue" :searchable="item.searchable"
                        :onchangeMethod="item.onchangeMethod">
                      </ImFormInput>
                    </div>

                    <button @click="savePartner" class="btn btn-md btn-secondary  normal-case text-sm im-round-btn">{{
                      $t("message.save") }}</button>
                  </div>
                </div>
              </div>

              <!------------------ Dokumentumok ---------------->
              <div class="flex flex-col im-main-card rounded-xl im-main-card-starter" v-if="state.tab == 6">
                <div>
                  <h2 class="text-xl text-secondary w-full font-bold">
                    {{ $t("message.documents") }}
                  </h2>
                  <div class="im-card-form">
                    <div class="flex flex-row w-full mb-4">
                      <ImFormInput v-model="state.documentSetup.documentType" :title="$t('message.mainType')"
                        type="option" :valueLabel="state.documentSetup.documentTypeLabel" optionValue="DOCUMENT_TYPE"
                        class="w-full mr-4" endPoint="" displayValue="" searchable="true">
                      </ImFormInput>
                      <ImFormInput v-model="state.documentSetup.documentSubType" :title="$t('message.subType')"
                        type="option" :valueLabel="state.documentSetup.documentSubTypeLabel"
                        optionValue="DOCUMENT_SUB_TYPE" class="w-full ml-4" endPoint="" displayValue="" :onchangeMethod="(options, optionsRepo, value) => {
                          options.value = optionsRepo.filter(elem => (elem.parent) == value);
                        }" searchable="true">
                      </ImFormInput>
                    </div>
                    <input
                      class="file:p-2 file:bg-gray-800 file:border-none file:hover:cursor-pointer block w-full text-sm text-gray-900 border border-gray-300 rounded-md bg-gray-50 dark:text-gray-400 focus:outline-none dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 mb-8"
                      aria-describedby="file_input_help" accept=".doc,.docx,.xml" id="file_input" type="file"
                      ref="documentFile" />

                    <button @click="uploadFile" class="btn btn-md btn-secondary normal-case text-sm im-round-btn mb-8">
                      {{ $t("message.upload") }}
                    </button>

                    <hr class="mb-8" />
                    <RobberVueGrid ref="documentsGrid" :config="documentsGridConfigComputed" />
                  </div>
                </div>
              </div>
            </TransitionGroup>
          </div>
        </div>
      </template>
    </BaseLayout>
  </div>
</template>

<script setup>
//:options="state.documentSetup.typeOptions"
//:options="state.documentSetup.subTypeOptions"
// Components
import BaseLayout from "../../layouts/BaseLayout.vue";
import PropertyManagementNavbar from "../../components/REM/PropertyManagementNavbar.vue";
import ImFormInput from "./ImFormInput.vue";
//import SideMenuButton from "./SideButtonMenu.vue";
import RobberVueGrid from "@/components/RVG/RobberVueGrid.vue";
import RemUpButton from "../../components/REM/RemUpButton.vue";
import SVGRenderer from "../../components/SVGRenderer.vue";

//Utils

import { onMounted, ref, watch, /*onMounted, reactive,*/ computed } from "vue";
import { useRoute } from "vue-router";
import router from "@/misc/router";
import formdata from "./FormData.js";
import premiseUtils from "./premiseUtils.js";
import store from "@/misc/vuex-store";
import { useI18n } from "vue-i18n";

//RVG Configs
import documentsGridConfig from "@/misc/gridConfigs/premiseDocumentsGridConfig.js";
import utilizationGridConfig from "@/misc/gridConfigs/utilizationPremiseGridConfig.js";
import legalsitGridConfig from "@/misc/gridConfigs/legalSitPremiseGridConfig.js";
import partnerGridConfig from "@/misc/gridConfigs/premisePartnerGridConfig.js";

/**
 * Setup
 */
const { t } = useI18n();

const language = computed(() => store.getters.getLanguage);

const route = useRoute();
const premiseId = route.params.premiseId;
const documentFile = ref("");

const state = ref({
  tab: 1,
  orig: {},
  editLegalsit: formdata.editLegalsit,
  isElevator: false,
  isStaire: false,
  documentSetup: {
    documentType: "",
    documentSubType: "",
    documentTypeLabel: "",
    documentSubTypeLabel: "",
  },
  basicData: [],
  partnerList: [],
  staircaseData: [],
  elevatorData: [],
  specialData: [],
  utilizationData: formdata.utilizationData,
  utilizationId: 0,
  legalsitData: formdata.legalsitData,
  legalsitId: 0,
  partnerData: formdata.partnerData,
  partnerId: 0,
});

//state.value.partnerData[2].parent = state.value.partnerData[1].value

onMounted(() => {
  //state.value.basicData = formdata.basicData
  premiseUtils.asyncGet(state, premiseId, formdata);

});

/**
 *  RVGs
 */

const documentsGridConfigComputed = computed(() => {
  return documentsGridConfig(premiseId);
});

const utilizationGridConfigComputed = computed(() => {
  return utilizationGridConfig(premiseId, state);
});

const legalsitGridConfigComputed = computed(() => {
  return legalsitGridConfig(state);
});

const partnerGridConfigComputed = computed(() => {
  return partnerGridConfig(premiseId, state, t, false);
});

const addPartnerGridConfigComputed = computed(() => {
  return partnerGridConfig(premiseId, state, t, true);
});

/**
 *  Watchers
 */

watch(
  () => language.value,
  () => {
    premiseUtils.asyncGet(state, premiseId);
  }
);


/**
 * Savers
 */

function savePremise() {
  premiseUtils.asyncSavePremise(state, premiseId, t);
}

function savePremiseSpecial() {
  premiseUtils.asyncSavePremiseSpecialData(state, premiseId, t);
}

function uploadFile() {
  premiseUtils.uploadFile(state, documentFile, premiseId, t);
}

function saveLegalsit() {
  premiseUtils.saveLegalsit(state, t);
}

function saveUtilization() {
  premiseUtils.saveUtilization(state, premiseId, t)
}

function savePartner() {
  premiseUtils.savePartner(state, premiseId, t)
}

function clearData(vArray) {
  vArray.forEach(element => {
    element.value = "";
  });
}



</script>

<style scoped>
.cardBg {
  background-color: hsl(220, 17.647%, 20%);
}

.im-main-card {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--b2, var(--b1)) / var(--tw-bg-opacity));
  padding: 3rem;
  width: 100%;
  max-width: 100%;
  max-height: 75vh;
  overflow: hidden;
}

@media screen and (max-width: 600px) {
  .im-main-card {
    margin-bottom: 30px;
  }


}

.im-main-card-starter {
  animation: 0.5s ease-out 0s 1 scaleupY;
  overflow: auto;
}

.im-main-card-starter div {
  animation: 1.5s ease-out 0s 1 fadein;
}

@keyframes scaleupY {
  0% {
    transform-origin: left top;
    transform: scaleY(0);
  }

  100% {
    transform: scaleY(1);
  }
}

@keyframes fadein {
  0% {
    opacity: 0;
  }

  50% {
    opacity: 0;
  }

  100% {
    opacity: 100%;
  }
}

.im-sidemenu-contanier {
  animation: 0.5s ease-out 0s 1 scaleupY;
  padding: 1rem;

  margin-right: 2rem;
  height: fit-content;
  --tw-bg-opacity: 1;
  background-color: hsl(var(--b2, var(--b1)) / var(--tw-bg-opacity));
}

.im-sidemenu-contanier div {
  animation: 1.5s ease-out 0s 1 fadein;
}

.im-card-form {
  padding-top: 2rem;
}

.fade-enter-active {
  transition: opacity 1s ease 1s, padding 1s ease 1s, width 1s ease 1s;
}

.fade-leave-active {
  transition: opacity 0.5s ease, padding 0.5s ease, width 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
  padding: 0;
  width: 0;
  opacity: 0;
}

.im-round-btn {
  border-radius: 9999px;
}



/* button */

.btn-circle {
    transition: width 0.5s;
}


.im-sidemenu-contanier:hover .btn-circle {
       
    width: 15rem;
   }



@keyframes fadein {
    0% {
        opacity: 0;
    }

    25% {
        opacity: 0;
    }

    100% {
        opacity: 100%;
    }
}

.im-btn-text {
    display: none;
}

.im-sidemenu-contanier:hover .im-btn-text {
    animation: .5s ease-out 0s 1 fadein;
    display: inline;
}

.im-btn-icon {
    display: inline;
}
.im-button-active{
  border-color: lighter hsl(var(--s) );;
}

.im-sidemenu-contanier:hover .im-btn-icon {
    display: none;
}
</style>
