<template>
  <div class="RVGDateTime">
    <svg v-if="this.data && !isNaN(this.data)" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-6">
      <path stroke-linecap="round" stroke-linejoin="round" d="M6.75 2.994v2.25m10.5-2.25v2.25m-14.252 13.5V7.491a2.25 2.25 0 0 1 2.25-2.25h13.5a2.25 2.25 0 0 1 2.25 2.25v11.251m-18 0a2.25 2.25 0 0 0 2.25 2.25h13.5a2.25 2.25 0 0 0 2.25-2.25m-18 0v-7.5a2.25 2.25 0 0 1 2.25-2.25h13.5a2.25 2.25 0 0 1 2.25 2.25v7.5m-6.75-6h2.25m-9 2.25h4.5m.002-2.25h.005v.006H12v-.006Zm-.001 4.5h.006v.006h-.006v-.005Zm-2.25.001h.005v.006H9.75v-.006Zm-2.25 0h.005v.005h-.006v-.005Zm6.75-2.247h.005v.005h-.005v-.005Zm0 2.247h.006v.006h-.006v-.006Zm2.25-2.248h.006V15H16.5v-.005Z" />
    </svg>
    <span v-if="this.data && !isNaN(this.data)">{{ formattedDate }}</span>
    <span class="naDate" v-if="!this.data || isNaN(this.data)">[nincs megadva]</span>
  </div>
</template>

<script>
import moment from 'moment';
export default {
  name: "RVGDateTime",
  components: {
  },
  props: ['data', 'config'],
  computed: {
    formattedDate: function () {
      var format = process.env.VUE_APP_DATETIME_FORMAT;
      if(this.config.formatter.shortFormat) {
        format = process.env.VUE_APP_DATETIME_SHORT_FORMAT;
      }

      return moment(this.data*1000).format(format);
    }
  }
};
</script>

<style scoped>
.RVGDateTime span {
  margin-left: 10px;
}

.RVGDateTime .naDate {
  font-style: italic;
  font-weight: 200;
}
</style>
