<template>
  <div class="flex flex-row items-center justify-center gap-0.5">
    <div class="card card-side bg-base-100 shadow-xl grow">
      <figure class=" bg-base-300 px-5 text-magenta">
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-8 h-8">
          <path stroke-linecap="round" stroke-linejoin="round" d="M8.25 3v1.5M4.5 8.25H3m18 0h-1.5M4.5 12H3m18 0h-1.5m-15 3.75H3m18 0h-1.5M8.25 19.5V21M12 3v1.5m0 15V21m3.75-18v1.5m0 15V21m-9-1.5h10.5a2.25 2.25 0 002.25-2.25V6.75a2.25 2.25 0 00-2.25-2.25H6.75A2.25 2.25 0 004.5 6.75v10.5a2.25 2.25 0 002.25 2.25zm.75-12h9v9h-9v-9z" />
        </svg>
      </figure>
      <div class="card-body p-3 flex flex-col gap-0 text-left">
        <h2 v-if="props.gistType.gistType == 'SITE_DESIGN'" @click="navigateToGlobalSiteDesign" class="card-title text-secondary font-bold text-lg hover:underline hover:cursor-pointer">{{ props.gistType.displayName }}</h2>
        <h2 v-else class="card-title font-light text-lg">{{ props.gistType.displayName }}</h2>
        <div class="flex flex-row items-center justify-center text-left gap-2">
          <p class="m-0 text-xs text-slate-600 text-left grow-0">T: <span class="text-slate-500">{{ props.gistType.gistType }}</span></p>
          <span class="text-white opacity-20 border-r-[1px] border-r-slate-500 mr-[1px] h-[15px]">&nbsp;</span>
          <p class="m-0 text-xs text-slate-600 text-left grow">A: <span class="text-slate-500">{{ props.gistType.adapterName }}</span></p>
        </div>
      </div>
    </div>
    <div class="flex flex-col items-center justify-around gap-0.5">
      <button v-if="User.hasPermission('EditGist')" class="btn btn-sm btn-circle bg-white bg-opacity-10 hover:bg-opacity-20 border-none text-white" @click="props.clickOnEdit">
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-3 h-3">
          <path stroke-linecap="round" stroke-linejoin="round" d="m16.862 4.487 1.687-1.688a1.875 1.875 0 1 1 2.652 2.652L6.832 19.82a4.5 4.5 0 0 1-1.897 1.13l-2.685.8.8-2.685a4.5 4.5 0 0 1 1.13-1.897L16.863 4.487Zm0 0L19.5 7.125" />
        </svg>
      </button>
      <button v-if="User.hasPermission('DeleteGist')" class="btn btn-sm btn-circle bg-white bg-opacity-10 hover:bg-opacity-20 border-none text-red-700" @click="() => { state.showRemoveConfirmation = true }">
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-4 h-4">
          <path stroke-linecap="round" stroke-linejoin="round" d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0" />
        </svg>
      </button>
    </div>

    <input type="checkbox" class="modal-toggle" v-model="state.showRemoveConfirmation" />
    <div class="modal">
      <div class="modal-box">
        <h3 class="text-lg text-left font-normal">{{ $t("message.confirmRemove") }}</h3>
        <div class="modal-action">
          <label class="btn btn-sm text-white font-light text-xs" @click="() => { state.showRemoveConfirmation = false }">{{ $t("message.deny") }}</label>
          <label class="btn btn-sm bg-magenta text-white font-light text-xs" @click="() => { props.clickOnRemove(); state.showRemoveConfirmation = false; }">{{ $t("message.confirm") }}</label>
        </div>
      </div>
    </div>

  </div>
</template>

<script setup>
  import User from '@/misc/User'
  import { reactive } from 'vue';
  import store from "@/misc/vuex-store";
  import toast from '@/misc/toast.js'
  import { useI18n } from "vue-i18n";
  const { t } = useI18n();

  const props = defineProps({
    gistType: {
      type: Object,
      required: true
    },
    clickOnEdit: {
      type: Function,
      default: () => {}
    },
    clickOnRemove: {
      type: Function,
      default: () => {}
    }
  })

  const state = reactive({
    showRemoveConfirmation: false
  });
  const navigateToGlobalSiteDesign = () => {
    let siteDesings = store.getters.getSiteDesigns;
    for(let siteDesign of siteDesings) {
      if(siteDesign.type == null && siteDesign.gistId == props.gistType.id) {
        store.commit("showSidebar", { type: "siteDesign", entity: siteDesign });
        store.commit('setSiteDesignSidebarCurrentTab', 'areas');
        return;
      }
    }
    toast.error(t("message.globalSiteDesignNotFound"));
  }
</script>