<template>
  <div class="w-full bg-base-300 p-3 rounded-lg">
    <div
      class="flex flex-row w-full items-center justify-center border-b-[1px] font-bold text-slate-400 border-slate-400 mb-3 pb-3"
    >
      <div class="grow flex flex-row items-center justify-start gap-2">
        <span class="font-thin text-white text-sm flex flex-row items-center">
          <slot name="icon"></slot>
          <span>{{ gist.gistType }}</span>
        </span>
        <button
          @click="
            () => {
              period = 'daily';
            }
          "
          :class="[
            'bg-slate-900 text-white rounded-md py-1 font-light px-3 text-xs',
            { ' !bg-secondary': period == 'daily' },
          ]"
        >
          1 {{ $t("message.day") }}
        </button>
        <button
          @click="
            () => {
              period = 'hourly';
            }
          "
          :class="[
            'bg-slate-900 text-white rounded-md py-1 font-light px-3 text-xs',
            { ' !bg-secondary': period == 'hourly' },
          ]"
        >
          1 {{ $t("message.hour") }}
        </button>
        <button
          @click="
            () => {
              period = 'minutely';
            }
          "
          :class="[
            'bg-slate-900 text-white rounded-md py-1 font-light text-xs px-3',
            { '!bg-secondary': period == 'minutely' },
          ]"
        >
          15 {{ $t("message.minutes") }}
        </button>
      </div>
      <div class="w-8 flex items-center justify-center h-full">
        <SpinnerLoader sizeClasses="w-5 h-5" v-if="loading" />
      </div>
    </div>

    <TendencyBar :minuteStep="currentMinuteStep" :barData="barData" />

    <div class="flex flex-row items-center justify-center text-left gap-2">
      <p class="m-0 text-xs text-slate-600 text-left grow-0">
        <span class="text-slate-500">{{ gist.displayName }} </span>
      </p>
      <!-- <span class="text-white opacity-20 border-r-[1px] border-r-slate-500 mr-[1px] h-[15px]">&nbsp;</span> -->
      <p class="m-0 text-xs text-slate-600 text-right grow">
        <span class="text-slate-500">{{ gist.adapterName }}</span>
      </p>
    </div>
  </div>
</template>

<script>
import TendencyBar from "../charts/TendencyBar.vue";
import SpinnerLoader from "../../SpinnerLoader.vue";
import Axios from "@/misc/axios.js";

export default {
  name: "DefaultDisplayer",
  props: {
    entity: {
      type: Object,
      required: true,
    },
    entityType: {
      type: String,
      required: true,
    },
    gist: {
      type: Object,
      required: true,
    },
  },
  components: {
    TendencyBar,
    SpinnerLoader,
  },
  mounted() {
    this.fetchBarData();
    this.fetchBarDataInterval();
  },
  beforeDestroy() {
    if (this.timeout) clearTimeout(this.timeout);
  },
  data() {
    return {
      period: "hourly", // daily, hourly, minutely
      barData: [],
      loading: false,
      timeout: null,
    };
  },
  computed: {
    date() {
      return this.$store.getters.getCurrentDate;
    },
    currentMinuteStep() {
      if (this.period == "daily") return 60 * 24;
      if (this.period == "hourly") return 60;
      return 15; // minutely
    },
  },
  watch: {
    period() {
      this.fetchBarData();
      this.fetchBarDataInterval();
    },
  },
  methods: {
    // toggleFlipped(){
    //   this.$store.commit('setFlipped', !this.$store.getters.getFlipped)
    // },
    fetchBarDataInterval() {
      if (this.timeout) clearTimeout(this.timeout);

      this.timeout = setTimeout(() => {
        this.fetchBarData();
        this.fetchBarDataInterval();
      }, process.env.VUE_APP_GIST_REFRESH_INTERVAL);
    },
    fetchBarData() {
      this.loading = true;
      let url = "";

      if (this.entityType == "zone") {
        url =
          process.env.VUE_APP_FRONTENDV2_API_URL +
          "/zone/" +
          this.entity.id +
          "/spots/" +
          this.gist.id +
          "/" +
          this.period +
          "/" +
          this.date;
      }

      if (this.entityType == "site-design") {
        url =
          process.env.VUE_APP_FRONTENDV2_API_URL +
          "/site-design/" +
          this.entity.id +
          "/spots/" +
          this.gist.id +
          "/" +
          this.period +
          "/" +
          this.date;
      }

      if (this.entityType == "zone-group") {
        url =
          process.env.VUE_APP_FRONTENDV2_API_URL +
          "/zone_group/" +
          this.entity.id +
          "/spots/" +
          this.gist.id +
          "/" +
          this.period +
          "/" +
          this.date;
      }

      if (!url) return;

      Axios.get(url, {
        onTokenRefreshed: () => {
          this.fetchBarData();
        },
      })
        .then((res) => {
          this.barData = res.data;
          this.loading = false;
        })
        .catch((err) => {
          console.log(err);
          this.loading = false;
        });
    },
  },
};
</script>
