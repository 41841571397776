<template>
    <tr>
        <th class="text-white text-base">{{ item.translatedValue }}</th>
        <!--<td>
            <div class="badge badge-secondary"> <span v-for="(mType, i) in item.modificationTypes" v-bind:key="mType">
                    {{ mType }}<span v-if="i + 1 < item.modificationTypes.length">, </span></span></div>

        </td>-->
        <td>{{ item.optionValueAdminDtos.length }}</td>
        <td>
            <label :for="item.type" class="btn btn-square btn-secondary btn-md">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-pencil"
                    viewBox="0 0 16 16">
                    <path
                        d="M12.146.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-10 10a.5.5 0 0 1-.168.11l-5 2a.5.5 0 0 1-.65-.65l2-5a.5.5 0 0 1 .11-.168zM11.207 2.5 13.5 4.793 14.793 3.5 12.5 1.207zm1.586 3L10.5 3.207 4 9.707V10h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.293zm-9.761 5.175-.106.106-1.528 3.821 3.821-1.528.106-.106A.5.5 0 0 1 5 12.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.468-.325" />
                </svg>
            </label>
            <input type="checkbox" :id="item.type" class="modal-toggle" />
            <div class="modal" role="dialog">
                <div class="modal-box">

                    <!-----             DRAGGABLES              ---->
                    <div v-if="selectedOV == null">
                        <h3 class="text-lg font-bold text-white text-base mb-8">{{ item.translatedValue }}</h3>

                        <draggable v-model="item.optionValueAdminDtos" group="people" @start="drag = true"
                            @end="drag = false" item-key="id">
                            <template #item="{ element, index }">
                                <div class="input-group mb-4">
                                    <input class="input input-bordered" type="text" v-model="element.value">
                                    <button class="btn btn-secondary" @click="removeElement(index)">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                                            fill="currentColor" class="bi bi-trash" viewBox="0 0 16 16">
                                            <path
                                                d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5m2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5m3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0z" />
                                            <path
                                                d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4zM2.5 3h11V2h-11z" />
                                        </svg>
                                    </button>
                                    <button class="btn btn-secondary" @click="selectedOV = element">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                                            fill="currentColor" class="bi bi-pencil-square" viewBox="0 0 16 16">
                                            <path
                                                d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
                                            <path fill-rule="evenodd"
                                                d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5z" />
                                        </svg>
                                    </button>
                                </div>
                            </template>
                        </draggable>

                        <hr class="my-8" />
                        <!-----             NEW DRAGGABLE              ---->
                        <div class="input-group my-8">

                            <input class="input input-bordered" type="text" v-model="item.newOption">
                            <button class="btn btn-secondary" @click="addElement()"> {{ $t('message.newOptionValue')
                                }}</button>
                        </div>

                        <!-----             DRAGGABLE SAVE OR EXIT              ---->
                        <div class="modal-action my-4">
                            <button class="btn btn-secondary" @click="asyncSave(item)">{{ $t('message.save') }}</button>
                            <label :for="item.type" class="btn btn-secondary">{{ $t('message.close') }}</label>
                        </div>
                    </div>


                    <!-----             TRANSLATIONS              ---->
                    <div v-if="selectedOV != null">
                        <div class="tooltip tooltip-right tooltip-secondary" :data-tip="$t('message.goBack')">
                            <button class="btn btn-secondary btn-sm btn-circle my-8" @click="selectedOV = null">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                    stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                                    <path stroke-linecap="round" stroke-linejoin="round"
                                        d="M19.5 12h-15m0 0l6.75 6.75M4.5 12l6.75-6.75" />
                                </svg>
                            </button>
                        </div>

                        <h3 class="text-lg font-bold text-white text-base mb-8">{{ selectedOV.value }}</h3>
                        <!-----             TRANSLATION VALUES              ---->
                        <div v-for="translation in selectedOV.translations" v-bind:key="translation.id">
                            <div class="input-group my-8">

                                <input class="input input-bordered" type="text" v-model="translation.translatedValue">
                                <label class="btn btn-secondary">
                                    {{ translation.languageCode }}

                                </label>
                            </div>
                        </div>

                        <hr />

                        <!-----             NEW TRANSLATION              ---->

                        <div class="input-group my-8">


                            <input class="input input-bordered" type="text" v-model="selectedOV.newValue">
                            <select class="input input-bordered btn-secondary" v-model="selectedOV.newLanguage">
                                <option v-for="language in languages" v-bind:key="language">{{ language }}
                                </option>

                            </select>


                            <button class="btn btn-secondary" @click="addTranslationElement()"> {{
                                $t('message.newOptionValue')
                            }}</button>
                        </div>


                        <!-----             TRANSLATION MODAL ACTIONS              ---->
                        <div class="modal-action my-4">

                            <button class="btn btn-secondary" @click="asyncTranslationSave()">{{ $t('message.save')
                                }}</button>
                            <label :for="item.type" class="btn btn-secondary" @click="selectedOV = null">{{
                                $t('message.close') }}</label>
                        </div>
                    </div>




                </div>
            </div>
        </td>
    </tr>

</template>

<script setup>
//import { Container, Draggable } from "vue3-smooth-dnd";
import { /*reactive, watch, onMounted, watch, computed,*/ reactive, ref } from 'vue';
import draggable from 'vuedraggable'
import Axios from '@/misc/axios';
import toast from "@/misc/toast.js";
import { useI18n } from "vue-i18n";

const { t } = useI18n();
const props = defineProps(['item']);

const item = reactive(props.item)

const selectedOV = ref(null);
const languages = ['en', 'de', 'hu', 'es', 'el']

function addElement() {
    let temp = {
        id: null,
        value: item.newOption,
        type: item.type + "",

    }

    //console.log(item.value.type);
    item.optionValueAdminDtos.push(temp);
    item.newOption = '';
}

function addTranslationElement() {
    if (selectedOV.value != null && selectedOV.value.newLanguage != '' && selectedOV.value.newValue != '') {
        let temp = {

            languageCode: selectedOV.value.newLanguage,
            translatedValue: selectedOV.value.newValue
        }
        selectedOV.value.translations.push(temp);
        selectedOV.value.newLanguage = '';
        selectedOV.value.newValue = '';
    }


}

function removeElement(i) {
    //console.log(i)
    if (i >= 0) {
        if (item.optionValueAdminDtos[i].id) {
            item.deletableOptionValues.push(item.optionValueAdminDtos[i].id);
        }

        item.optionValueAdminDtos.splice(i, 1);
        //console.log("i: " + i)
    }

}

function asyncTranslationSave() {
    let success = true;
    selectedOV.value.translations.forEach(translation => {

        
        Axios.post(process.env.VUE_APP_ADMINV2_API_URL + '/rem/option/option-values/' + selectedOV.value.id + "/translations",
            translation
            , {
                onTokenRefreshed: () => {
                    asyncTranslationSave(item);
                }
            }).then((response) => {
                if (response) {
                    console.log(response)
                    if (response.status != 200) {
                        success = false
                    }
                } else {
                    success = false
                }
            })
    });

    if (success == true) {
        toast.success(t("message.successfulSave"));
    } else {
        toast.error(t('message.notSuccessfulSave'));
    }
}

async function asyncSave(item) {
    const temp = {
        optionValueAdminDtos: [],
        deletableOptionValues: []
    };

    item.optionValueAdminDtos.forEach((element, i) => {
        const value = {
            id: element.id,
            type: item.type,
            value: element.value,
            parentDto: null,
            order: i,
            deleted: false
        }
        temp.optionValueAdminDtos.push(value);
        if (element.deletable == true) {
            temp.deletableOptionValues.push(element.id);
        }
    });

    temp.deletableOptionValues = item.deletableOptionValues;


    //console.log("opossum");

    const response = await Axios.put(process.env.VUE_APP_ADMINV2_API_URL + '/rem/option/' + item.type + "/option-values",
        temp
        , {
            onTokenRefreshed: () => {
                asyncSave(item);
            }
        });

    if (response) {
        if (response.status == 200) {
            toast.success(t("message.successfulSave"));
        } else {
            toast.error(t('message.notSuccessfulSave'));
        }
        //console.log(response);
    } else {
        toast.error(t('message.notSuccessfulSave'));
    }


}
</script>

<style scoped>
.modal-box {
    max-width: 75rem;

}
</style>