<template>
  <div id="PropertyPage" class="h-screen">
    <BaseLayout>
      <template #leftButtons>
        <PropertyManagementNavbar propEntity="property" />
      </template>
      <template #mainContent>
        <!--Header-->
        <header class="w-full mt-4 shadow-sm body-font cardBg">
          <div class="items-start px-6 pb-6 mx-auto md:flex-row">
            <div class="flex justify-between">
              <p
                class="flex mb-4 text-xl text-secondary md:mb-0 uppercase font-bold"
              >
                {{ $t("message.property") }} -
                {{
                  state.property
                    ? state.property.basicDataPropertySheetAppellation
                    : ""
                }}
              </p>
              <RemUpButton remType="property" />
            </div>
          </div>
        </header>

        <div class="flex flex-row w-full">
          <!--Épületek-->
          <div
            v-if="propCurrentTab == 'buildings'"
            class="w-full h-dvh px-6 cardBg"
          >
            <PropertyLists title="buildings" :property="state.property" />
          </div>
          <!--Címek-->
          <div
            v-if="propCurrentTab == 'addresses'"
            class="w-full h-dvh px-6 cardBg"
          >
            <PropertyAddresses title="addresses" :property="state.property" />
          </div>
          <!--Hasznosítások-->
          <div
            v-if="propCurrentTab == 'utilizations'"
            class="w-full h-dvh px-6 cardBg"
          >
            <PropertyUtilizations
              title="utilizations"
              :property="state.property"
            />
          </div>
          <!--Jogi helyzet + kötelezettség-->
          <div
            v-if="propCurrentTab == 'propLegalSituations'"
            class="w-full h-dvh px-6 cardBg"
          >
            <PropLegalSituations />
            <PropertyObligations />
          </div>
          <!--Alapadatok-->
          <div
            v-if="propCurrentTab == 'baseDatas'"
            class="w-full h-dvh px-6 cardBg"
          >
            <PropertyLists title="baseData" :property="state.property" />
          </div>
          <!--Dokumentumok-->
          <div
            v-if="propCurrentTab == 'documents'"
            class="w-full h-dvh px-6 cardBg"
          >
            <PropertyLists title="documents" :property="state.property" />
          </div>
          <!--Partnerlista + szerkesztés elem kiválasztásakor + hozzáadás gomb-->
          <div
            v-if="propCurrentTab == 'partners'"
            class="w-full h-dvh px-6 cardBg"
          >
            <PropertyLists title="partners" />
          </div>
        </div>
      </template>
    </BaseLayout>
  </div>
</template>

<script setup>
import BaseLayout from "../../layouts/BaseLayout.vue";
import PropertyLists from "../../components/REM/PropertyLists.vue";
import { onMounted, reactive, computed } from "vue";
import Utils from "../../misc/Utils";
import { useRoute } from "vue-router";
import PropertyManagementNavbar from "../../components/REM/PropertyManagementNavbar.vue";
import store from "@/misc/vuex-store.js";
import RemUpButton from "../../components/REM/RemUpButton.vue";
import PropertyAddresses from "./PropertyAddresses.vue";
import PropertyUtilizations from "./UtilizationsList.vue";
import PropertyObligations from "./PropertyObligations.vue";
import PropLegalSituations from "./LegalSituations.vue";
import localStorageService from "../../services/LocalStorageService/LocalStorageService";

const state = reactive({
  property: null,
  isSidebarOpen: localStorageService.getSidebarState() == "true",
});

const router = useRoute();
const propertyId = router.params.propertyId;

const propCurrentTab = computed(() => {
  return store.getters.getPropCurrentTab;
});

const getProperty = async () => {
  const response = await Utils.fetchPropertyById(propertyId);
  state.property = response;
};

onMounted(() => {
  getProperty();
});
</script>

<style scoped>
.partnerWidth {
  width: 27.5%;
}

.cardBg {
  background-color: hsl(220, 17.647%, 20%);
}
</style>
