<template>
  <div class="h-full overflow-y-scroll w-full">
    <div class="p-5 pb-2 mr-2 flex flex-col grow border-b-0 border-base-300">
      <div class="flex flex-row items-center gap-2 justify-center">
        <h2
          class="text-base font-sans font-semibold uppercase text-secondary flex flex-row items-center gap-2 grow"
        >
          <span class="text-secondary">{{ $t("message.addNewContact") }}</span>
        </h2>
        <a
          href="javascript:void(null)"
          class="hover:text-secondary"
          @click="hideSidebar"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
            class="w-6 h-6"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M6 18L18 6M6 6l12 12"
            />
          </svg>
        </a>
      </div>
    </div>
    <div class="px-5 pb-5">
      <div class="flex flex-col items-center justify-center gap-2 w-full">
        <div class="flex flex-row gap-3 w-full mt-2">
          <div class="form-control w-full mt-0">
            <label class="label mt-0 pt-0" for="">
              <span class="label-text-alt">{{ $t("message.name") }}</span>
            </label>
            <input
              v-model="state.contact_name"
              type="text"
              class="input input-sm input-bordered w-full"
            />
          </div>
        </div>

        <div class="flex flex-row gap-3 w-full mt-2">
          <div class="form-control w-full mt-0">
            <label class="label mt-0 pt-0" for="">
              <span class="label-text-alt">{{
                $t("message.contactPhone")
              }}</span>
            </label>
            <input
              v-model="state.contact_phone"
              type="text"
              class="input input-sm input-bordered w-full"
            />
          </div>
        </div>

        <div class="flex flex-row gap-3 w-full mt-2">
          <div class="form-control w-full mt-0">
            <label class="label mt-0 pt-0" for="">
              <span class="label-text-alt">E-mail<span class="text-error"
                  >*</span
                ></span>
            </label>
            <input
              v-model="state.contact_email"
              type="text"
              class="input input-sm input-bordered w-full"
            />
          </div>
        </div>

        <div class="flex flex-row gap-3 w-full mt-2">
          <div class="form-control w-full mt-0">
            <label class="label mt-0 pt-0" for="">
              <span class="label-text-alt">{{ $t("message.rank") }}</span>
            </label>
            <input
              v-model="state.contact_rank"
              type="text"
              class="input input-sm input-bordered w-full"
            />
          </div>
        </div>

        <div class="flex flex-row gap-3 w-full mt-2">
          <div class="form-control w-full mt-0">
            <label class="label mt-0 pt-0" for="">
              <span class="label-text-alt">{{ $t("message.note") }}</span>
            </label>
            <input
              v-model="state.contact_note"
              type="text"
              class="input input-sm input-bordered w-full"
            />
          </div>
        </div>

        <div class="w-full mt-3">
          <button
            :disabled="!validation()"
            class="disabled:opacity-60 btn btn-sm bg-magenta text-white font-light text-xs border-none"
            @click="addNewContact"
          >
            {{ $t("message.addNewContact") }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { reactive, computed } from "vue";
import axios from "axios";
import store from "@/misc/vuex-store.js";
import toast from "@/misc/toast.js";
import { useI18n } from "vue-i18n";

const { t } = useI18n();

const state = reactive({
  partnerID: null,
  contact_name: "",
  contact_phone: "",
  contact_email: "",
  contact_rank: "",
  contact_note: "",
});

function validation() {
  const emailRegex =
    /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9.-]+\.+[a-zA-Z]{2,4}$/;
  if (!emailRegex.test(state.contact_email)) return false;

  return true;
}

const partnerId = computed(() => {
  return store.getters.getPartnerById;
});

const addNewContact = async () => {
  const uri = process.env.VUE_APP_FRONTENDV2_API_URL + "/rem/partner/contact";

  const postBody = {
    partnerId: partnerId.value.id,
    contactName: state.contact_name,
    contactPhone: state.contact_phone,
    contactEmail: state.contact_email,
    contactRank: state.contact_rank,
    contactNote: state.contact_note,
  };

  try {
    await axios.post(uri, postBody, {
      onTokenRefreshed: () => {
        addNewContact();
      },
    });
    hideSidebar();
    toast.success(t("message.successfulSave"));
  } catch (error) {
    console.log(error);
  }
};

const hideSidebar = () => {
  store.commit("hideSidebar");
};
</script>

<style scoped></style>
