<template>
  <div class="h-full overflow-y-auto w-full">
    <div class="p-5 pb-2 mr-2 flex flex-col grow border-b-0 border-base-300">
      <div class="flex flex-row items-center gap-2 justify-center">
        <h2
          class="text-base font-sans font-semibold uppercase text-secondary flex flex-row items-center gap-2 grow"
        >
          <span class="text-secondary">{{ $t("message.editPartner") }}</span>
        </h2>
        <a
          href="javascript:void(null)"
          class="hover:text-secondary"
          @click="hideSidebar"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
            class="w-6 h-6"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M6 18L18 6M6 6l12 12"
            />
          </svg>
        </a>
      </div>
    </div>
    <div class="px-5 pb-5">
      <div class="flex flex-col items-center justify-center gap-2 w-full">
        <div class="flex flex-row gap-3 w-full">
          <div class="form-control w-full mt-0">
            <label class="label mt-0 pt-0" for="">
              <span class="label-text">{{ $t("message.mainType") }}<span class="text-error"
                  >*</span
                ></span>
              <span class="label-text-alt"></span>
            </label>
            <OptionValueSelector
              v-model="state.partner.main_type"
              type="PARTNER_MAIN_TYPE"
            />
          </div>
        </div>

        <div class="flex flex-row gap-3 w-full">
          <div class="form-control w-full mt-0">
            <label class="label mt-0 pt-0" for="">
              <span class="label-text">{{ $t("message.subType") }}<span class="text-error"
                  >*</span
                ></span>
              <span class="label-text-alt"></span>
            </label>
            <OptionValueSelector
              v-model="state.partner.sub_type"
              type="PARTNER_SUB_TYPE"
            />
          </div>
        </div>

        <div class="flex flex-row gap-3 w-full">
          <div class="form-control w-full mt-0">
            <label class="label mt-0 pt-0" for="">
              <span class="label-text">{{ $t("message.name") }}<span class="text-error"
                  >*</span
                ></span>
              <span class="label-text-alt"></span>
            </label>
            <input
              v-model="state.partner.name"
              type="text"
              class="input input-sm input-bordered w-full"
            />
          </div>
        </div>

        <div class="flex flex-row gap-3 w-full">
          <div class="form-control w-full mt-0">
            <label class="label mt-0 pt-0" for="">
              <span class="label-text">{{ $t("message.type") }}<span class="text-error"
                  >*</span
                ></span>
              <span class="label-text-alt"></span>
            </label>
            <select
              v-model="state.partner.partnerTypeForTax"
              class="select select-sm w-full select-bordered"
            >
              <option disabled selected>{{ $t("message.choose") }}</option>
              <option>{{ $t("message.privateIndividual") }}</option>
              <option>{{ $t("message.company") }}</option>
            </select>
          </div>
        </div>

        <div
          class="flex flex-row gap-3 w-full"
          v-if="
            state.partner.partnerTypeForTax == t('message.privateIndividual')
          "
        >
          <div class="form-control w-full mt-0">
            <label class="label mt-0 pt-0" for="">
              <span class="label-text">{{ $t("message.taxId") }}<span class="text-error"
                  >*</span
                ></span>
            </label>
            <input
              v-model="state.partner.tax_id"
              type="number"
              class="input input-sm input-bordered w-full"
            />
          </div>
        </div>

        <div
          class="flex flex-row gap-3 w-full"
          v-if="state.partner.partnerTypeForTax == t('message.company')"
        >
          <div class="form-control w-full mt-0">
            <label class="label mt-0 pt-0" for="">
              <span class="label-text">{{ $t("message.taxNumber") }}<span class="text-error"
                  >*</span
                ></span>
            </label>
            <input
              v-model="state.partner.tax_number"
              type="number"
              class="input input-sm input-bordered w-full"
            />
          </div>
        </div>

        <div
          class="flex flex-row gap-3 w-full border-b-2 border-secondary mt-4 font-bold"
        >
          <div class="form-control w-full mt-2">
            <label class="label mt-0 pt-0" for="">
              <span class="label-text">{{ $t("message.address") }}</span>
            </label>
          </div>
        </div>

        <div class="flex flex-row gap-3 w-full mt-2">
          <div class="form-control w-3/5 mt-0">
            <label class="label mt-0 pt-0" for="">
              <span class="label-text-alt">{{ $t("message.country") }}<span class="text-error"
                  >*</span
                ></span>
            </label>
            <input
              v-model="state.partner.address.country"
              type="text"
              class="input input-sm input-bordered w-full"
            />
          </div>
          <div class="form-control w-2/5 mt-0">
            <label class="label mt-0 pt-0" for="">
              <span class="label-text-alt">{{ $t("message.postal") }}<span class="text-error"
                  >*</span
                ></span>
            </label>
            <input
              v-model="state.partner.address.postal"
              type="number"
              class="input input-sm input-bordered w-full"
              @blur="fetchCountiesAndCities"
            />
          </div>
        </div>

        <div
          v-if="!/^\d{4}$/.test(state.partner.address.postal)"
          class="flex flex-row gap-3 w-full mt-2"
        >
          <div class="form-control w-full mt-0">
            <label class="label mt-0 pt-0" for="">
              <span class="label-text-alt text-red-400">{{
                postalWarning
              }}</span>
            </label>
          </div>
        </div>

        <div class="flex flex-row gap-3 w-full">
          <div class="form-control w-full mt-0">
            <label class="label mt-0 pt-0" for="">
              <span class="label-text-alt">{{ $t("message.county") }}<span class="text-error"
                  >*</span
                ></span>
            </label>
            <select
              v-model="state.partner.address.county"
              class="select select-sm w-full select-bordered"
              :disabled="state.isCountyDisabled"
            >
              <option selected disabled>{{ $t("message.choose") }}</option>
              <option v-for="item in state.cities" :key="item.id">
                {{ item.county }}
              </option>
            </select>
          </div>
          <div class="form-control w-full mt-0">
            <label class="label mt-0 pt-0" for="">
              <span class="label-text-alt">{{ $t("message.city") }}<span class="text-error"
                  >*</span
                ></span>
            </label>
            <select
              v-model="state.partner.address.city"
              class="select select-sm w-full select-bordered"
              :disabled="state.isCityDisabled"
            >
              <option disabled selected>{{ $t("message.choose") }}</option>
              <option v-for="item in state.cities" :key="item.id">
                {{ item.name }}
              </option>
            </select>
          </div>
        </div>

        <div class="flex flex-row gap-3 w-full mt-2">
          <div class="form-control w-full mt-0">
            <label class="label mt-0 pt-0" for="">
              <span class="label-text-alt">{{ $t("message.district") }}</span>
            </label>
            <input
              v-model="state.partner.address.district"
              type="text"
              class="input input-sm input-bordered w-full"
            />
          </div>
        </div>

        <div class="flex flex-row gap-3 w-full">
          <div class="form-control w-3/5 mt-0">
            <label class="label mt-0 pt-0" for="">
              <span class="label-text-alt">{{
                $t("message.publicAreaName")
              }}<span class="text-error"
                  >*</span
                ></span>
            </label>
            <input
              v-model="state.partner.address.public_area_name"
              type="text"
              class="input input-sm input-bordered w-full"
            />
          </div>
          <div class="form-control w-2/5 mt-0">
            <label class="label mt-0 pt-0" for="">
              <span class="label-text-alt">{{
                $t("message.publicAreaType")
              }}<span class="text-error"
                  >*</span
                ></span>
            </label>
            <OptionValueSelector
              type="PUBLIC_AREA_TYPE"
              v-model="state.partner.address.public_area_type"
            />
          </div>
        </div>

        <div class="flex flex-row gap-3 w-full mt-2">
          <div class="form-control w-full mt-0">
            <label class="label mt-0 pt-0" for="">
              <span class="label-text-alt">{{
                $t("message.houseNumber")
              }}<span class="text-error"
                  >*</span
                ></span>
            </label>
            <input
              v-model="state.partner.address.house_number"
              type="text"
              class="input input-sm input-bordered w-full"
            />
          </div>
          <div class="form-control w-full mt-0">
            <label class="label mt-0 pt-0" for="">
              <span class="label-text-alt">{{ $t("message.stairway") }}</span>
            </label>
            <input
              v-model="state.partner.address.stairway"
              type="text"
              class="input input-sm input-bordered w-full"
            />
          </div>
          <div class="form-control w-full mt-0">
            <label class="label mt-0 pt-0" for="">
              <span class="label-text-alt">{{ $t("message.floor") }}</span>
            </label>
            <input
              v-model="state.partner.address.floor"
              type="text"
              class="input input-sm input-bordered w-full"
            />
          </div>
          <div class="form-control w-full mt-0">
            <label class="label mt-0 pt-0" for="">
              <span class="label-text-alt">{{ $t("message.door") }}</span>
            </label>
            <input
              v-model="state.partner.address.door"
              type="text"
              class="input input-sm input-bordered w-full"
            />
          </div>
        </div>

        <div class="w-full mt-3 flex justify-between">
          <button
            :disabled="!validation()"
            class="disabled:opacity-60 btn btn-sm bg-magenta text-white font-light text-xs border-none"
            @click="updatePartner(state.partnerById.id)"
          >
            {{ $t("message.save") }}
          </button>
          <button
            class="disabled:opacity-60 btn btn-sm bg-red-600 text-white font-light text-xs border-none"
            @click="
              () => {
                state.showRemoveConfirmation = true;
              }
            "
          >
            Partner törlése
          </button>
        </div>

        <input
          type="checkbox"
          class="modal-toggle"
          v-model="state.showRemoveConfirmation"
        />
        <div class="modal">
          <div class="modal-box">
            <h3 class="text-lg text-left font-normal">
              {{ $t("message.confirmRemovePartner") }}
            </h3>
            <div class="modal-action">
              <label
                class="btn btn-sm text-white font-light text-xs"
                @click="
                  () => {
                    state.showRemoveConfirmation = false;
                  }
                "
                >{{ $t("message.deny") }}</label
              >
              <label
                class="btn btn-sm bg-magenta text-white font-light text-xs"
                @click="
                  () => {
                    deletePartner(state.partnerById.id);
                    state.showRemoveConfirmation = false;
                  }
                "
                >{{ $t("message.confirm") }}</label
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import Axios from "@/misc/axios";
import { reactive, onMounted, computed } from "vue";
import store from "@/misc/vuex-store";
import toast from "@/misc/toast.js";
import { useI18n } from "vue-i18n";
import OptionValueSelector from "../../../../components/OptionValueSelector.vue";

const { t } = useI18n();

const props = defineProps(["params"]);

const state = reactive({
  showRemoveConfirmation: false,
  partnerById: {},
  isCountyDisabled: false,
  isCityDisabled: false,
  cities: [],
  partner: {
    mainTypes: [],
    subTypes: [],
    main_type: "",
    sub_type: "",
    name: "",
    address: {
      id: "",
      addressType: "POSTAL",
      country: "",
      postal: null,
      county: "",
      city: "",
      district: "",
      public_area_name: "",
      public_area_type: "",
      house_number: "",
      stairway: "",
      floor: "",
      door: "",
    },
    partnerTypeForTax: "",
    tax_id: null,
    tax_number: null,
  },
});

function getPartnerById() {
  state.partnerById = store.getters.getPartnerById;
  state.partner.main_type = state.partnerById.partnerMainType.id;
  state.partner.sub_type = state.partnerById.partnerSubType.id;
  state.partner.name = state.partnerById.name;
  if (state.partnerById.taxId == null) {
    state.partner.partnerTypeForTax = t("message.company");
    state.partner.tax_number = state.partnerById.taxNumber;
  } else if (state.partnerById.taxNumber == null) {
    state.partner.partnerTypeForTax = t("message.privateIndividual");
    state.partner.tax_id = state.partnerById.taxId;
  }
  state.partner.address.id = state.partnerById.partnerAddresses[0].id;
  state.partner.address.country = state.partnerById.partnerAddresses[0].country;
  state.partner.address.postal =
    state.partnerById.partnerAddresses[0].postalCode;
  state.partnerById.partnerAddresses[0].district
    ? (state.partner.address.district =
        state.partnerById.partnerAddresses[0].district)
    : (state.partner.address.district = "");
  state.partner.address.public_area_name =
    state.partnerById.partnerAddresses[0].publicAreaName;
  state.partner.address.public_area_type = state.partnerById.partnerAddresses[0]
    .publicAreaType?.id
    ? state.partnerById.partnerAddresses[0].publicAreaType?.id
    : null;
  state.partner.address.house_number =
    state.partnerById.partnerAddresses[0].houseNumber;
  state.partner.address.stairway =
    state.partnerById.partnerAddresses[0].stairWay;
  state.partner.address.floor = state.partnerById.partnerAddresses[0].floor;
  state.partner.address.door = state.partnerById.partnerAddresses[0].door;
}

async function deletePartner(id) {
  const uri = process.env.VUE_APP_FRONTENDV2_API_URL + `/rem/partner/${id}`;
  try {
    await Axios.delete(uri, {
      onTokenRefreshed: () => {
        deletePartner(id);
      },
    });
    toast.success(t("message.deleteSuccessful"));
    hideSidebar();
    if (props.params && props.params.onSave) {
      props.params.onSave();
    }
  } catch (error) {
    console.log(error);
    toast.error(t("message.deleteNotSuccessful"));
  }
}

function validation() {
  const empty =
    !state.partner.main_type ||
    !state.partner.sub_type ||
    !state.partner.name ||
    !state.partner.address.country ||
    !state.partner.address.postal ||
    !state.partner.address.county ||
    !state.partner.address.city ||
    !state.partner.address.public_area_name ||
    !state.partner.address.public_area_type ||
    !state.partner.address.house_number;
  if (empty) return false;

  const emptyTax =
    state.partner.partnerTypeForTax == t("message.privateIndividual")
      ? !state.partner.tax_id
      : !state.partner.tax_number;
  if (emptyTax) return false;

  const postalRegex = /^\d{4}$/;
  if (!postalRegex.test(state.partner.address.postal)) return false;

  return true;
}

async function fetchCountiesAndCities() {
  if (state.partner.address.postal !== "") {
    const uri =
      process.env.VUE_APP_ADMINV2_API_URL +
      `/partner/settlement/postal?postalCode=${state.partner.address.postal}`;

    try {
      {
        const response = await Axios.get(uri, {
          onTokenRefreshed: () => {
            fetchCountiesAndCities();
          },
        });
        state.cities = response.data;
      }
      autoFill();
    } catch (error) {
      console.log(error);
    }
  } else {
    emptyFill();
  }
}
const autoFill = () => {
  if (state.cities.length == 1) {
    state.partner.address.county = state.cities[0].county;
    state.partner.address.city = state.cities[0].name;
  } else {
    state.partner.address.county = state.cities[0].county;
    state.partner.address.city = t("message.choose");
    state.isCountyDisabled = true;
    state.isCityDisabled = false;
  }
};

const emptyFill = () => {
  if (state.partner.address.postal === "") {
    state.cities = [];
    state.isCountyDisabled = false;
    state.isCityDisabled = false;
  }
};

const postalWarning = computed(() => {
  return "*" + t("message.postalRegex");
});

async function updatePartner(id) {
  const uri = process.env.VUE_APP_FRONTENDV2_API_URL + "/rem/partner/" + id;
  const postBody = {
    partnerMainType: state.partner.main_type,
    partnerSubType: state.partner.sub_type,
    name: state.partner.name,
    partnerAddresses: [
      {
        addressType: "POSTAL",
        id: state.partner.address.id,
        postalCode: state.partner.address.postal,
        country: state.partner.address.country,
        county: state.partner.address.county,
        city: state.partner.address.city,
        publicAreaName: state.partner.address.public_area_name,
        publicAreaType: state.partner.address.public_area_type,
        houseNumber: state.partner.address.house_number,
        stairWay: state.partner.address.stairway,
        floor: state.partner.address.floor,
        door: state.partner.address.door,
      },
    ],
    taxId: state.partner.tax_id,
    taxNumber: state.partner.tax_number,
  };

  try {
    await Axios.patch(uri, postBody, {
      onTokenRefreshed: () => {
        updatePartner(id);
      },
    });
    hideSidebar();
    if (props.params && props.params.onSave) {
      props.params.onSave();
    }
    toast.success(t("message.successfulSave"));
  } catch (err) {
    console.log(err);
    toast.error(t("message.saveError"));
  }
}

onMounted(() => {
  getPartnerById();
  fetchCountiesAndCities();
});

const hideSidebar = () => {
  store.commit("hideSidebar");
};
</script>
