let uniqueField = 'propertiesGrid';

export default (t, editPropertyFn, toPropertyFn) => {
  return {
    endpoint: process.env.VUE_APP_FRONTENDV2_API_URL + `/rem/property`,
    idkey: "id",
    defaultOrderKey: 'id',
    defaultOrderDirection: 'desc',
    orderLocal: false,
    rootkey: "content",
    pagination: {
      gridUniqueId: uniqueField,
      active: true,
      page: 0,
      size: 10,
      // possiblePageSizes: [10, 20, 50, 100]
    },
    filtering: {
      active: true,
      local: false,
      //simple: true,
      //simpleHelpText: l('rvg-simple-filter-help-text'),
    },
    bulkOperation: {
      active: false
    },
    columnSelector: {
      active: true,
      gridUniqueId: uniqueField,
      mode: 'server', // server or local/null
      serverGridKey: 'worksGridColumns'
    },
    xlsxExport: {
      active: true
    },
    refreshable: {
      manualActive: true,
      autoActive: true,
      autoCanBeDisabled: true,
      gridUniqueId: uniqueField,
      autoValues: [{
        key: '30sec',
        refreshInterval: 30,
        title: t('message.rvg-automatic-list-refresh-30-sec'),
        default: true
      }, {
        key: '1min',
        refreshInterval: 60,
        title: t('message.rvg-automatic-list-refresh-1-min')
      }, {
        key: '5min',
        refreshInterval: 300,
        title: t('message.rvg-automatic-list-refresh-5-min'),
      }]
    },
    mapping: {
      "$operations": {
        title: "",
        visible: true,
        filterable: false,
        selectable: false,
        orderable: false,
        width: '50px',
        formatter: {
          type: 'Operations',
          config: {
            buttons: (vm, callMeToRefreshTheGrid, rowid, record) => {
              var buttons = [];

              buttons.push({
                title: '',
                tooltip: 'Page of Property',
                icon: '<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-4"><path stroke-linecap="round" stroke-linejoin="round" d="M12,8V4L20,12L12,20V16H4V8H12Z" /></svg>',
                classList: 'btn btn-secondary btn-sm hover:!opacity-80 btn-circle',
                testValueAttribute: 'propertyForwardButton',
                dropdowned: false,
                //disabled: record.endDate ? false : true,
                onClick: () => {
                  toPropertyFn(record.id)
                }
              })

              buttons.push({
                title: '',
                tooltip: 'Edit property',
                icon: '<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-4"><path stroke-linecap="round" stroke-linejoin="round" d="M12 3L2 12H5V20H10V14H14V15.11L19.43 9.68L12 3M21.04 11.14C20.9 11.14 20.76 11.2 20.65 11.3L19.65 12.3L21.7 14.35L22.7 13.35C22.91 13.14 22.91 12.79 22.7 12.58L21.42 11.3C21.32 11.2 21.18 11.14 21.04 11.14M19.06 12.88L13 18.94V21H15.06L21.11 14.93L19.06 12.88Z" /></svg>',
                classList: 'btn btn-secondary btn-sm hover:!opacity-80 btn-circle',
                testValueAttribute: 'propertyEditButton',
                dropdowned: false,
                //disabled: record.endDate ? false : true,
                onClick: () => {
                  editPropertyFn(record.id)
                }
              })

              return buttons;
            }
          }
        }
      },
      "id": {
        title: "ID",
        visible: false,
        selectable: false, // non-optional, must be selected
        filterable: {
          active: true,
          type: 'Text'
        },
        exportable: true,
        orderable: true,
      },
      "property.propertySheetAppellation": {
        title: t("message.propertySheetAppellation"),
        visible: true,
        selectable: true, // non-optional, must be selected
        filterable: {
          active: false,
          type: 'Text'
        },
        exportable: true,
        orderable: false,
        middleware: (value, record) => {
          return record?.basicDataPropertySheetAppellation ? record.basicDataPropertySheetAppellation : 'N/A'
        }
      },
      "property.floorArea": {
        title: t("message.floorArea"),
        visible: true,
        selectable: true, // non-optional, must be selected
        filterable: {
          active: false,
          type: 'Text'
        },
        exportable: true,
        orderable: false,
        middleware: (value, record) => {    
          return record?.basicDataFloorArea ? record.basicDataFloorArea : 'N/A'
        },             
      },
      "property.kng": {
        title: t("message.kng"),
        visible: true,
        selectable: true, // non-optional, must be selected
        filterable: {
          active: false,
          type: 'Text'
        },
        exportable: true,
        orderable: false,
        middleware: (value, record) => {    
          return record?.basicDataKng ? record.basicDataKng : 'N/A'
        },             
      },
      "property.floc": {
        title: t("message.floc"),
        visible: true,
        selectable: true, // non-optional, must be selected
        filterable: {
          active: false,
          type: 'Text'
        },
        exportable: true,
        orderable: false,
        middleware: (value, record) => {    
          return record?.basicDataFloc ? record.basicDataFloc : 'N/A'
        },             
      },
      "property.sap": {
        title: t("message.sap"),
        visible: true,
        selectable: true, // non-optional, must be selected
        filterable: {
          active: false,
          type: 'Text'
        },
        exportable: true,
        orderable: false,
        middleware: (value, record) => {    
          return record?.basicDataSap ? record.basicDataSap : 'N/A'
        },             
      },
      "property.type": {
        title: t("message.type"),
        visible: true,
        selectable: true, // non-optional, must be selected
        filterable: {
          active: false,
          type: 'Text'
        },
        exportable: true,
        orderable: false,
        middleware: (value, record) => {    
          return record?.basicDataTypeValue ? record.basicDataTypeValue : 'N/A'
        },             
      },
      "property.protection": {
        title: t("message.protection"),
        visible: true,
        selectable: true, // non-optional, must be selected
        filterable: {
          active: false,
          type: 'Text'
        },
        exportable: true,
        orderable: false,
        middleware: (value, record) => {    
          return record?.basicDataProtection ? record.basicDataProtection : 'N/A'
        },             
      },
      "property.protectionNote": {
        title: t("message.protectionNote"),
        visible: true,
        selectable: true, // non-optional, must be selected
        filterable: {
          active: false,
          type: 'Text'
        },
        exportable: true,
        orderable: false,
        middleware: (value, record) => {    
          return record?.basicDataProtectionNote ? record.basicDataProtectionNote : 'N/A'
        },             
      },
      "property.numberDedicated": {
        title: t("message.numberDedicated"),
        visible: true,
        selectable: true, // non-optional, must be selected
        filterable: {
          active: false,
          type: 'Number'
        },
        exportable: true,
        orderable: false,
        middleware: (value, record) => {    
          return record?.basicDataNumberDedicated ? record.basicDataNumberDedicated : 'N/A'
        },             
      },
      "property.numberCanBeSeated": {
        title: t("message.numberCanBeSeated"),
        visible: true,
        selectable: true, // non-optional, must be selected
        filterable: {
          active: false,
          type: 'Number'
        },
        exportable: true,
        orderable: false,
        middleware: (value, record) => {    
          return record?.basicDataNumberCanBeSeated ? record.basicDataNumberCanBeSeated : 'N/A'
        },             
      },
      "property.numberSeated": {
        title: t("message.numberSeated"),
        visible: true,
        selectable: true, // non-optional, must be selected
        filterable: {
          active: false,
          type: 'Number'
        },
        exportable: true,
        orderable: false,
        middleware: (value, record) => {    
          return record?.basicDataNumberSeated ? record.basicDataNumberSeated : 'N/A'
        },             
      },
      "property.classification": {
        title: t("message.classification"),
        visible: true,
        selectable: true, // non-optional, must be selected
        filterable: {
          active: false,
          type: 'Text'
        },
        exportable: true,
        orderable: false,
        middleware: (value, record) => {    
          return record?.basicDataClassificationValue ? record.basicDataClassificationValue : 'N/A'
        },             
      },
      "property.status": {
        title: t("message.status"),
        visible: true,
        selectable: true, // non-optional, must be selected
        filterable: {
          active: false,
          type: 'Text'
        },
        exportable: true,
        orderable: false,
        middleware: (value, record) => {    
          return record?.basicDataStatusValue ? record.basicDataStatusValue : 'N/A'
        },             
      },
      "property.solarPanel": {
        title: t("message.solarPanel"),
        visible: true,
        selectable: true, // non-optional, must be selected
        filterable: {
          active: false,
          type: 'Boolean'
        },
        exportable: true,
        orderable: false,
        middleware: (value, record) => {    
          return record?.basicDataSolarPanel && record.basicDataSolarPanel ? t("message.yes") : t("message.no")
        },             
      },
      "property.fuelCellGenerator": {
        title: t("message.fuelCellGenerator"),
        visible: true,
        selectable: true, // non-optional, must be selected
        filterable: {
          active: false,
          type: 'Boolean'
        },
        exportable: true,
        orderable: false,
        middleware: (value, record) => {    
          return record?.basicDataFuelCellGenerator && record.basicDataFuelCellGenerator ? t("message.yes") : t("message.no")
        },             
      },
      "property.dieselGenerator": {
        title: t("message.dieselGenerator"),
        visible: true,
        selectable: true, // non-optional, must be selected
        filterable: {
          active: false,
          type: 'Boolean'
        },
        exportable: true,
        orderable: false,
        middleware: (value, record) => {    
          return record?.basicDataDieselGenerator && record.basicDataDieselGenerator ? t("message.yes") : t("message.no")
        },             
      },
      "property.energyTradingPartner": {
        title: t("message.energyTradingPartner"),
        visible: true,
        selectable: true, // non-optional, must be selected
        filterable: {
          active: false,
          type: 'Boolean'
        },
        exportable: true,
        orderable: false,
        middleware: (value, record) => {    
          return record?.basicDataEnergyTradingPartner && record.basicDataEnergyTradingPartner ? t("message.yes") : t("message.no")
        },             
      }
    }
  }
}
