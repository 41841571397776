<template>
  <div class="h-full overflow-y-scroll w-full">
    <div class="p-5 pb-2 mr-2 flex flex-col grow border-b-0 border-base-300">
      <div class="flex flex-row items-center gap-2 justify-center">
        <h2
          class="text-base font-sans font-semibold uppercase text-secondary flex flex-row items-center gap-2 grow"
        >
          <span class="text-secondary">{{ $t("message.addNewBuilding") }}</span>
        </h2>
        <a
          href="javascript:void(null)"
          class="hover:text-secondary"
          @click="hideSidebar"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
            class="w-6 h-6"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M6 18L18 6M6 6l12 12"
            />
          </svg>
        </a>
      </div>
    </div>

    <div class="px-5 pb-5">
      <div class="flex flex-col items-center justify-center gap-2 w-full">
        <div class="flex flex-row gap-3 w-full mt-2">
          <div class="form-control w-full mt-0">
            <label class="label mt-0 pt-0" for="">
              <span class="label-text-alt"
                >SiteDesign</span
              >
            </label>
            <SearchSiteDesign v-model="state.siteDesignId" type="siteDesign"/>
          </div>
        </div>

        <div class="flex flex-row gap-3 w-full mt-2">
          <div class="form-control w-full mt-0 pt-3">
            <label class="label mt-0 pt-0" for="">
              <span class="label-text-alt flex flex-row"
                >{{ $t("message.floorArea") }}
                <div
                  class="tooltip tooltip-hover tooltip-right"
                  data-tip="A megadott területnek egyeznie kell a helyiségek területének összegével."
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    class="w-5 h-5 pl-1 pb-1 text-secondary"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M11 7V9H13V7H11M14 17V15H13V11H10V13H11V15H10V17H14M22 12C22 17.5 17.5 22 12 22C6.5 22 2 17.5 2 12C2 6.5 6.5 2 12 2C17.5 2 22 6.5 22 12M20 12C20 7.58 16.42 4 12 4C7.58 4 4 7.58 4 12C4 16.42 7.58 20 12 20C16.42 20 20 16.42 20 12Z"
                    />
                  </svg>
                </div>
              </span>
            </label>
            <input
              v-model="state.basicData.floorArea"
              type="number"
              class="input input-sm input-bordered w-full"
            />
          </div>

          <div class="form-control w-full mt-0">
            <label class="label mt-0 pt-0" for="">
              <span class="label-text-alt"
                >{{ $t("message.mtArea")
                }}</span
              >
            </label>
            <input
              v-model="state.basicData.mtArea"
              type="number"
              class="input input-sm input-bordered w-full"
            />
          </div>

          <div class="form-control w-full mt-0 pt-4">
            <label class="label mt-0 pt-0" for="">
              <span class="label-text-alt"
                >{{$t("message.premiseNumber")}}</span
              >
            </label>
            <input
              v-model="state.basicData.premiseCount"
              type="number"
              class="input input-sm input-bordered w-full"
            />
          </div>
        </div>

        <div class="flex flex-row gap-3 w-full mt-2">
          <div class="form-control w-full mt-0">
            <label class="label mt-0 pt-0" for="">
              <span class="label-text-alt"
                >{{$t("message.mainFunction")}}<span class="text-error">*</span></span
              >
            </label>
            <OptionValueSelector
              v-model="state.basicData.mainFunction"
              type="BUILDING_MAIN_FUNCTION"
            />
          </div>

          <div class="form-control w-full mt-0 pt-1">
            <label class="label mt-0 pt-0" for="">
              <span class="label-text-alt"
                >{{$t("message.isAccessible")}}</span
              >
            </label>
            <select
              v-model="state.selectedAccessibility"
              class="select select-sm w-full select-bordered"
              @change="updateAccessibility"
            >
              <option disabled selected>{{ $t("message.choose") }}</option>
              <option value="yes">{{ $t("message.yes") }}</option>
              <option value="no">{{ $t("message.no") }}</option>
            </select>
          </div>
        </div>

        <div class="flex flex-row gap-3 w-full mt-2">
          <div class="form-control w-full mt-0">
            <label class="label mt-0 pt-0" for="">
              <span class="label-text-alt"
                >{{ $t("message.numberDedicated")
                }}</span
              >
            </label>
            <input
              v-model="state.basicData.numberDedicated"
              type="number"
              class="input input-sm input-bordered w-full"
            />
          </div>

          <div class="form-control w-full mt-0">
            <label class="label mt-0 pt-0" for="">
              <span class="label-text-alt"
                >{{ $t("message.numberCanBeSeated")
                }}</span
              >
            </label>
            <input
              v-model="state.basicData.numberCanBeSeated"
              type="number"
              class="input input-sm input-bordered w-full"
            />
          </div>

          <div class="form-control w-full mt-0">
            <label class="label mt-0 pt-0" for="">
              <span class="label-text-alt"
                >{{ $t("message.numberSeated")
                }}</span
              >
            </label>
            <input
              v-model="state.basicData.numberSeated"
              type="number"
              class="input input-sm input-bordered w-full"
            />
          </div>
        </div>

        <div
          class="flex flex-row gap-3 w-full border-b-2 border-secondary mt-4 font-bold"
        >
          <div class="form-control w-full mt-2">
            <label class="label mt-0 pt-0" for="">
              <span class="label-text">{{$t("message.baseDatas")}}</span>
            </label>
          </div>
        </div>

        <div class="flex flex-row gap-3 w-full mt-2">
          <div class="form-control w-full mt-0">
            <label class="label mt-0 pt-0" for="">
              <span class="label-text-alt"
                >{{$t("message.buildingMaxHeight")}}
              </span>
            </label>
            <input
              v-model="state.basicData.maxHeight"
              type="number"
              class="input input-sm input-bordered w-full"
            />
          </div>

          <div class="form-control w-full mt-0">
            <label class="label mt-0 pt-0" for="">
              <span class="label-text-alt"
                >{{ $t("message.status")
                }}<span class="text-error">*</span></span
              >
            </label>
            <OptionValueSelector
              v-model="state.basicData.status"
              type="BUILDING_STATUS"
            />
          </div>
        </div>

        <div class="flex flex-row gap-3 w-full mt-2">
          <div class="form-control w-full mt-0">
            <label class="label mt-0 pt-0" for="">
              <span class="label-text-alt"
                >{{$t("message.floorsBelowSurface")}}
              </span>
            </label>
            <input
              v-model="state.basicData.floorsBelowSurface"
              type="text"
              class="input input-sm input-bordered w-full"
            />
          </div>

          <div class="form-control w-full mt-0">
            <label class="label mt-0 pt-0" for="">
              <span class="label-text-alt"
                >{{$t("message.floorsAboveSurface")}}
              </span>
            </label>
            <input
              v-model="state.basicData.floorsAboveSurface"
              type="text"
              class="input input-sm input-bordered w-full"
            />
          </div>
        </div>

        <div class="flex flex-row gap-3 w-full mt-2">
          <div class="form-control w-full mt-0">
            <label class="label mt-0 pt-0" for="">
              <span class="label-text-alt"
                >{{$t("message.attic")}}</span
              >
            </label>
            <select
              v-model="state.selectedAttic"
              class="select select-sm w-full select-bordered"
              @change="updateAttic"
            >
              <option disabled selected>{{ $t("message.choose") }}</option>
              <option value="yes">{{ $t("message.yes") }}</option>
              <option value="no">{{ $t("message.no") }}</option>
            </select>
          </div>

          <div class="form-control w-full mt-0">
            <label class="label mt-0 pt-0" for="">
              <span class="label-text-alt"
                >{{$t("message.atticCanBeUsed")}}</span
              >
            </label>
            <select
              v-model="state.selectedAtticCanBeUsed"
              class="select select-sm w-full select-bordered"
              @change="updateAtticCanBeUsed"
            >
              <option disabled selected>{{ $t("message.choose") }}</option>
              <option value="yes">{{ $t("message.yes") }}</option>
              <option value="no">{{ $t("message.no") }}</option>
            </select>
          </div>
        </div>

        <div class="flex flex-row gap-3 w-full mt-2">
          <div class="form-control w-full mt-0">
            <label class="label mt-0 pt-0" for="">
              <span class="label-text-alt"
                >{{ $t("message.sap") }}</span
              >
            </label>
            <OptionValueSelector
              v-model="state.basicData.sap"
              type="SAP_BUILDING_IDENTIFIER"
            />
          </div>

          <div class="form-control w-full mt-0">
            <label class="label mt-0 pt-0" for="">
              <span class="label-text-alt"
                >TCAFM
              </span>
            </label>
            <input
              v-model="state.basicData.tcafm"
              type="text"
              class="input input-sm input-bordered w-full"
            />
          </div>
        </div>

        <div class="flex flex-row gap-3 w-full mt-2">
          <div class="form-control w-full mt-0">
            <label class="label mt-0 pt-0" for="">
              <span class="label-text-alt"
                >{{ $t("message.parcelNumber")
                }}
              </span>
            </label>
            <input
              v-model="state.basicData.parcelNumber"
              type="text"
              class="input input-sm input-bordered w-full"
            />
          </div>

          <div class="form-control w-full mt-0">
            <label class="label mt-0 pt-0" for="">
              <span class="label-text-alt"
                >{{$t("message.paymentObligations")}}
              </span>
            </label>
            <input
              v-model="state.paymentObligation"
              type="text"
              class="input input-sm input-bordered w-full"
            />
          </div>
        </div>

        <div class="flex flex-row gap-3 w-full mt-2">
          <div class="form-control w-full mt-0">
            <label class="label mt-0 pt-0" for="">
              <span class="label-text-alt"
                >{{$t("message.numberOfStairways")}}<span class="text-error">*</span>
              </span>
            </label>
            <input
              v-model="state.numberOfStairways"
              type="number"
              class="input input-sm input-bordered w-full"
            />
          </div>

          <div class="form-control w-full mt-0">
            <label class="label mt-0 pt-0" for="">
              <span class="label-text-alt"
                >{{$t("message.numberOfElevators")}}<span class="text-error">*</span>
              </span>
            </label>
            <input
              v-model="state.numberOfElevators"
              type="number"
              class="input input-sm input-bordered w-full"
            />
          </div>
        </div>

        <div
          class="flex flex-row gap-3 w-full border-b-2 border-secondary mt-4 font-bold"
        >
          <div class="form-control w-full mt-2">
            <label class="label mt-0 pt-0" for="">
              <span class="label-text">{{$t("message.structureTypes")}}</span>
            </label>
          </div>
        </div>

        <div class="flex flex-row gap-3 w-full mt-2">
          <div class="form-control w-full mt-0">
            <label class="label mt-0 pt-0" for="">
              <span class="label-text-alt"
                >{{$t("message.foundation")}}<span class="text-error">*</span></span
              >
            </label>
            <OptionValueSelector v-model="state.foundation" type="FOUNDATION" />
          </div>

          <div class="form-control w-full mt-0">
            <label class="label mt-0 pt-0" for="">
              <span class="label-text-alt"
                >{{$t("message.bearingMasonry")}}<span class="text-error">*</span></span
              >
            </label>
            <OptionValueSelector
              v-model="state.bearingMasonry"
              type="BEARING_MASONRY"
            />
          </div>
        </div>

        <div class="flex flex-row gap-3 w-full mt-2">
          <div class="form-control w-full mt-0">
            <label class="label mt-0 pt-0" for="">
              <span class="label-text-alt"
                >{{$t("message.slabStructInter")}}</span
              >
            </label>
            <OptionValueSelector
              v-model="state.slabStructureInter"
              type="SLAB_STRUCTURE_INTERMEDIATE"
            />
          </div>
        </div>

        <div class="flex flex-row gap-3 w-full mt-2">
          <div class="form-control w-full mt-0">
            <label class="label mt-0 pt-0" for="">
              <span class="label-text-alt"
                >{{$t("message.slabSubStructInter")}}</span
              >
            </label>
            <OptionValueSelector
              v-model="state.slabSubStructureInter"
              type="SLAB_SUB_STRUCTURE_INTERMEDIATE"
            />
          </div>
        </div>

        <div class="flex flex-row gap-3 w-full mt-2">
          <div class="form-control w-full mt-0">
            <label class="label mt-0 pt-0" for="">
              <span class="label-text-alt"
                >{{$t("message.slabStructClosing")}}</span
              >
            </label>
            <OptionValueSelector
              v-model="state.slabStructureClosing"
              type="SLAB_STRUCTURE_CLOSING"
            />
          </div>
        </div>

        <div class="flex flex-row gap-3 w-full mt-2">
          <div class="form-control w-full mt-0">
            <label class="label mt-0 pt-0" for="">
              <span class="label-text-alt"
                >{{$t("message.slabSubStructClosing")}}</span
              >
            </label>
            <OptionValueSelector
              v-model="state.slabSubStructureClosing"
              type="SLAB_SUB_STRUCTURE_CLOSING"
            />
          </div>
        </div>

        <div class="flex flex-row gap-3 w-full mt-2">
          <div class="form-control w-full mt-0">
            <label class="label mt-0 pt-0" for="">
              <span class="label-text-alt"
                >{{$t("message.flatRoof")}}</span
              >
            </label>
            <OptionValueSelector v-model="state.flatRoof" type="FLAT_ROOF" />
          </div>

          <div class="form-control w-full mt-0">
            <label class="label mt-0 pt-0" for="">
              <span class="label-text-alt"
                >{{$t("message.roofType")}}</span
              >
            </label>
            <OptionValueSelector v-model="state.roofType" type="ROOF_TYPE" />
          </div>
        </div>

        <div class="flex flex-row gap-3 w-full mt-2">
          <div class="form-control w-full mt-0">
            <label class="label mt-0 pt-0" for="">
              <span class="label-text-alt"
                >{{$t("message.roofing")}}</span
              >
            </label>
            <OptionValueSelector v-model="state.roofing" type="ROOFING" />
          </div>

          <div class="form-control w-full mt-0">
            <label class="label mt-0 pt-0" for="">
              <span class="label-text-alt"
                >{{$t("message.insulation")}}</span
              >
            </label>
            <OptionValueSelector v-model="state.insulation" type="INSULATION" />
          </div>
        </div>

        <div class="flex flex-row gap-3 w-full mt-2">
          <div class="form-control w-full mt-0">
            <label class="label mt-0 pt-0" for="">
              <span class="label-text-alt"
                >{{$t("message.energetics")}}</span
              >
            </label>
            <input
              v-model="state.energetics"
              type="text"
              class="input input-sm input-bordered w-full"
            />
          </div>
        </div>

        <div
          class="flex flex-row gap-3 w-full border-b-2 border-secondary mt-4 font-bold"
        >
          <div class="form-control w-full mt-2">
            <label class="label mt-0 pt-0" for="">
              <span class="label-text">{{$t("message.mtResponsibles")}}</span>
            </label>
          </div>
        </div>

        <div class="flex flex-row gap-3 w-full mt-2">
          <div class="form-control w-full mt-0">
            <label class="label mt-0 pt-0" for="">
              <span class="label-text-alt"
                >{{$t("message.operation")}}<span class="text-error">*</span>
              </span>
            </label>
            <input
              v-model="state.basicData.operation"
              type="text"
              class="input input-sm input-bordered w-full"
            />
          </div>
        </div>

        <div class="flex flex-row gap-3 w-full mt-2">
          <div class="form-control w-full mt-0">
            <label class="label mt-0 pt-0" for="">
              <span class="label-text-alt"
                >{{$t("message.cleaning")}}
              </span>
            </label>
            <input
              v-model="state.basicData.cleaner"
              type="text"
              class="input input-sm input-bordered w-full"
            />
          </div>
        </div>

        <div class="flex flex-row gap-3 w-full mt-2">
          <div class="form-control w-full mt-0">
            <label class="label mt-0 pt-0" for="">
              <span class="label-text-alt"
                >{{$t("message.supplies")}}
              </span>
            </label>
            <input
              v-model="state.basicData.supplies"
              type="text"
              class="input input-sm input-bordered w-full"
            />
          </div>
        </div>

        <div class="flex flex-row gap-3 w-full mt-2">
          <div class="form-control w-full mt-0">
            <label class="label mt-0 pt-0" for="">
              <span class="label-text-alt"
                >TUI
              </span>
            </label>
            <input
              v-model="state.basicData.tui"
              type="text"
              class="input input-sm input-bordered w-full"
            />
          </div>
        </div>

        <div class="flex flex-row gap-3 w-full mt-2">
          <div class="form-control w-full mt-0">
            <label class="label mt-0 pt-0" for="">
              <span class="label-text-alt"
                >{{$t("message.ownerOfAssets")}}
              </span>
            </label>
            <input
              v-model="state.basicData.ownerOfAssets"
              type="text"
              class="input input-sm input-bordered w-full"
            />
          </div>
        </div>

        <div class="flex flex-row gap-3 w-full mt-2">
          <div class="form-control w-full mt-0">
            <label class="label mt-0 pt-0" for="">
              <span class="label-text-alt"
                >{{$t("message.assetManager")}}</span
              >
            </label>
            <input
              v-model="state.basicData.assetManager"
              type="text"
              class="input input-sm input-bordered w-full"
            />
          </div>
        </div>

        <div class="w-full mt-3">
          <button
            :disabled="!validation()"
            class="disabled:opacity-60 btn btn-sm bg-magenta text-white font-light text-xs border-none"
            @click="addNewBuilding"
          >
            {{ $t("message.addNewBuilding") }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import Axios from "@/misc/axios";
import { onMounted, reactive } from "vue";
import store from "@/misc/vuex-store";
import toast from "@/misc/toast.js";
import { useI18n } from "vue-i18n";
import { useRoute } from "vue-router";
import OptionValueSelector from "../../../../components/OptionValueSelector.vue";
import SearchSiteDesign from "../../../../components/SearchOptions.vue";

const router = useRoute();
const propertyId = router.params.propertyId;

const { t } = useI18n();

const props = defineProps(["params"]);

const state = reactive({
  siteDesignId: null,
  selectedAccessibility: "",
  selectedAtticCanBeUsed: "",
  selectedAttic: "",
  basicData: {
    floorArea: null,
    mtArea: null,
    mainFunction: null,
    premiseCount: null,
    isAccessible: null,
    numberDedicated: null,
    numberCanBeSeated: null,
    numberSeated: null,
    maxHeight: null,
    status: null,
    floorsBelowSurface: "",
    floorsAboveSurface: "",
    attic: null,
    atticCanBeUsed: null,
    sap: null,
    tcafm: "",
    parcelNumber: "",
    operation: "",
    cleaner: "",
    supplies: "",
    tui: "",
    ownerOfAssets: "",
    assetManager: "",
  },
  paymentObligation: "",
  numberOfStairways: null,
  numberOfElevators: null,
  foundation: null,
  bearingMasonry: null,
  slabStructureInter: null,
  slabSubStructureInter: null,
  slabStructureClosing: null,
  slabSubStructureClosing: null,
  flatRoof: null,
  roofType: null,
  roofing: null,
  insulation: null,
  energetics: "",
});

async function addNewBuilding() {
  const uri = process.env.VUE_APP_FRONTENDV2_API_URL + "/rem/building";
  let postBody = {
    siteDesignId: state.siteDesignId,
    property: propertyId,
    basicData: {
      floorArea: state.basicData.floorArea,
      mtArea: state.basicData.mtArea,
      mainFunction: state.basicData.mainFunction,
      premiseNumber: state.basicData.premiseCount,
      isAccessible: state.basicData.isAccessible,
      numberDedicated: state.basicData.numberDedicated,
      numberCanBeSeated: state.basicData.numberCanBeSeated,
      numberSeated: state.basicData.numberSeated,
      maxHeight: state.basicData.maxHeight,
      status: state.basicData.status,
      floorsBelowSurface: state.basicData.floorsBelowSurface,
      floorsAboveSurface: state.basicData.floorsAboveSurface,
      attic: state.basicData.attic,
      atticCanBeUsed: state.basicData.atticCanBeUsed,
      sap: state.basicData.sap,
      tcafm: state.basicData.tcafm,
      parcelNumber: state.basicData.parcelNumber,
      operation: state.basicData.operation,
      cleaner: state.basicData.cleaner,
      supplies: state.basicData.supplies,
      tui: state.basicData.tui,
      ownerOfAssets: state.basicData.ownerOfAssets,
      assetManager: state.basicData.assetManager,
    },
    paymentObligation: state.paymentObligation,
    numberOfStairways: state.numberOfStairways,
    numberOfElevators: state.numberOfElevators,
    foundation: state.foundation,
    bearingMasonry: state.bearingMasonry,
    slabStructureInter: state.slabStructureInter,
    slabSubStructureInter: state.slabSubStructureInter,
    slabStructureClosing: state.slabStructureClosing,
    slabSubStructureClosing: state.slabSubStructureClosing,
    flatRoof: state.flatRoof,
    roofType: state.roofType,
    roofing: state.roofing,
    insulation: state.insulation,
    energetics: state.energetics,
  };

  try {
    await Axios.post(uri, postBody, {
      onTokenRefreshed: () => {
        addNewBuilding();
      },
    });
    hideSidebar();
    if (props.params && props.params.onSave) {
      props.params.onSave();
    }
    toast.success(t("message.successfulSave"));
  } catch (err) {
    console.log(err);
    toast.error(t("message.saveError"));
  }
}

const hideSidebar = () => {
  
  
  store.commit("hideSidebar");
};

const updateAccessibility = () => {
  state.selectedAccessibility === "yes"
    ? (state.basicData.isAccessible = 1)
    : (state.basicData.isAccessible = 0);
};

const updateAttic = () => {
  state.selectedAttic === "yes"
    ? (state.basicData.attic = 1)
    : (state.basicData.attic = 0);
};

const updateAtticCanBeUsed = () => {
  state.selectedAtticCanBeUsed === "yes"
    ? (state.basicData.atticCanBeUsed = 1)
    : (state.basicData.atticCanBeUsed = 0);
};

function validation() {
  const empty =
    !state.basicData.mainFunction ||
    !state.basicData.status ||
    !state.paymentObligation ||
    !state.numberOfStairways ||
    !state.numberOfElevators ||
    !state.foundation ||
    !state.bearingMasonry ||
    !state.basicData.operation

  if (empty) return false;

  return true;
}

onMounted(() => {});
</script>

<style scoped>
.hide {
  display: none;
}

.info:hover + .hide {
  display: flex;
  color: red;
}
</style>
