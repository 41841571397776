<template>
  <div class="h-full overflow-y-auto w-full">
    <div
      class="mt-5 mx-5 flex flex-row grow border-b-0 border-base-300 justify-between"
    >
      <div class="flex flex-row items-center gap-2 justify-center">
        <h2
          class="text-base font-sans font-semibold uppercase text-secondary flex flex-row items-center gap-2 grow"
        >
          <span class="text-secondary">{{
            $t("message.addNewUtilization")
          }}</span>
        </h2>
      </div>
      <a
        href="javascript:void(null)"
        class="hover:text-secondary"
        @click="hideSidebar"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke-width="1.5"
          stroke="currentColor"
          class="w-6 h-6"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            d="M6 18L18 6M6 6l12 12"
          />
        </svg>
      </a>
    </div>

    <div class="px-5 pb-5">
      <div class="flex flex-col items-center justify-center gap-2 w-full">
        <div class="flex flex-row gap-3 w-full mt-2">
          <div class="form-control w-full mt-0">
            <label class="label mt-0 pt-0" for="">
              <span class="label-text-alt">{{
                $t("message.legalRelationship")
              }}</span>
            </label>
            <OptionValueSelector
              type="UTILIZATION_LEGAL_RELATIONSHIP"
              v-model="state.legalRelationshipType"
            />
          </div>
        </div>

        <div class="flex flex-row gap-3 w-full mt-2">
          <div class="form-control w-full mt-0">
            <label class="label mt-0 pt-0" for="">
              <span class="label-text-alt">{{ $t("message.floorArea") }}</span>
            </label>
            <input
              v-model="state.floorArea"
              type="number"
              class="input input-sm input-bordered w-full"
            />
          </div>
        </div>

        <div class="flex flex-row gap-3 w-full mt-2">
          <div class="form-control w-full mt-0">
            <label class="label mt-0 pt-0" for="">
              <span class="label-text-alt">{{
                $t("message.legalSituationStart")
              }}</span>
            </label>
            <input
              v-model="state.legalRelationshipStart"
              type="date"
              class="input input-sm input-bordered w-full"
            />
          </div>
        </div>

        <div class="flex flex-row gap-3 w-full mt-2">
          <div class="form-control w-full mt-0">
            <label class="label mt-0 pt-0" for="">
              <span class="label-text-alt">{{
                $t("message.legalSituationEnd")
              }}</span>
            </label>
            <input
              v-model="state.legalRelationshipEnd"
              type="date"
              class="input input-sm input-bordered w-full"
            />
          </div>
        </div>

        <div class="w-full mt-3">
          <button
            class="disabled:opacity-60 btn btn-sm bg-magenta text-white font-light text-xs border-none"
            @click="addNewUtilization"
          >
            {{ $t("message.save") }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import axios from "axios";
import { reactive } from "vue";
import toast from "@/misc/toast.js";
import { useI18n } from "vue-i18n";
import store from "@/misc/vuex-store";
import { useRoute } from "vue-router";
import OptionValueSelector from "../../../../components/OptionValueSelector.vue";

const route = useRoute();
const premiseId = route.params.premiseId;
const buildingId = route.params.buildingId;
const propertyId = route.params.propertyId;

const { t } = useI18n();
const props = defineProps(["params"]);

const state = reactive({
  legalRelationshipType: null,
  floorArea: null,
  legalRelationshipStart: null,
  legalRelationshipEnd: null,
});

const addNewUtilization = async () => {
  const uri = process.env.VUE_APP_FRONTENDV2_API_URL + `/rem/utilization`;
  let postBody = "";

  if (propertyId) {
    postBody = {
      legalRelationship: state.legalRelationshipType,
      floorSpace: state.floorArea,
      legalRelationshipStart: state.legalRelationshipStart,
      legalRelationshipEnd: state.legalRelationshipEnd,
      partnerId: null,
      propertyId: propertyId,
    };
  }

  if (buildingId) {
    postBody = {
      legalRelationship: state.legalRelationshipType,
      floorSpace: state.floorArea,
      legalRelationshipStart: state.legalRelationshipStart,
      legalRelationshipEnd: state.legalRelationshipEnd,
      partnerId: null,
      buildingId: buildingId,
    };
  }

  if (premiseId) {
    postBody = {
      legalRelationship: state.legalRelationshipType,
      floorSpace: state.floorArea,
      legalRelationshipStart: state.legalRelationshipStart,
      legalRelationshipEnd: state.legalRelationshipEnd,
      partnerId: null,
      premiseId: premiseId,
    };
  }

  try {
    await axios.post(uri, postBody, {
      onTokenRefreshed: () => {
        addNewUtilization();
      },
    });
    hideSidebar();
    if (props.params && props.params.onSave) {
      props.params.onSave();
    }
    toast.success(t("message.successfulSave"));
  } catch (err) {
    console.log(err);
    toast.error(t("message.saveError"));
  }
};

const hideSidebar = () => {
  store.commit("hideSidebar");
};
</script>

<style lang="scss" scoped></style>
