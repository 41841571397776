import { createApp } from 'vue'
import router from './misc/router.js'
import store from './misc/vuex-store.js'
import moment from 'moment';
import './index.css'
import App from './App.vue'
import 'leaflet/dist/leaflet.css';
import 'vue-leaflet-markercluster/dist/style.css'
import VueCookie from 'vue-cookie';
import vuexStore from './misc/vuex-store.js';
import messages from './messages'
import { createI18n } from 'vue-i18n'
import VueApexCharts from "vue3-apexcharts";
import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";
import vSelect from "vue-select";

let language;
if(VueCookie.get('language')) {
  language = VueCookie.get('language');
} else {
  language = navigator.language.slice(0,2);
}
vuexStore.commit('setLanguage', language);

const i18n = createI18n({
  locale: language,
  fallbackLocale: process.env.VUE_APP_DEFAULT_LANGUAGE,
  globalInjection: true,
  legacy: false,
  messages,
})

const app = createApp(App);
app.component("v-select", vSelect)
app.use(i18n);
app.use(router);
app.use(Toast)
app.use(store);
app.use(VueApexCharts);

moment.locale('hu');

if(VueCookie.get('access_token')) {
  vuexStore.commit('setAccessToken', VueCookie.get('access_token'));
  vuexStore.commit('setIdToken', VueCookie.get('id_token'));
  vuexStore.commit('setRoles', VueCookie.get('roles'));
}

if(VueCookie.get('graph_access_token')) {
  vuexStore.commit('setGraphAccessToken', VueCookie.get('graph_access_token'));
  vuexStore.commit('setGraphIdToken', VueCookie.get('graph_id_token'));
}

app.mount('#app');
