let uniqueField = 'buildingsGrid';

export default (t, deleteBuildingFn, editBuildingFn, toBuildingFn, propertyId) => {
  return {
    endpoint: process.env.VUE_APP_FRONTENDV2_API_URL + `/rem/property/${propertyId}/buildings`,
    idkey: "id",
    defaultOrderKey: 'id',
    defaultOrderDirection: 'desc',
    orderLocal: false,
    rootkey: "content",
    pagination: {
      gridUniqueId: uniqueField,
      active: true,
      page: 0,
      size: 10,
      // possiblePageSizes: [10, 20, 50, 100]
    },
    filtering: {
      active: true,
      local: false,
      //simple: true,
      //simpleHelpText: l('rvg-simple-filter-help-text'),
    },
    bulkOperation: {
      active: false
    },
    columnSelector: {
      active: true,
      gridUniqueId: uniqueField,
      mode: 'server', // server or local/null
      serverGridKey: 'worksGridColumns'
    },
    xlsxExport: {
      active: true
    },
    refreshable: {
      manualActive: true,
      autoActive: true,
      autoCanBeDisabled: true,
      gridUniqueId: uniqueField,
      autoValues: [{
        key: '30sec',
        refreshInterval: 30,
        title: t('message.rvg-automatic-list-refresh-30-sec'),
        default: true
      }, {
        key: '1min',
        refreshInterval: 60,
        title: t('message.rvg-automatic-list-refresh-1-min'),
      }, {
        key: '5min',
        refreshInterval: 300,
        title: t('message.rvg-automatic-list-refresh-5-min'),
      }]
    },
    mapping: {
      "$operations": {
        title: "",
        visible: true,
        filterable: false,
        selectable: false,
        orderable: false,
        width: '50px',
        formatter: {
          type: 'Operations',
          config: {
            buttons: (vm, callMeToRefreshTheGrid, rowid, record) => {
              var buttons = [];

              buttons.push({
                title: '',
                tooltip: 'Page of Building',
                icon: '<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-4"><path stroke-linecap="round" stroke-linejoin="round" d="M12,8V4L20,12L12,20V16H4V8H12Z" /></svg>',
                classList: 'btn btn-secondary btn-sm hover:!opacity-80 btn-circle',
                testValueAttribute: 'buildingForwardButton',
                dropdowned: false,
                //disabled: record.endDate ? false : true,
                onClick: () => {
                  toBuildingFn(record.id)
                }
              })

              buttons.push({
                title: '',
                tooltip: 'Edit building',
                icon: '<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-4"><path stroke-linecap="round" stroke-linejoin="round" d="M12 3L2 12H5V20H10V14H14V15.11L19.43 9.68L12 3M21.04 11.14C20.9 11.14 20.76 11.2 20.65 11.3L19.65 12.3L21.7 14.35L22.7 13.35C22.91 13.14 22.91 12.79 22.7 12.58L21.42 11.3C21.32 11.2 21.18 11.14 21.04 11.14M19.06 12.88L13 18.94V21H15.06L21.11 14.93L19.06 12.88Z" /></svg>',
                classList: 'btn btn-secondary btn-sm hover:!opacity-80 btn-circle',
                testValueAttribute: 'buildingEditButton',
                dropdowned: false,
                //disabled: record.endDate ? false : true,
                onClick: () => {
                  editBuildingFn(record.id)
                }
              })

              buttons.push({
                title: '',
                tooltip: 'Delete building',
                icon: '<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-4"><path stroke-linecap="round" stroke-linejoin="round" d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0" /></svg>',
                classList: 'btn btn-secondary btn-sm hover:!opacity-80 btn-circle',
                testValueAttribute: 'buildingDeleteButton',
                dropdowned: false,
                //disabled: record.endDate ? false : true,
                onClick: () => {
                  deleteBuildingFn(record.id)
                }
              })

              return buttons;
            }
          }
        }
      },
      "id": {
        title: "ID",
        visible: false,
        selectable: false, // non-optional, must be selected
        filterable: {
          active: true,
          type: 'Number'
        },
        exportable: true,
        orderable: true,
      },
      "siteDesign.name": {
        title: t("message.building"),
        visible: true,
        selectable: true, // non-optional, must be selected
        filterable: {
          active: true,
          type: 'Text'
        },
        exportable: true,
        orderable: false,
        middleware: (value, record) => {
          return record?.siteDesignName ? record.siteDesignName : 'N/A'
        }
      },
      "mainFunction": {
        title: t("message.mainFunction"),
        visible: true,
        selectable: true, // non-optional, must be selected
        filterable: {
          active: false,
          type: 'Text'
        },
        exportable: true,
        orderable: false,
        middleware: (value, record) => {    
          return record?.basicDataMainFunctionValue ? record.basicDataMainFunctionValue : 'N/A'
        },             
      },
      "floorArea": {
        title: t("message.floorArea"),
        visible: true,
        selectable: true, // non-optional, must be selected
        filterable: {
          active: false,
          type: 'Number'
        },
        exportable: true,
        orderable: false,
        middleware: (value, record) => {    
          return record?.basicDataFloorArea ? record.basicDataFloorArea : 'N/A'
        },             
      },
      "mtArea": {
        title: t("message.mtArea"),
        visible: true,
        selectable: true, // non-optional, must be selected
        filterable: {
          active: false,
          type: 'Number'
        },
        exportable: true,
        orderable: false,
        middleware: (value, record) => {    
          return record?.basicDataMtArea ? record.basicDataMtArea : 'N/A'
        },             
      },
      "premiseNumber": {
        title: t("message.premiseNumber"),
        visible: true,
        selectable: true, // non-optional, must be selected
        filterable: {
          active: false,
          type: 'Text'
        },
        exportable: true,
        orderable: false,
        middleware: (value, record) => {    
          return record?.basicDataPremiseNumber ? record.basicDataPremiseNumber : 'N/A'
        },             
      },
      "isAccessible": {
        title: t("message.isAccessible"),
        visible: true,
        selectable: true, // non-optional, must be selected
        filterable: {
          active: false,
          type: 'Text'
        },
        exportable: true,
        orderable: false,
        middleware: (value, record) => {    
          return record?.basicDataIsAccessible && record.basicDataIsAccessible ? t("message.yes") : t("message.no")
        },             
      },
      "numberDedicated": {
        title: t("message.numberDedicated"),
        visible: true,
        selectable: true, // non-optional, must be selected
        filterable: {
          active: false,
          type: 'Number'
        },
        exportable: true,
        orderable: false,
        middleware: (value, record) => {    
          return record?.basicDataNumberDedicated ? record.basicDataNumberDedicated : 'N/A'
        },             
      },
      "numberCanBeSeated": {
        title: t("message.numberCanBeSeated"),
        visible: true,
        selectable: true, // non-optional, must be selected
        filterable: {
          active: false,
          type: 'Number'
        },
        exportable: true,
        orderable: false,
        middleware: (value, record) => {    
          return record?.basicDataNumberCanBeSeated ? record.basicDataNumberCanBeSeated : 'N/A'
        },             
      },
      "numberSeated": {
        title: t("message.numberSeated"),
        visible: true,
        selectable: true, // non-optional, must be selected
        filterable: {
          active: false,
          type: 'Number'
        },
        exportable: true,
        orderable: false,
        middleware: (value, record) => {    
          return record?.basicDataNumberSeated ? record.basicDataNumberSeated : 'N/A'
        },             
      },
      "maxHeight": {
        title: t("message.buildingMaxHeight"),
        visible: true,
        selectable: true, // non-optional, must be selected
        filterable: {
          active: false,
          type: 'Text'
        },
        exportable: true,
        orderable: false,
        middleware: (value, record) => {    
          return record?.basicDataMaxHeight ? record.basicDataMaxHeight : 'N/A'
        },             
      },
      "status": {
        title: t("message.status"),
        visible: true,
        selectable: true, // non-optional, must be selected
        filterable: {
          active: false,
          type: 'Text'
        },
        exportable: true,
        orderable: false,
        middleware: (value, record) => {    
          return record?.basicDataStatusValue ? record.basicDataStatusValue : 'N/A'
        },             
      },
      "floorsBelowSurface": {
        title: t("message.floorsBelowSurface"),
        visible: true,
        selectable: true, // non-optional, must be selected
        filterable: {
          active: false,
          type: 'Text'
        },
        exportable: true,
        orderable: false,
        middleware: (value, record) => {    
          return record?.basicDataFloorsBelowSurface ? record.basicDataFloorsBelowSurface : 'N/A'
        },             
      },
      "floorsAboveSurface": {
        title: t("message.floorsAboveSurface"),
        visible: true,
        selectable: true, // non-optional, must be selected
        filterable: {
          active: false,
          type: 'Text'
        },
        exportable: true,
        orderable: false,
        middleware: (value, record) => {    
          return record?.basicDataFloorsAboveSurface ? record.basicDataFloorsAboveSurface : 'N/A'
        },             
      },
      "attic": {
        title: t("message.attic"),
        visible: true,
        selectable: true, // non-optional, must be selected
        filterable: {
          active: false,
          type: 'Text'
        },
        exportable: true,
        orderable: false,
        middleware: (value, record) => {    
          return record?.basicDataAttic && record.basicDataAttic ? t("message.yes") : t("message.no")
        },             
      },
      "atticCanBeUsed": {
        title: t("message.atticCanBeUsed"),
        visible: true,
        selectable: true, // non-optional, must be selected
        filterable: {
          active: false,
          type: 'Text'
        },
        exportable: true,
        orderable: false,
        middleware: (value, record) => {    
          return record?.basicDataAtticCanBeUsed && record.basicDataAtticCanBeUsed ? t("message.yes") : t("message.no")
        },             
      },
      "sap": {
        title: t("message.sap"),
        visible: true,
        selectable: true, // non-optional, must be selected
        filterable: {
          active: false,
          type: 'Text'
        },
        exportable: true,
        orderable: false,
        middleware: (value, record) => {    
          return record?.basicDataSapValue ? record.basicDataSapValue : 'N/A'
        },             
      },
      "tcafm": {
        title: 'TCAFM',
        visible: true,
        selectable: true, // non-optional, must be selected
        filterable: {
          active: false,
          type: 'Text'
        },
        exportable: true,
        orderable: false,
        middleware: (value, record) => {    
          return record?.basicDataTcafm ? record.basicDataTcafm : 'N/A'
        },             
      },
      "parcelNumber": {
        title: t("message.parcelNumber"),
        visible: true,
        selectable: true, // non-optional, must be selected
        filterable: {
          active: false,
          type: 'Text'
        },
        exportable: true,
        orderable: false,
        middleware: (value, record) => {    
          return record?.basicDataParcelNumber ? record.basicDataParcelNumber : 'N/A'
        },             
      },
      "operation": {
        title: t("message.operation"),
        visible: true,
        selectable: true, // non-optional, must be selected
        filterable: {
          active: false,
          type: 'Text'
        },
        exportable: true,
        orderable: false,
        middleware: (value, record) => {    
          return record?.basicDataOperation ? record.basicDataOperation : 'N/A'
        },             
      },
      "cleaner": {
        title: t("message.cleaning"),
        visible: true,
        selectable: true, // non-optional, must be selected
        filterable: {
          active: false,
          type: 'Text'
        },
        exportable: true,
        orderable: false,
        middleware: (value, record) => {    
          return record?.basicDataCleaner ? record.basicDataCleaner : 'N/A'
        },             
      },
      "supplies": {
        title: t("message.supplies"),
        visible: true,
        selectable: true, // non-optional, must be selected
        filterable: {
          active: false,
          type: 'Text'
        },
        exportable: true,
        orderable: false,
        middleware: (value, record) => {    
          return record?.basicDataSupplies ? record.basicDataSupplies : 'N/A'
        },             
      },
      "tui": {
        title: 'TUI',
        visible: true,
        selectable: true, // non-optional, must be selected
        filterable: {
          active: false,
          type: 'Text'
        },
        exportable: true,
        orderable: false,
        middleware: (value, record) => {    
          return record?.basicDataTui ? record.basicDataTui : 'N/A'
        },             
      },
      "ownerOfAssets": {
        title: t("message.ownerOfAssets"),
        visible: true,
        selectable: true, // non-optional, must be selected
        filterable: {
          active: false,
          type: 'Text'
        },
        exportable: true,
        orderable: false,
        middleware: (value, record) => {    
          return record?.basicDataOwnerOfAssets ? record.basicDataOwnerOfAssets : 'N/A'
        },             
      },
      "assetManager": {
        title: t("message.assetManager"),
        visible: true,
        selectable: true, // non-optional, must be selected
        filterable: {
          active: false,
          type: 'Text'
        },
        exportable: true,
        orderable: false,
        middleware: (value, record) => {    
          return record?.basicDataAssetManager ? record.basicDataAssetManager : 'N/A'
        },             
      },
      "numberOfStairways": {
        title: t("message.numberOfStairways"),
        visible: true,
        selectable: true, // non-optional, must be selected
        filterable: {
          active: true,
          type: 'Number'
        },
        exportable: true,
        orderable: false,
        middleware: (value, record) => {    
          return record?.numberOfStairways ? record.numberOfStairways : 'N/A'
        },             
      },
      "numberOfElevators": {
        title: t("message.numberOfElevators"),
        visible: true,
        selectable: true, // non-optional, must be selected
        filterable: {
          active: true,
          type: 'Number'
        },
        exportable: true,
        orderable: false,
        middleware: (value, record) => {    
          return record?.numberOfElevators ? record.numberOfElevators : 'N/A'
        },             
      },
      "paymentObligations": {
        title: t("message.paymentObligations"),
        visible: true,
        selectable: true, // non-optional, must be selected
        filterable: {
          active: false,
          type: 'Text'
        },
        exportable: true,
        orderable: false,
        middleware: (value, record) => {    
          return record?.paymentObligation ? record.paymentObligation : 'N/A'
        },             
      },
      "foundation": {
        title: t("message.foundation"),
        visible: true,
        selectable: true, // non-optional, must be selected
        filterable: {
          active: false,
          type: 'Text'
        },
        exportable: true,
        orderable: false,
        middleware: (value, record) => {    
          return record?.foundationValue ? record.foundationValue : 'N/A'
        },             
      },
      "bearingMasonry": {
        title: t("message.bearingMasonry"),
        visible: true,
        selectable: true, // non-optional, must be selected
        filterable: {
          active: false,
          type: 'Text'
        },
        exportable: true,
        orderable: false,
        middleware: (value, record) => {    
          return record?.bearingMasonryValue ? record.bearingMasonryValue : 'N/A'
        },             
      },
      "slabStructureInter": {
        title: t("message.slabStructInter"),
        visible: true,
        selectable: true, // non-optional, must be selected
        filterable: {
          active: false,
          type: 'Text'
        },
        exportable: true,
        orderable: false,
        middleware: (value, record) => {    
          return record?.slabStructureInterValue ? record.slabStructureInterValue : 'N/A'
        },             
      },
      "slabSubStructureInter": {
        title: t("message.slabSubStructInter"),
        visible: true,
        selectable: true, // non-optional, must be selected
        filterable: {
          active: false,
          type: 'Text'
        },
        exportable: true,
        orderable: false,
        middleware: (value, record) => {    
          return record?.slabSubStructureInterValue ? record.slabSubStructureInterValue : 'N/A'
        },             
      },
      "slabStructureClosing": {
        title: t("message.slabStructClosing"),
        visible: true,
        selectable: true, // non-optional, must be selected
        filterable: {
          active: false,
          type: 'Text'
        },
        exportable: true,
        orderable: false,
        middleware: (value, record) => {    
          return record?.slabStructureClosingValue ? record.slabStructureClosingValue : 'N/A'
        },             
      },
      "slabSubStructureClosing": {
        title: t("message.slabSubStructClosing"),
        visible: true,
        selectable: true, // non-optional, must be selected
        filterable: {
          active: false,
          type: 'Text'
        },
        exportable: true,
        orderable: false,
        middleware: (value, record) => {    
          return record?.slabSubStructureClosingValue ? record.slabSubStructureClosingValue : 'N/A'
        },             
      },
      "flatRoof": {
        title: t("message.flatRoof"),
        visible: true,
        selectable: true, // non-optional, must be selected
        filterable: {
          active: false,
          type: 'Text'
        },
        exportable: true,
        orderable: false,
        middleware: (value, record) => {    
          return record?.flatRoofValue ? record.flatRoofValue : 'N/A'
        },             
      },
      "roofType": {
        title: t("message.roofType"),
        visible: true,
        selectable: true, // non-optional, must be selected
        filterable: {
          active: false,
          type: 'Text'
        },
        exportable: true,
        orderable: false,
        middleware: (value, record) => {    
          return record?.roofTypeValue ? record.roofTypeValue : 'N/A'
        },             
      },
      "roofing": {
        title: t("message.roofing"),
        visible: true,
        selectable: true, // non-optional, must be selected
        filterable: {
          active: false,
          type: 'Text'
        },
        exportable: true,
        orderable: false,
        middleware: (value, record) => {    
          return record?.roofingValue ? record.roofingValue : 'N/A'
        },             
      },
      "insulation": {
        title: t("message.insulation"),
        visible: true,
        selectable: true, // non-optional, must be selected
        filterable: {
          active: false,
          type: 'Text'
        },
        exportable: true,
        orderable: false,
        middleware: (value, record) => {    
          return record?.insulationValue ? record.insulationValue : 'N/A'
        },             
      },
      "buildingStatus": {
        title: t("message.status"),
        visible: true,
        selectable: true, // non-optional, must be selected
        filterable: {
          active: false,
          type: 'Text'
        },
        exportable: true,
        orderable: false,
        middleware: (value, record) => {    
          return record?.basicDataStatusValue ? record.basicDataStatusValue : 'N/A'
        },             
      },
    }
  }
}
