<template>
  <div>
    <div class="space-y-6" v-if="props.title == 'addresses'">
      <div class="flex flex-row justify-between">
        <h2 class="text-xl uppercase text-[#c6c6c6] font-bold">
          {{ $t("message.addresses") }}
        </h2>
        <!--A kommentelt kód direkt maradt a fájlban, végpontok létrehozásával/változtatásával szükség lesz majd rájuk-->
        <!-- <button
        @click="
          store.commit('showSidebar', {
            type: 'addNewAddress',
            params: {
              onSave: () => {
                fetchAddresses();
              },
            },
          })
        "
        class="whitespace-nowrap rounded-md text-sm font-medium transition-colors focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:pointer-events-none disabled:opacity-50 bg-secondary text-nrgGray shadow hover:bg-nrgGray hover:text-secondary h-9 px-4 py-2 font-bold uppercase"
      >
        {{ $t("message.addNewAddress") }}
      </button> -->
      </div>
      <RobberVueGrid
        v-if="!state.forceRefresh"
        ref="propertyAddressesGrid"
        :config="propertyAddressesGridConfigComputed"
      />
      <!-- <input
      type="checkbox"
      class="modal-toggle"
      v-model="state.showRemoveConfirmation"
    />
    <div class="modal">
      <div class="modal-box">
        <h3
          class="text-lg text-left font-normal hover:text-gray-100 text-gray-100"
        >
          Biztos, hogy törli a címet?
        </h3>
        <div class="modal-action">
          <label
            class="btn btn-sm text-white font-light text-xs"
            @click="
              () => {
                state.showRemoveConfirmation = false;
              }
            "
            >{{ $t("message.deny") }}</label
          >
          <label
            class="btn btn-sm bg-magenta text-white font-light text-xs"
            @click="
              () => {
                deleteBuilding(state.rvgBuildingId);
                state.showRemoveConfirmation = false;
              }
            "
            >{{ $t("message.confirm") }}</label
          >
        </div>
      </div>
    </div> -->
    </div>
  </div>
</template>

<script setup>
import { computed, reactive, watch } from "vue";
import { useI18n } from "vue-i18n";
import { useRoute } from "vue-router";
// import Axios from "@/misc/axios.js";
import propertyAddressesGridConfig from "@/misc/gridConfigs/propertyAddressesGridConfig.js";
import RobberVueGrid from "../../components/RVG/RobberVueGrid.vue";

const props = defineProps(["title"]);
const { t } = useI18n();
const route = useRoute();
const propertyId = route.params.propertyId;

const propertyAddressesGridConfigComputed = computed(() => {
  return propertyAddressesGridConfig(t, propertyId);
});

const state = reactive({
  addresses: [],
  forceRefresh: false,
});

// const fetchAddresses = async () => {
//   const uri =
//     process.env.VUE_APP_FRONTENDV2_API_URL +
//     `/rem/property/${propertyId}/addresses`;
//   try {
//     const response = await Axios.get(uri, {
//       onTokenRefreshed: () => {
//         fetchAddresses();
//       },
//     });

//     state.addresses = response.content;
//   } catch (error) {
//     console.log(error);
//   }
// };

const refreshGrid = () => {
  state.forceRefresh = true;
  setTimeout(() => {
    state.forceRefresh = false;
  }, 100);
};

watch(
  () => state.addresses,
  () => {
    refreshGrid();
  }
);
</script>

<style scoped></style>
