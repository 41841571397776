<template>
  <div>
    <div class="space-y-6">
      <div class="flex flex-row justify-between">
        <h2 class="text-xl uppercase text-[#c6c6c6] font-bold">
          {{ $t("message.meters") }}
        </h2>
        <button
          @click="
            store.commit('showSidebar', {
              type: 'addNewMeter',
              params: {
                onSave: () => {
                  fetchMeters();
                },
              },
            })
          "
          class="whitespace-nowrap rounded-md text-sm font-medium transition-colors focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:pointer-events-none disabled:opacity-50 btn btn-sm bg-magenta text-white font-light text-xs shadow hover:bg-nrgGray h-9 px-4 py-2 font-bold uppercase"
        >
          {{ $t("message.addNewMeter") }}
        </button>
      </div>
      <RobberVueGrid
        v-if="!state.forceRefresh"
        ref="metersGrid"
        :config="metersGridConfigComputed"
      />
      <input
        type="checkbox"
        class="modal-toggle"
        v-model="state.showRemoveConfirmation"
      />
      <div class="modal">
        <div class="modal-box">
          <h3
            class="text-lg text-left font-normal hover:text-gray-100 text-gray-100"
          >
            {{ $t("message.deleteMeterConfirm") }}
          </h3>
          <div class="modal-action">
            <label
              class="btn btn-sm text-white font-light text-xs"
              @click="
                () => {
                  state.showRemoveConfirmation = false;
                }
              "
              >{{ $t("message.deny") }}</label
            >
            <label
              class="btn btn-sm bg-magenta text-white font-light text-xs"
              @click="
                () => {
                  deleteMeter(state.rvgMeterId);
                  state.showRemoveConfirmation = false;
                }
              "
              >{{ $t("message.confirm") }}</label
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed, reactive, watch } from "vue";
import { useI18n } from "vue-i18n";
import { useRoute } from "vue-router";
import Axios from "@/misc/axios.js";
import metersGridConfig from "@/misc/gridConfigs/metersGridConfig.js";
import RobberVueGrid from "../../components/RVG/RobberVueGrid.vue";
import store from "@/misc/vuex-store.js";
import Utils from "../../misc/Utils";

const { t } = useI18n();
const route = useRoute();
const buildingId = route.params.buildingId;

const metersGridConfigComputed = computed(() => {
  return metersGridConfig(
    t,
    buildingId,
    setRemoveConfirmation,
    editUtilization
  );
});

const state = reactive({
  meters: [],
  forceRefresh: false,
  showRemoveConfirmation: false,
  rvgMeterId: null,
});

const setRemoveConfirmation = (id) => {
  state.rvgMeterId = id;
  state.showRemoveConfirmation = true;
};

const editUtilization = async (id) => {
  const response = await Utils.fetchMeterById(id);

  store.commit("setMeterById", response);
  store.commit("showSidebar", {
    type: "editMeter",
    params: {
      onSave: () => {
        fetchMeters();
      },
    },
  });
};

const fetchMeters = async () => {
  const uri =
    process.env.VUE_APP_FRONTENDV2_API_URL +
    `/rem/meter/building/${buildingId}`;
  try {
    const response = await Axios.get(uri, {
      onTokenRefreshed: () => {
        fetchMeters();
      },
    });

    state.meters = response.content;
  } catch (error) {
    console.log(error);
  }
};

const deleteMeter = async (id) => {
  const uri = process.env.VUE_APP_FRONTENDV2_API_URL + `/rem/meter/${id}`;

  try {
    await Axios.delete(uri, {
      onTokenRefreshed: () => {
        deleteMeter();
      },
    });
  } catch (error) {
    console.log(error);
  }
};

const refreshGrid = () => {
  state.forceRefresh = true;
  setTimeout(() => {
    state.forceRefresh = false;
  }, 100);
};

watch(
  () => state.meters,
  () => {
    refreshGrid();
  }
);
</script>

<style scoped></style>
