const messages =
{
    en: {
        message: {
            resolution: 'Resolution',
            minutes: 'minutes',
            day: 'day',
            hour: 'hour',
            floors: 'Floors',
            infos: 'Infos',
            currentPresenceGist: 'Current presence',
            numberOfWorkplaces: 'Number of workplaces',
            sqm: 'sqm',
            externalIdentifier: 'External Identifier',
            regenerateApiKeyConfirmation: 'Are you sure you want to regenerate the API key?',
            regenerateApiKeyConfirmationCancel: 'Cancel',
            regenerateApiKeyConfirmationOk: 'confirm',
            generateApiKeyButtonTitle: 'New API key',
            copy: 'Copy',
            missingApiKey: 'API key is not generated yet',
            copiedToClipboard: 'Copied to clipboard',
            generateApiKeyTitle: 'API key',
            generateApiKeyExample: 'Example',
            noAssignedDevice: 'No assigned device',
            backToTheMap: 'Back to the map',
            error: 'Error',
            invalidMapView: 'The data of the loaded map is incorrect',
            loading: 'Loading...',
            loadedAreaHasNoCoordinates: 'The loaded area does not have geographical coordinates.',
            device: 'Device',
            noSubArea: 'There are no subareas attached to this area.',
            lidarView: 'LIDAR View',
            select: 'Select',
            close: 'Close',
            rain: 'Rain: ',
            displayName: 'Display Name',
            deviceType: 'Device Type',
            choose: 'Choose',
            adapterType: 'Adapter Type',
            adapterConfig: 'Adapter Configuration',
            save: 'Save',
            test: 'Test',
            confirmRemove: 'Are you sure that you want to remove this device?',
            confirmRemoveScenario: 'Are you sure that you want to remove this scenario?',
            confirmRemovePartner: 'Are you sure you want to delete this partner?',
            noDevice: 'There is no device added right now',
            listOfDevices: 'List of Devices',
            addDevice: 'Add Device',
            editDevice: 'Edit Device',
            addNew: 'Add new device',
            noSDChildrenFound: 'There are no sub entity on this site design',
            editZone: 'Edit Zone',
            designation: 'Designation',
            zoneName: 'The name of the zone',
            numberOfPossibleWorkplaces: 'Number of possible workplaces',
            floorSpace: 'Floorspace',
            zoneType: 'Zone type',
            zoneGroups: 'Zone groups',
            isActive: 'Active?',
            successfulSave: 'Successful save...',
            notSuccessfulSave: 'Save is not successful...',
            searchingDevice: 'Searching Device...',
            noResults: 'The search has no result...',
            atleastOneCharacter: 'Atleast one character...',
            profile: 'Profile',
            logoutInProgress: 'Logout in progress...',
            name: 'Name',
            position: 'Position',
            breakdown: 'Breakdown',
            temperatureData: 'TEMPERATURE DATA',
            populationData: 'POPULATION DATA',
            errorAlt: 'A page refresh may solve the problem',
            permissionDenied: 'Permission Denied',
            goBack: 'Go to the previous page',
            sessionExpired: 'The session expired, please try to log in again!',
            loginAgain: 'I will log in again',
            unauthorizedTenant: 'The usage of this application is not allowed for your company!',
            saveError: 'Errors occured during save',
            anonymus: 'Anonymus',
            deleteSuccessful: 'Delete successful',
            deleteNotSuccessful: 'Delete was not successful',
            noViewImage: 'There is no available image for this view!',
            deviceNamePlaceholder: 'eg. Device Name',
            confirm: 'Confirm',
            deny: 'Deny',
            zoneNamePlaceholder: 'eg. Corridor',
            egNumPlaceholder: 'eg. 100',
            search: 'Search',
            searchGeneric: 'Generic Search',
            searchZone: 'Zone Search',
            searchSiteDesign: 'Site Design Search',
            admin: 'Admin',
            optionEditorLink: 'Edit',
            optionAdminEditorLink: 'Administration',
            optionEditorPage: 'Option Value Editor',
            optionValue: 'Option Value',
            type: 'Type',
            optionsNumber: 'Option Number',
            actions: 'Actions',
            newOptionValue: 'New value',
            zoneMap: 'Zone Map',
            temperatureMap: 'Temperature Map',
            deviceMap: 'Device Map',
            zoneAdmin: 'Zone Admin',
            system: 'System',
            scenarios: 'Scenarios',
            propertyManagementModule: 'Property Management Module',
            reports: 'Reports',
            newScenario: 'New scenario',
            noScenarioAddedCurrently: 'No scenarios configured yet.',
            selectOutput: 'Select Output',
            selectInputs: 'Select Inputs',
            selectTime: 'Set start time',
            editScenario: 'Edit Scenario',
            data: 'Data',
            responders: 'Scenarios',
            syncSites: 'Sync',
            outgoingIPAddress: "Outgoing IP address",
            chooseLang: 'Choose Language: ',
            hungarian: 'Hungarian',
            english: 'English',
            addNewPartner: 'Add New Partner',
            mainType: 'Main type',
            subType: 'Sub type',
            taxId: 'Tax ID',
            taxNumber: 'Tax number',
            accountNumber: 'Account number',
            address: 'Address',
            country: 'Country',
            postal: 'Postal code',
            county: 'County',
            city: 'City',
            publicAreaName: 'Public area name',
            publicAreaType: 'Public area type',
            houseNumber: 'House number',
            stairway: 'Stairway',
            floor: 'Floor',
            door: 'Door',
            documents: 'Documents',
            fileName: 'Filename',
            uploadDate: 'Upload date',
            uploader: 'Uploader',
            contacts: 'Contacts',
            contactPhone: 'Phone',
            rank: 'Rank',
            note: 'Note',
            partners: 'Partners',
            addNewPartnerType: 'Add New Partner Type',
            privateIndividual: 'Private Individual',
            company: 'Company',
            district: 'District',
            postalRegex: 'To display the counties and settlements, fill the postal code field!',
            baseDatas: 'Datas',
            buildings: 'Buildings',
            uploadFile: 'Upload File',
            upload: 'Upload',
            properties: 'Properties',
            addNewProperty: 'Add new property',
            parcelNumber: 'Parcel number',
            gpsCoordinates: 'GPS Coordinates',
            notes: 'Entry notes',
            floorArea: 'Floor area',
            mtArea: 'MT relevant sqm',
            kng: 'KNG Name',
            floc: 'FLOC ID',
            sap: 'SAP Code',
            propertySheetAppellation: 'Name according to the Ownership Sheet',
            protection: 'Protection',
            protectionNote: 'Protection description',
            numberDedicated: 'Mumber of dedicated sets',
            numberCanBeSeated: 'Number of seats',
            numberSeated: 'Number of seated persons',
            classification: 'Classification',
            status: 'Status',
            solarPanel: 'Solar Panel?',
            fuelCellGenerator: 'Fuel Cell Generator?',
            dieselGenerator: 'Diesel Generator?',
            energyTradingPartner: 'Energy Trading Partner?',
            noBuildingsFound: 'There are no buildings for the property',
            yes: 'Yes',
            no: 'No',
            addNewBuilding: 'New building',
            editPartner: 'Edit Partner',
            downloadEkrReport: 'Download EKR Report',
            area: 'Areas',
            addNewSiteDesign: 'Add New',
            editSiteDesign: 'Edit',
            siteDesignPlaceholder: 'eg. Site Design Name',
            globalSiteDesignNotFound: 'Site design not found, please update this adapter.',
            delete: "Delete",
            latitude: 'Latitude',
            longitude: 'Longitude',
            mapImage: 'Map Image',
            addNewContact: 'Add new contact',
            building: 'Building',
            mapView: 'Map view',
            property: 'Property',
            'rvg-automatic-list-refresh-30-sec': 'Automatic list refresh every 30 sec',
            'rvg-automatic-list-refresh-1-min': 'Automatic list refresh every minute',
            'rvg-automatic-list-refresh-5-min': 'Automatic list refresh every minute',
            mainFunction: 'Main function',
            numberOfStairways: 'Number of stairways',
            numberOfElevators: 'Number of elevators',
            paymentObligations: 'Payment obligations',
            noAppliedFilters: 'No applied filters...',
            updateProperty: 'Edit property',
            legalSituations: 'Legal situations',
            mtResponsibles: 'MT Responsibles',
            structureTypes: 'Structure types',
            updateContact: 'Edit contact',
            addLegalSituation: 'Add legal situation',
            legalSituation: 'Legal situation type',
            legalRelationship: 'Legal relationship type',
            ownership: 'Ownership (%)',
            legalSituationStart: 'Legal situation start date',
            legalSituationEnd: 'Legal situation end date',
            updateLegalSituation: 'Edit legal situation',
            deleteLegalSituation: 'Delete legal situation',
            confirmRemoveLS: 'Are you sure you want to delete this legal situation?',
            premises: 'Premises',
            addPremise: 'New premise',
            function: 'Function',
            identifier: 'Identifier',
            cremClassification: 'CREM classification',
            premiseNumber: 'Number of premises',
            isAccessible: 'Accessible?',
            stairwayId: 'Stairway ID',
            stairwayType: 'Stairway type',
            isSmokeFree: 'Smoke free?',
            isEscapeRoute: 'Escape route?',
            numberOfPerson: 'Maximum number of persons',
            loadCapacity: 'Load capacity',
            elevatorType: 'Elevator type',
            elevator: 'Elevator',
            isEmergencyElevator: 'Emergency elevator?',
            specialData: 'Special data',
            floorCovering: 'Floor covering',
            isFalseFloor:  'False floor?',
            isFalseCeiling: 'False ceiling?',
            fireProtectionClass: 'Fire protection class',
            builtInFireProtection: 'Built in fire protection',
            doorType: 'Door type',
            minHeight: 'Min ceiling height',
            maxHeight: 'Max ceiling height',
            szepaClass: 'SZEPA classification',
            sharedUse: 'Shared use?',
            isDedicated: 'Dedicated org unit?',
            canBeHeated: 'Can be heated?',
            canBeCooled: 'Can be cooled?',
            openableWindow: 'Openable windows?',
            windows: 'Windows',
            windowSize: 'Size',
            windowMaterial: 'Material',
            windowNumber: 'Number of windows',
            windowWidth: 'Width',
            windowHeight: 'Height',
            windowDepth: 'Depth',
            coolingHeatingDevices:  'Cooling-heating devices',
            isCentral: 'Central?',
            canBeControlled: 'Can be controlled?',
            outdoorUnit: 'Outdoor unit?',
            manufacturer: 'Manufacturer',
            energyIntake: 'Energy intake',
            coolingPerformance: 'Cooling performance',
            heatingPerformance: 'Heating performance',
            installationDate: 'Installation date',
            maintenanceCycle: 'Maintenance cycle (month)',
            yearOfManufacture: 'Year of manufacture',
            guarantee: 'Guarantee',
            confirmRemovePremise: 'Are you sure you want to delete this premise?',
            buildingMaxHeight: 'Max height of the building',
            floorsAboveSurface: 'Floors above surface',
            floorsBelowSurface: 'Floors below surface',
            attic: 'Is there an attic?',
            atticCanBeUsed: 'Usable?',
            sapBuildingId: 'SAP building identifier',
            foundation: 'Foundation',
            bearingMasonry: 'Bearing masonry',
            slabStructInter: 'Slab structure intermediate',
            slabSubStructInter: 'Slab substructure intermediate',
            slabStructClosing: 'Slab structure closing',
            slabSubStructClosing: 'Slab substructure closing',
            flatRoof: 'Flatroof?',
            roofType: 'Roof type',
            roofing: 'Roofing',
            insulation: 'Insulation',
            energetics: 'Energetics',
            operation: 'Operation',
            cleaning: 'Cleaning',
            supplies: 'Kitchen supplies',
            ownerOfAssets: 'Owner of assets',
            assetManager: 'Asset manager',
            addresses: 'Addresses',
            obligations: 'Obligations',
            utilizations:  'Utilizations',
            addNewAddress: 'New Address',
            addressType: 'Address type',
            buildingDeleteConfirm:  'Are you sure you want to delete this building?',
            utilizationDeleteConfirm: 'Are you sure you want to delete this utilization?',
            addNewUtilization: 'New utilization',
            otherRight: 'Other right name',
            permission: 'Permission',
            obliged: 'Obliged',
            deleteLegalSituationConfirm: 'Are you sure you want to delete this legal situation?',
            deleteObligationConfirm: 'Are you sure you want to delete the obligation?',
            deleteUtilizationConfirm: 'Are you sure you want to delete this utilization?',
            editUtilization: 'Edit utilization',
            meterType: 'Meter type',
            publicUtilityType: 'Public utility type',
            placement: 'Placement',
            readings: 'Readings',
            readingFrequency: 'Reading frequency',
            commType: 'Communication type',
            expirationDate: 'Expiration date',
            meters: 'Meters',
            addNewMeter: 'New meter',
            editMeter:'Edit meter',
            editPremise: 'Edit premise',
            cancel: 'Cancel',
            editDocument: 'Edit document',
            dateOfOrigin: 'Date of origin',
            startDate: 'Start date',
            endDate: 'End date'
        }
    },
    hu: {
        message: {
            resolution: 'Bontás',
            minutes: 'perc',
            day: 'nap',
            hour: 'óra',
            floors: 'Szintek',
            infos: 'Infók',
            currentPresenceGist: 'Aktuális jelenlét',
            numberOfWorkplaces: 'M. áll száma',
            sqm: 'Terület (m2)',
            externalIdentifier: 'Külső azonosító',
            regenerateApiKeyConfirmation: 'Biztosan újra generálja az API kulcsot?',
            regenerateApiKeyConfirmationCancel: 'Mégse',
            regenerateApiKeyConfirmationOk: 'Igen',
            generateApiKeyButtonTitle: 'Új API kulcs generálása',
            copy: 'Másolás',
            missingApiKey: 'API kulcs még nincs generálva',
            copiedToClipboard: 'Másolva a vágólapra',
            generateApiKeyTitle: 'API kulcs',
            generateApiKeyExample: 'Példa',
            noAssignedDevice: 'Nincs hozzárendelt eszköz',
            backToTheMap: 'Vissza a térképhez',
            error: 'Hiba történt',
            invalidMapView: 'A betöltött térkép adatai nem megfelelőek.',
            loading: 'Betöltés...',
            loadedAreaHasNoCoordinates: 'A betöltött területnek nincsenek meghatározott földrajzi koordinátái.',
            device: 'Eszköz',
            noSubArea: 'Ehhez a területhez nem tartozik alterület.',
            lidarView: 'LIDAR Nézet',
            select: 'Kiválasztás!',
            close: 'BEZÁRÁS',
            rain: 'Csapadék: ',
            displayName: 'Megjelenítendő név',
            deviceType: 'Eszköz típusa',
            choose: 'Válasszon',
            adapterType: 'Adapter típusa',
            adapterConfig: 'Adapter konfiguráció',
            save: 'Mentés',
            test: 'Teszt',
            confirmRemove: 'Biztos benne, hogy törli az eszközt?',
            confirmRemoveScenario: 'Biztos benne, hogy törli a scenariot?',
            confirmRemovePartner: 'Biztos benne, hogy törli a partnert?',
            noDevice: 'Jelenleg nincs eszköz hozzáadva',
            listOfDevices: 'Eszközök listája',
            addDevice: 'Eszköz hozzáadása',
            editDevice: 'Eszköz szerkesztése',
            addNew: 'Új hozzáadása',
            noSDChildrenFound: 'Ehhez a site designhoz nem található al site design',
            editZone: 'Zóna szerkesztése',
            designation: 'Megnevezés',
            zoneName: 'A zóna neve',
            numberOfPossibleWorkplaces: 'Lehetséges m.áll. száma',
            floorSpace: 'Alapterület',
            zoneType: 'Zóna típusa',
            zoneGroups: 'Zónacsoportok',
            isActive: 'Aktív?',
            successfulSave: 'Sikeres mentés...',
            notSuccessfulSave: 'Sikertelen mentés...',
            searchingDevice: 'Eszköz keresése...',
            noResults: 'Nincs találat...',
            atleastOneCharacter: 'Min. 1 karakter...',
            profile: 'Profil',
            logoutInProgress: 'Kijelentkezés folyamatban...',
            name: 'Név',
            position: 'Pozíció',
            breakdown: 'Bontás',
            temperatureData: 'HŐMÉRSÉKLETI ADATOK',
            populationData: 'LÉTSZÁM ADATOK',
            errorAlt: 'Az oldal frissítése talán megoldja a problémát',
            permissionDenied: 'Hozzáférés megtagadva',
            goBack: 'Visszalépés',
            sessionExpired: 'A munkamenet lejárt, jelentkezzen be újra!',
            loginAgain: 'Belépek újra',
            unauthorizedTenant: 'Az Ön szervezete számára nem engedélyezett az alkalmazás használata!',
            saveError: 'A mentés közben hibák léptek fel.',
            anonymus: 'Névtelen',
            deleteSuccessful: 'Sikeres törlés...',
            deleteNotSuccessful: 'Sikertelen törlés...',
            noViewImage: 'A nézethez nincs elérhető képi információ!',
            deviceNamePlaceholder: 'pl. Eszköz neve',
            confirm: 'Igen',
            deny: 'Mégse',
            zoneNamePlaceholder: 'pl. Folyosó',
            egNumPlaceholder: 'pl. 100',
            search: 'Keresés',
            searchGeneric: 'Általános Kereső',
            searchZone: 'Zóna Kereső',
            searchSiteDesign: 'Terület Kereső',
            admin: 'Admin',
            optionEditorLink: 'Szerkesztés',
            optionAdminEditorLink: 'Adminisztráció',
            optionEditorPage: 'Értékkészlet Kezelés',
            optionValue: 'Értékkészlet',
            type: 'Típus',
            optionsNumber: 'Opciók száma',
            actions: 'Műveletek',
            newOptionValue: 'Új Érték',
            zoneMap: 'Zónatérkép',
            temperatureMap: 'Hőmérsékleti térkép',
            deviceMap: 'Eszköztérkép',
            zoneAdmin: 'Zóna admin',       
            propertyManagementModule: 'Ingatlankezelő modul',
            system: 'Rendszer',
            scenarios: 'Szkenáriók',
            reports: 'Riportok',
            newScenario: 'Új szkenárió',
            noScenarioAddedCurrently: 'Jelenleg nincs scenario hozzáadva.',
            selectOutput: 'Kimenet kiválasztása',
            selectInputs: 'Bemenetek kiválasztása',
            selectTime: 'Időpont beállítása',
            editScenario: 'Szkenárió szerkesztése',
            data: 'Adatok',
            responders: 'Szkenáriók',
            syncSites: 'Frissítése',
            outgoingIPAddress: 'Kimenő IP cím',
            chooseLang: 'Válasszon nyelvet: ',
            hungarian: 'Magyar',
            english: 'Angol',
            addNewPartner: 'Partner hozzáadása',
            mainType: 'Főtípus',
            subType: 'Altípus',
            taxId: 'Adóazonosító jel',
            taxNumber: 'Adószám',
            accountNumber: 'Számlaszám',
            address: 'Cím',
            country: 'Ország',
            postal: 'Irányítószám',
            county: 'Megye',
            city: 'Település',
            publicAreaName: 'Közterület neve',
            publicAreaType: 'Közterület jellege',
            houseNumber: 'Házszám',
            stairway: 'Lépcsőház',
            floor: 'Emelet',
            door: 'Ajtó',
            documents: 'Dokumentumok',
            fileName: 'Fájlnév',
            uploadDate: 'Feltöltés dátuma',
            uploader: 'Feltöltő',
            contacts: 'Kapcsolattartók',
            contactPhone: 'Telefonszám',
            rank: 'Beosztás',
            note: 'Megjegyzés',
            partners: 'Partnerek',
            addNewPartnerType: 'Partnertípus hozzáadása',
            privateIndividual: 'Magánszemély',
            company: 'Cég',
            district: 'Kerület',
            postalRegex: 'A megyék és települések megjelenítéséhez töltse ki az irányítószám mezőt!',
            baseDatas: 'Adatok',
            buildings: 'Épületek',
            uploadFile: 'Fájl feltöltése',
            upload: 'Feltöltés',
            properties: 'Ingatlanok',
            addNewProperty: 'Új ingatlan hozzáadása',
            parcelNumber: 'Helyrajziszám',
            gpsCoordinates: 'GPS Koordináták',
            notes: 'Bejutási megjegyzések',
            floorArea: 'Alapterület',
            mtArea: 'MT releváns négyzetméter',
            kng: 'KNG Név',
            floc: 'FLOC Azonosító',
            sap: 'SAP kód',
            propertySheetAppellation: 'Tulajdoni lap szerinti megnevezés',
            protection: 'Védettség',
            protectionNote: 'Védettség leírás',
            numberDedicated: 'Dedikált létszám',
            numberCanBeSeated: 'Ültethető létszám',
            numberSeated: 'Ültetett létszám',
            classification: 'Besorolás',
            status: 'Státusz',
            solarPanel: 'Napelem van?',
            fuelCellGenerator: 'Üzemanyag cellás generátor van?',
            dieselGenerator: 'Telepített dízel ellátás van?',
            energyTradingPartner: 'Energiakereskedő Partner?',
            noBuildingsFound: 'Ehhez az ingatlanhoz nem találhatók épületek',
            yes: 'Igen',
            no: 'Nem',
            addNewBuilding: 'Új épület hozzáadása',
            editPartner: 'Partner szerkesztése',
            downloadEkrReport: 'EKR riport letöltése',
            areas: 'Areák',
            addNewSiteDesign: 'Új hozzáadása',
            editSiteDesign: 'Szerkesztés',
            siteDesignPlaceholder: 'pl. Site Design Név',
            globalSiteDesignNotFound: 'Site design nem található, frissítse ezt az adaptert.',
            delete: "Törlés",
            latitude: 'Szélesség',
            longitude: 'Hosszúság',
            mapImage: 'Térkép kép',
            addNewContact: 'Új kapcsolattartó felvétele',
            building: 'Épület',
            mapView: 'Térképnézet',
            property: 'Ingatlan',
            'rvg-automatic-list-refresh-30-sec': 'Automatikus lista frissítés 30 mp-ként',
            'rvg-automatic-list-refresh-1-min': 'Automatikus lista frissítés percenként',
            'rvg-automatic-list-refresh-5-min': 'Automatikus lista frissítés 5 percenként',
            mainFunction: 'Fő funkció',
            numberOfStairways: 'Lépcsőházak száma',
            numberOfElevators: 'Felvonók száma',
            paymentObligations: 'Fizetési kötelezettség',
            noAppliedFilters: 'Nincs beállított szűrőfeltétel...',
            updateProperty: 'Ingatlan szerkesztése',
            legalSituations: 'Jogi helyzetek',
            mtResponsibles: 'MT-n belüli felelősök',
            structureTypes: 'Szerkezet típusok',
            updateContact: 'Kapcsolattartó adatainak szerkesztése',
            addLegalSituation: 'Új jogi helyzet',
            legalSituation: 'Jogi helyzet',
            legalRelationship: 'Alapviszony jellege',
            ownership: 'Tulajdonyhányad (%)',
            legalSituationStart: 'Jogviszony kezdete',
            legalSituationEnd: 'Jogviszony vége',
            updateLegalSituation: 'Jogi helyzet szerkesztése',
            deleteLegalSituation: 'Jogi helyzet törlése',
            confirmRemoveLS: 'Biztos törölni szeretné ezt a jogi helyzetet?',
            premises: 'Helyiségek',
            addPremise: 'Új helyiség hozzáadása',
            function: 'Funkció',
            identifier: 'Azonosító',
            cremClassification: 'CREM Besorolás',
            premiseNumber: 'Helyiségek száma',
            isAccessible: 'Akadálymentesített?',
            stairwayId: 'Lépcsőház azonosító',
            stairwayType: 'Lépcsőház típusa',
            isSmokeFree: 'Füstmentes?',
            isEscapeRoute: 'Menekülési útvonal van?',
            numberOfPerson: 'Személyek száma',
            loadCapacity: 'Teherbírás',
            elevatorType: 'Lift típusa',
            elevator: 'Lift',
            isEmergencyElevator: 'Mentő lift?',
            specialData: 'Speciális adatok',
            floorCovering: 'Burkolat',
            isFalseFloor:  'Álpadló?',
            isFalseCeiling: 'Álmennyezet?',
            fireProtectionClass: 'Tűzvédelmi besorolás',
            builtInFireProtection: 'Kiépített tűzvédelem',
            doorType: 'Ajtók típusa',
            minHeight: 'Legkisebb belmagasság',
            maxHeight: 'Legnagyobb belmagasság',
            szepaClass: 'SZEPA Besorolás',
            sharedUse: 'Közös használatú?',
            isDedicated: 'Dedikált szervezeti egység?',
            canBeHeated: 'Fűthető?',
            canBeCooled: 'Hűthető?',
            openableWindow: 'Nyitható ablak van?',
            windows: 'Ablakok',
            windowSize: 'Ablakok mérete',
            windowMaterial: 'Ablakok anyaga',
            windowNumber: 'Ablakok száma',
            windowWidth: 'Ablak szélesség',
            windowHeight: 'Ablak magasság',
            windowDepth: 'Ablak mélység',
            coolingHeatingDevices:  'Hütő-fűtő eszközök',
            isCentral: 'Központi?',
            canBeControlled: 'Vezérelhető?',
            outdoorUnit: 'Kültéri egység van?',
            manufacturer: 'Gyártó',
            energyIntake: 'Energiafelvétel',
            coolingPerformance: 'Hűtő teljesítmény',
            heatingPerformance: 'Fűtő teljesítmény',
            installationDate: 'Üzembe helyezés ideje',
            maintenanceCycle: 'Karbantartási ciklus (hónap)',
            yearOfManufacture: 'Gyártási év',
            guarantee: 'Garancia',
            confirmRemovePremise: 'Biztos benne, hogy törölni akarja ezt a helyiséget?',
            buildingMaxHeight: 'Épület legnagyobb magassága',
            floorsAboveSurface: 'Emeletek száma a felszín felett',
            floorsBelowSurface: 'Emeletek száma a felszín alatt',
            attic: 'Tetőtér van?',
            atticCanBeUsed: 'Hasznosítható?',
            sapBuildingId: 'SAP Épület azonosító',
            foundation: 'Alapozás',
            bearingMasonry: 'Teherhordó falazat',
            slabStructInter: 'Közbenső födémszerkezet',
            slabSubStructInter: 'Közbenső födém altípus',
            slabStructClosing: 'Záró födémszerkezet',
            slabSubStructClosing: 'Zárró födém altípus',
            flatRoof: 'Lapostető?',
            roofType: 'Tető típusa',
            roofing: 'Tetőfedés',
            insulation: 'Hőszigetelés',
            energetics: 'Energetika',
            operation: 'Üzemeltetés',
            cleaning: 'Takarítás',
            supplies: 'Teakonyha ellátmány',
            ownerOfAssets: 'Eszközgazda',
            assetManager: 'Vagyongazda',
            addresses: 'Címek',
            obligations: 'Kötelezettségek',
            utilizations:  'Hasznosítások',
            addNewAddress: 'Új cím',
            addressType: 'Cím típusa',
            buildingDeleteConfirm:  'Biztos, hogy törölni szeretné ezt az épületet?',
            utilizationDeleteConfirm: 'Biztos, hogy törölni szeretné ezt a hasznosítást?',
            addNewUtilization: 'Új hasznosítás',
            otherRight: 'Egyéb jog megnevezése',
            permission: 'Jogosultság',
            obliged: 'Kötelezett',
            deleteLegalSituationConfirm: 'Biztos, hogy törölni szeretné ezt a jogi helyzetet?',
            deleteObligationConfirm: 'Biztos, hogy törölni szeretné ezt a kötelezettséget?',
            deleteUtilizationConfirm: 'Biztos, hogy törölni szeretné ezt a hasznosítást?',
            editUtilization: 'Hasznosítás szerkesztése',
            meterType: 'Mérőóra fajta',
            publicUtilityType: 'Közmű típus',
            placement: 'Elhelyezés',
            readings: 'Leolvasások',
            readingFrequency: 'Leolvasások gyakorisága',
            commType: 'Kommunikáció típusa',
            expirationDate: 'Lejárati idő',
            meters: 'Mérőórák',
            addNewMeter: 'Új mérőóra',
            editMeter: 'Mérőóra szerkesztése',
            editPremise: 'Helyiség szerkesztése',
            cancel: 'Mégse',
            editDocument: 'Dokumentum szerkesztése',
            dateOfOrigin: 'Kelt',
            startDate: 'Kezdő dátum',
            endDate: 'Záró dátum'
        }
    },
    es: {
        message: {
            resolution: 'Resolución',
            minutes: 'minutos',
            day: 'día',
            hour: 'hora',
            floors: 'Pisos',
            infos: 'Informaciones',
            currentPresenceGist: 'Presencia actual',
            numberOfWorkplaces: 'Número de lugares de trabajo',
            sqm: 'm²',
            externalIdentifier: 'Identificador Externo',
            regenerateApiKeyConfirmation: '¿Está seguro de que desea regenerar la clave API?',
            regenerateApiKeyConfirmationCancel: 'Cancelar',
            regenerateApiKeyConfirmationOk: 'Sí',
            generateApiKeyButtonTitle: 'Nueva clave API',
            copy: 'Copiar',
            missingApiKey: 'La clave API no se ha generado aún',
            copiedToClipboard: 'Copiado al portapapeles',
            generateApiKeyTitle: 'Clave API',
            generateApiKeyExample: 'Ejemplo',
            noAssignedDevice: 'No hay dispositivo asignado',
            backToTheMap: 'Volver al mapa',
            error: 'Error',
            invalidMapView: 'Los datos del mapa cargado son incorrectos',
            loading: 'Cargando...',
            loadedAreaHasNoCoordinates: 'El área cargada no tiene coordenadas geográficas.',
            device: 'Dispositivo',
            noSubArea: 'No hay subáreas adjuntas a esta área.',
            lidarView: 'Vista LIDAR',
            select: 'Seleccionar',
            close: 'Cerrar',
            rain: 'Lluvia: ',
            displayName: 'Nombre para mostrar',
            deviceType: 'Tipo de dispositivo',
            choose: 'Elegir',
            adapterType: 'Tipo de adaptador',
            adapterConfig: 'Configuración del adaptador',
            save: 'Guardar',
            test: 'Probar',
            confirmRemove: '¿Está seguro de que desea eliminar este dispositivo?',
            confirmRemoveScenario: '¿Está seguro de que desea eliminar este escenario?',
            confirmRemovePartner: '¿Estás seguro de que deseas eliminar a este socio?',
            noDevice: 'No hay dispositivo agregado actualmente',
            listOfDevices: 'Lista de dispositivos',
            addDevice: 'Agregar dispositivo',
            editDevice: 'Editar dispositivo',
            addNew: 'Agregar nuevo dispositivo',
            noSDChildrenFound: 'No hay subentidades en este diseño de sitio',
            editZone: 'Editar zona',
            designation: 'Designación',
            zoneName: 'Nombre de la zona',
            numberOfPossibleWorkplaces: 'Número de posibles lugares de trabajo',
            floorSpace: 'Espacio del piso',
            zoneType: 'Tipo de zona',
            zoneGroups: 'Grupos de zonas',
            isActive: '¿Activo?',
            successfulSave: 'Guardado exitosamente...',
            notSuccessfulSave: 'Guardado no exitoso...',
            searchingDevice: 'Buscando dispositivo...',
            noResults: 'La búsqueda no tiene resultados...',
            atleastOneCharacter: 'Al menos un carácter...',
            profile: 'Perfil',
            logoutInProgress: 'Cierre de sesión en progreso...',
            name: 'Nombre',
            position: 'Posición',
            breakdown: 'Desglose',
            temperatureData: 'DATOS DE TEMPERATURA',
            populationData: 'DATOS DE POBLACIÓN',
            errorAlt: 'Una actualización de la página puede resolver el problema',
            permissionDenied: 'Permiso denegado',
            goBack: 'Ir a la página anterior',
            sessionExpired: 'La sesión ha expirado, ¡por favor intente iniciar sesión nuevamente!',
            loginAgain: 'Iniciaré sesión nuevamente',
            unauthorizedTenant: '¡El uso de esta aplicación no está permitido para su empresa!',
            saveError: 'Se produjeron errores durante el guardado',
            anonymus: 'Anónimo',
            deleteSuccessful: 'Eliminación exitosa',
            deleteNotSuccessful: 'Eliminación no exitosa',
            noViewImage: '¡No hay imagen disponible para esta vista!',
            deviceNamePlaceholder: 'ej. Nombre del dispositivo',
            confirm: 'Confirmar',
            deny: 'Negar',
            zoneNamePlaceholder: 'ej. Pasillo',
            egNumPlaceholder: 'ej. 100',
            search: 'Buscar',
            searchGeneric: 'Búsqueda Genérica',
            searchZone: 'Búsqueda de Zona',
            searchSiteDesign: 'Búsqueda de Sitio',
            admin: 'Admin',
            optionEditorLink: 'Editar',
            optionAdminEditorLink: 'Administración',
            optionEditorPage: 'Editor de valores de opciones',
            optionValue: 'Valores de opciones',
            type: 'Tipo',
            optionsNumber: 'Valores de opciones',
            actions: 'Comportamiento',
            newOptionValue: 'Nuevo',
            zoneMap: 'Mapa de zonas',
            temperatureMap: 'Mapa de temperatura',
            deviceMap: 'Mapa de dispositivos',
            zoneAdmin: 'Administrador de zonas',
            system: 'Sistema',
            scenarios: 'Escenarios',        
            propertyManagementModule: 'Módulo de gestión de propiedades',
            reports: 'Informes', 
            newScenario: 'Nuevo escenario',
            noScenarioAddedCurrently: 'No hay escenarios configurados aún.',
            selectOutput: 'Seleccionar salida',
            selectInputs: 'Seleccionar entradas',
            selectTime: 'Establecer hora de inicio',
            editScenario: 'Editar escenario',
            data: 'Datos',
            responders: 'Escenarios',
            syncSites: 'Sincronizar',
            outgoingIPAddress: 'Dirección IP de salida',
            chooseLang: 'Elegir idioma: ',
            hungarian: 'Húngaro',
            english: 'Inglés',
            addNewPartner: 'Añadir Nuevo Socio',
            mainType: 'Tipo principal',
            subType: 'Subtipo',
            taxId: 'Identificación del impuesto',
            taxNumber: 'Número de identificación fiscal',
            accountNumber: 'Número de cuenta',
            address: 'Dirección',
            country: 'País',
            postal: 'Postal',
            county: 'Condado',
            city: 'Ciudad',
            publicAreaName: 'Nombre del área pública',
            publicAreaType: 'Tipo de área pública',
            houseNumber: 'Número de casa',
            stairway: 'Escalera',
            floor: 'Piso',
            door: 'Puerta',
            documents: 'Documentos',
            fileName: 'Nombre',
            uploadDate: 'Fecha de carga',
            uploader: 'Cargador',
            contacts: 'Contactos',
            contactPhone: 'Número de teléfono',
            rank: 'Rango',
            note: 'Nota',
            partners: 'interlocutores',
            addNewPartnerType: 'Añadir nuevo tipo de socio',
            privateIndividual: 'Persona privada',
            company: 'Empresa',
            district: 'Distrito',
            postalRegex: 'Para mostrar los condados y asentamientos, complete el campo del código postal.',
            baseDatas: 'Datos',
            buildings: 'Edificios',
            uploadFile: 'Subir archivo',
            upload: 'Subir',
            properties: 'Propiedades',
            addNewProperty: 'Προσθήκη νέας ιδιοκτησίας',
            parcelNumber: 'Número de parcela',
            gpsCoordinates: 'GPS Coordenadas',
            notes: 'Notas de entrada',
            floorArea: 'Superficie del piso',
            mtArea: 'MT metro cuadrado relevante',
            kng: 'KNG Nombre',
            floc: 'FLOC ID',
            sap: 'SAP código',
            propertySheetAppellation: 'Nombre según la Hoja de Propiedad',
            protection: 'Proteccion',
            protectionNote: 'Descripción de la protección',
            numberDedicated: 'Personal dedicado',
            numberCanBeSeated: 'Numero de asientos',
            numberSeated: 'Número de sentados',
            classification: 'Clasificación',
            status: 'Estado',
            solarPanel: '¿Panel solar?',
            fuelCellGenerator: '¿Generador de pila de combustible?',
            dieselGenerator: '¿Generador de diesel?',
            energyTradingPartner: '¿Socio comercial de energía?',
            noBuildingsFound: 'No hay edificios para la propiedad.',
            yes: 'Sí',
            no: 'No',
            addNewBuilding: 'Añadir nuevo edificio',
            editPartner: 'Editar Socio',
            downloadEkrReport: 'Descargar informe EKR',
            areas: 'Areas',
            addNewSiteDesign: 'Añadir nuevo',
            editSiteDesign: 'Editar',
            siteDesignPlaceholder: 'ej. Nombre del Site Design',
            globalSiteDesignNotFound: 'Site design no encontrado, actualice este adaptador.',
            delete: "Eliminar",
            latitude: 'Latitud',
            longitude: 'Longitud',
            mapImage: 'Imagen del mapa',
            building: 'Edificio',
            mapView: 'Vista del mapa',
            property: 'Propiedad',
            'rvg-automatic-list-refresh-30-sec': 'Actualización automática de la lista cada 30 segundos',
            'rvg-automatic-list-refresh-1-min': 'Actualización automática de la lista cada minuto',
            'rvg-automatic-list-refresh-5-min': 'Actualización automática de la lista cada 5 minutos',
            mainFunction: 'Función principal',
            numberOfStairways: 'Número de escaleras',
            numberOfElevators: 'Número de ascensores',
            paymentObligations: 'Obligaciones de pago',
            noAppliedFilters: 'No hay filtros aplicados...',
            updateProperty: 'Editar propiedad',
            legalSituations: 'Situaciones legales',
            mtResponsibles: 'MT Responsables',
            structureTypes: 'Tipos de estructura',
            updateContact: 'Editar contacto',
            addLegalSituation: 'Nueva situación jurídica',
            legalSituation: 'Situación jurídica',
            legalRelationship: 'Relación jurídica',
            ownership: 'Posesión (%)',
            legalSituationStart: 'Situación legal de inicio',
            legalSituationEnd: 'Fin de la situación legal',
            updateLegalSituation: 'Editar situación legal',
            deleteLegalSituation: 'Eliminar situación legal',
            confirmRemoveLS: '¿Está seguro de que desea eliminar esta situación legal?'
        }
    },
    el: {
        message: {
            resolution: 'Ανάλυση',
            minutes: 'λεπτά',
            day: 'ημέρα',
            hour: 'ώρα',
            floors: 'Όροφοι',
            infos: 'Πληροφορίες',
            currentPresenceGist: 'Παρούσα κατάσταση',
            numberOfWorkplaces: 'Αριθμός θέσεων εργασίας',
            sqm: 'τ.μ.',
            externalIdentifier: 'Εξωτερικός Αναγνωριστικός Κωδικός',
            regenerateApiKeyConfirmation: 'Είστε σίγουροι ότι θέλετε να αναγεννήσετε το κλειδί API;',
            regenerateApiKeyConfirmationCancel: 'Ακύρωση',
            regenerateApiKeyConfirmationOk: 'Ναι',
            generateApiKeyButtonTitle: 'Νέο κλειδί API',
            copy: 'Αντιγραφή',
            missingApiKey: 'Το κλειδί API δεν έχει δημιουργηθεί ακόμη',
            copiedToClipboard: 'Αντιγράφηκε στο πρόχειρο',
            generateApiKeyTitle: 'Κλειδί API',
            generateApiKeyExample: 'Παράδειγμα',
            noAssignedDevice: 'Δεν έχει ανατεθεί συσκευή',
            backToTheMap: 'Επιστροφή στον χάρτη',
            error: 'Σφάλμα',
            invalidMapView: 'Τα δεδομένα του φορτωμένου χάρτη είναι εσφαλμένα',
            loading: 'Φόρτωση...',
            loadedAreaHasNoCoordinates: 'Η φορτωμένη περιοχή δεν έχει γεωγραφικές συντεταγμένες.',
            device: 'Συσκευή',
            noSubArea: 'Δεν υπάρχουν υποπεριοχές συνδεδεμένες με αυτήν την περιοχή.',
            lidarView: 'Προβολή LIDAR',
            select: 'Επιλογή',
            close: 'Κλείσιμο',
            rain: 'Βροχή: ',
            displayName: 'Ονομασία',
            deviceType: 'Τύπος Συσκευής',
            choose: 'Επιλέξτε',
            adapterType: 'Τύπος προσαρμογέα',
            adapterConfig: 'Διαμόρφωση προσαρμογέα',
            save: 'Αποθήκευση',
            test: 'Δοκιμή',
            confirmRemove: 'Είστε βέβαιοι ότι θέλετε να αφαιρέσετε αυτή τη συσκευή;',
            confirmRemoveScenario: 'Είστε βέβαιοι ότι θέλετε να αφαιρέσετε αυτό το σενάριο;',
            confirmRemovePartner: 'Είστε βέβαιοι ότι θέλετε να διαγράψετε αυτόν τον συνεργάτη;',
            noDevice: 'Δεν έχει προστεθεί συσκευή αυτή τη στιγμή',
            listOfDevices: 'Λίστα Συσκευών',
            addDevice: 'Προσθήκη Συσκευής',
            editDevice: 'Επεξεργασία Συσκευής',
            addNew: 'Προσθήκη νέας συσκευής',
            noSDChildrenFound: 'Δεν υπάρχουν υπο-περιοχές σε αυτό το σχέδιο τοποθεσίας',
            editZone: 'Επεξεργασία ζώνης',
            designation: 'Ονομασία',
            zoneName: 'Το όνομα της ζώνης',
            numberOfPossibleWorkplaces: 'Αριθμός πιθανών θέσεων εργασίας',
            floorSpace: 'Χώρος ορόφου',
            zoneType: 'Τύπος ζώνης',
            zoneGroups: 'Ομάδες ζωνών',
            isActive: 'Ενεργό;',
            successfulSave: 'Η αποθήκευση ήταν επιτυχής...',
            notSuccessfulSave: 'Η αποθήκευση δεν ήταν επιτυχής...',
            searchingDevice: 'Αναζήτηση συσκευής...',
            noResults: 'Η αναζήτηση δεν έχει αποτελέσματα...',
            atleastOneCharacter: 'Τουλάχιστον ένας χαρακτήρας...',
            profile: 'Προφίλ',
            logoutInProgress: 'Αποσύνδεση σε εξέλιξη...',
            name: 'Όνομα',
            position: 'Θέση',
            breakdown: 'Ανάλυση',
            temperatureData: 'ΔΕΔΟΜΕΝΑ ΘΕΡΜΟΚΡΑΣΙΑΣ',
            populationData: 'ΔΕΔΟΜΕΝΑ ΠΛΗΘΥΣΜΟΥ',
            errorAlt: 'Η ανανέωση της σελίδας μπορεί να λύσει το πρόβλημα',
            permissionDenied: 'Άρνηση πρόσβασης',
            goBack: 'Επιστροφή στην προηγούμενη σελίδα',
            sessionExpired: 'Η συνεδρία έληξε, παρακαλώ προσπαθήστε να συνδεθείτε ξανά!',
            loginAgain: 'Θα συνδεθώ ξανά',
            unauthorizedTenant: 'Η χρήση αυτής της εφαρμογής δεν επιτρέπεται για την εταιρεία σας!',
            saveError: 'Παρουσιάστηκαν σφάλματα κατά την αποθήκευση',
            anonymus: 'Ανώνυμος',
            deleteSuccessful: 'Η διαγραφή ήταν επιτυχής',
            deleteNotSuccessful: 'Η διαγραφή δεν ήταν επιτυχής',
            noViewImage: 'Δεν υπάρχει διαθέσιμη εικόνα για αυτή την προβολή!',
            deviceNamePlaceholder: 'π.χ. Όνομα Συσκευής',
            confirm: 'Επιβεβαίωση',
            deny: 'Άρνηση',
            zoneNamePlaceholder: 'π.χ. Διάδρομος',
            egNumPlaceholder: 'π.χ. 100',
            search: 'Αναζήτηση',
            searchGeneric: 'γενική αναζήτηση',
            searchZone: 'αναζήτηση ζώνης',
            searchSiteDesign: 'αναζήτηση ιστότοπου',
            admin: 'Διαχειριστής',
            optionEditorLink: 'Επεξεργασία',
            optionAdminEditorLink: 'διαχείριση',
            optionEditorPage: 'Σελίδα επεξεργασίας επιλογών',
            optionValue: 'Timí epilogís',
            type: 'τύπος',
            optionsNumber: 'αριθμός επιλογών',
            actions: 'δράση',
            newOptionValue: 'νέα αξία',
            zoneMap: 'Χάρτης Ζωνών',
            temperatureMap: 'Χάρτης Θερμοκρασίας',
            deviceMap: 'Χάρτης Συσκευών',
            zoneAdmin: 'Διαχειριστής Ζωνών',
            system: 'Σύστημα',
            scenarios: 'Σενάρια',
            propertyManagementModule: 'Ενότητα διαχείρισης ακινήτων',
            reports: 'Αναφορές',
            newScenario: 'Νέο Σενάριο',
            noScenarioAddedCurrently: 'Δεν έχουν διαμορφωθεί ακόμη σενάρια.',
            selectOutput: 'Επιλογή Εξόδου',
            selectInputs: 'Επιλογή Εισόδων',
            selectTime: 'Ρύθμιση ώρας έναρξης',
            editScenario: 'Επεξεργασία Σενάριο',
            data: 'Δεδομένα',
            responders: 'Σενάρια',
            syncSites: 'Συγχρονισμός',
            outgoingIPAddress: 'Εξερχόμενη διεύθυνση IP',
            chooseLang: 'Επιλογή Γλώσσας: ',
            hungarian: 'Ουγγρικά',
            english: 'Αγγλικά',
            addNewPartner: 'Προσθέστε νέο συνεργάτη',
            mainType: 'Κύριος τύπος',
            subType: 'Δευτερεύων τύπος',
            taxId: 'τον αριθμό φορολογικού μητρώου',
            taxNumber: 'Αριθμός φορολογικού μητρώου',
            accountNumber: 'Αριθμός λογαριασμού',
            address: 'Διεύθυνση',
            country: 'Χώρα',
            postal: 'Ταχυδρομικός',
            county: 'Κομητεία',
            city: 'Πόλη',
            publicAreaName: 'Όνομα δημόσιου χώρου',
            publicAreaType: 'Τύπος κοινόχρηστου χώρου',
            houseNumber: 'Αριθμός κατοικίας',
            stairway: 'Σκάλα',
            floor: 'Πάτωμα',
            door: 'Πόρτα',
            documents: 'Έγγραφα',
            fileName: 'Όνομα_αρχείου',
            uploadDate: 'Ημερομηνία μεταφόρτωσης',
            uploader: 'Μεταφορτωτής',
            contacts: 'επαφές',
            contactPhone: 'Αριθμός τηλεφώνου',
            rank: 'Βαθμός',
            note: 'Σημείωση',
            partners: 'Συνεργάτες',
            addNewPartnerType: 'Προσθέστε νέο τύπο συνεργάτη',
            privateIndividual: 'άτομο',
            company: 'εταιρεία',
            district: 'Περιοχή',
            postalRegex: 'Για να εμφανίσετε τους νομούς και τους οικισμούς, συμπληρώστε το πεδίο ταχυδρομικός κώδικας!',
            baseDatas: 'Δεδομένα',
            buildings: 'κτίρια',
            uploadFile: 'Ανέβασμα αρχείου',
            upload: 'Μεταφόρτωση',
            properties: 'Ιδιότητες',
            addNewProperty: 'Agregar nueva propiedad',
            parcelNumber: 'Αριθμός δέματος',
            gpsCoordinates: 'Συντεταγμένες GPS',
            notes: 'Σημειώσεις εισόδου',
            floorArea: 'Περιοχή ορόφου',
            mtArea: 'ΜΤ σχετικό τ.μ',
            kng: 'Όνομα KNG',
            floc: 'Αναγνωριστικό FLOC',
            sap: 'Κωδικός SAP',
            propertySheetAppellation: 'Όνομα σύμφωνα με το Φύλλο Ιδιοκτησίας',
            protection: 'ΠΡΟΣΤΑΣΙΑ',
            protectionNote: 'Προστατευτικό σημείωμα',
            numberDedicated: 'Αφοσιωμένο προσωπικό',
            numberCanBeSeated: 'αριθμός θέσεων',
            numberSeated: 'Αριθμός καθισμένων',
            classification: 'Ταξινόμηση',
            status: 'Κατάσταση',
            solarPanel: 'Ηλιακά πάνελ;',
            fuelCellGenerator: 'Γεννήτρια κυψελών καυσίμου;',
            dieselGenerator: 'Γεννήτρια ντίζελ;',
            energyTradingPartner: 'Συνεργάτης Εμπορίου Ενέργειας;',
            noBuildingsFound: 'Δεν υπάρχουν κτίρια για το ακίνητο',
            yes: 'Ναί',
            no: 'όχι',
            addNewBuilding: 'Προσθήκη νέου κτιρίου',
            editPartner: 'Επεξεργασία συνεργάτη',
            downloadEkrReport: 'Λήψη αναφοράς EKR',
            areas: 'Περιοχές',
            addNewSiteDesign: 'Προσθήκη νέου',
            editSiteDesign: 'Επεξεργασία',
            siteDesignPlaceholder: 'π.χ. Όνομα Site Design',
            globalSiteDesignNotFound: 'Το site design δεν βρέθηκε, ενημερώστε αυτόν τον προσαρμογέα.',
            delete: "Διαγραφή",
            latitude: 'Γεωγραφικό πλάτος',
            longitude: 'Γεωγραφικό μήκος',
            mapImage: 'Εικόνα χάρτη',
            building: 'Κτίριο',
            mapView: 'προβολή χάρτη',
            property: 'Ιδιοκτησία',
            'rvg-automatic-list-refresh-30-sec': 'Αυτόματη ανανέωση λίστας κάθε 30 δευτερόλεπτα',
            'rvg-automatic-list-refresh-1-min': 'Αυτόματη ανανέωση λίστας κάθε λεπτό',
            'rvg-automatic-list-refresh-5-min': 'Αυτόματη ανανέωση λίστας κάθε 5 λεπτά',
            mainFunction: 'Κύρια λειτουργία',
            numberOfStairways: 'Αριθμός κλιμακοστασίων',
            numberOfElevators: 'Αριθμός Ανελκυστήρων',
            paymentObligations: 'Υποχρεώσεις πληρωμής',
            noAppliedFilters: 'Δεν εφαρμόζονται φίλτρα...',
            updateProperty: 'Επεξεργασία ιδιοκτησίας',
            legalSituations: 'Νομικές καταστάσεις',
            mtResponsibles: 'MT Υπεύθυνοι',
            structureTypes: 'Τύποι δομών',
            updateContact: 'Επεξεργασία επαφής',
            addLegalSituation: 'Νέα νομική κατάσταση',
            legalSituation: 'Νομική κατάσταση',
            legalRelationship: 'Νομική σχέση',
            ownership: 'Ιδιοκτησία (%)',
            legalSituationStart: 'Έναρξη νομικής κατάστασης',
            legalSituationEnd: 'Τέλος νομικής κατάστασης',
            updateLegalSituation: 'Επεξεργασία Νομική κατάσταση',
            deleteLegalSituation: 'Διαγραφή νομικής κατάστασης',
            confirmRemoveLS: 'Είστε βέβαιοι ότι θέλετε να διαγράψετε αυτήν τη νομική κατάσταση;'
        }
    },
    de: {
        message: {
            resolution: 'Auflösung',
            minutes: 'Minuten',
            day: 'Tag',
            hour: 'Stunde',
            floors: 'Etagen',
            infos: 'Informationen',
            currentPresenceGist: 'Aktuelle Anwesenheit',
            numberOfWorkplaces: 'Anzahl der Arbeitsplätze',
            sqm: 'qm',
            externalIdentifier: 'Externe Kennung',
            regenerateApiKeyConfirmation: 'Sind Sie sicher, dass Sie den API-Schlüssel regenerieren möchten?',
            regenerateApiKeyConfirmationCancel: 'Abbrechen',
            regenerateApiKeyConfirmationOk: 'Ja',
            generateApiKeyButtonTitle: 'Neuer API-Schlüssel',
            copy: 'Kopieren',
            missingApiKey: 'API-Schlüssel ist noch nicht generiert',
            copiedToClipboard: 'In die Zwischenablage kopiert',
            generateApiKeyTitle: 'API-Schlüssel',
            generateApiKeyExample: 'Beispiel',
            noAssignedDevice: 'Kein zugewiesenes Gerät',
            backToTheMap: 'Zurück zur Karte',
            error: 'Fehler',
            invalidMapView: 'Die Daten der geladenen Karte sind inkorrekt',
            loading: 'Wird geladen...',
            loadedAreaHasNoCoordinates: 'Das geladene Gebiet hat keine geografischen Koordinaten.',
            device: 'Gerät',
            noSubArea: 'Es sind keine Unterbereiche an diesem Bereich angehängt.',
            lidarView: 'LIDAR-Ansicht',
            select: 'Auswählen',
            close: 'Schließen',
            rain: 'Regen: ',
            displayName: 'Anzeigename',
            deviceType: 'Gerätetyp',
            choose: 'Wählen',
            adapterType: 'Adaptertyp',
            adapterConfig: 'Adapterkonfiguration',
            save: 'Speichern',
            test: 'Testen',
            confirmRemove: 'Sind Sie sicher, dass Sie dieses Gerät entfernen möchten?',
            confirmRemoveScenario: 'Sind Sie sicher, dass Sie dieses Szenario entfernen möchten?',
            confirmRemovePartner: 'Möchten Sie diesen Partner wirklich löschen?',
            noDevice: 'Derzeit ist kein Gerät hinzugefügt',
            listOfDevices: 'Liste der Geräte',
            addDevice: 'Gerät hinzufügen',
            editDevice: 'Gerät bearbeiten',
            addNew: 'Neues Gerät hinzufügen',
            noSDChildrenFound: 'Es gibt keine Unterentitäten in diesem Standortdesign',
            editZone: 'Zone bearbeiten',
            designation: 'Bezeichnung',
            zoneName: 'Zonenname',
            numberOfPossibleWorkplaces: 'Anzahl der möglichen Arbeitsplätze',
            floorSpace: 'Grundfläche',
            zoneType: 'Zonentyp',
            zoneGroups: 'Zonengruppen',
            isActive: 'Aktiv?',
            successfulSave: 'Erfolgreich gespeichert...',
            notSuccessfulSave: 'Speichern nicht erfolgreich...',
            searchingDevice: 'Gerät wird gesucht...',
            noResults: 'Die Suche hat keine Ergebnisse...',
            atleastOneCharacter: 'Mindestens ein Zeichen...',
            profile: 'Profil',
            logoutInProgress: 'Abmeldung läuft...',
            name: 'Name',
            position: 'Position',
            breakdown: 'Aufschlüsselung',
            temperatureData: 'TEMPERATURDATEN',
            populationData: 'BEVÖLKERUNGSDATEN',
            errorAlt: 'Ein Neuladen der Seite könnte das Problem lösen',
            permissionDenied: 'Zugriff verweigert',
            goBack: 'Zur vorherigen Seite gehen',
            sessionExpired: 'Die Sitzung ist abgelaufen, bitte versuchen Sie erneut, sich anzumelden!',
            loginAgain: 'Ich werde mich erneut anmelden',
            unauthorizedTenant: 'Die Nutzung dieser Anwendung ist für Ihr Unternehmen nicht gestattet!',
            saveError: 'Fehler beim Speichern aufgetreten',
            anonymus: 'Anonym',
            deleteSuccessful: 'Löschen erfolgreich',
            deleteNotSuccessful: 'Löschen war nicht erfolgreich',
            noViewImage: 'Es ist kein Bild für diese Ansicht verfügbar!',
            deviceNamePlaceholder: 'z.B. Gerätename',
            confirm: 'Bestätigen',
            deny: 'Ablehnen',
            zoneNamePlaceholder: 'z.B. Korridor',
            egNumPlaceholder: 'z.B. 100',
            search: 'Suchen',
            searchGeneric: 'Generische Suche',
            searchZone: 'Zonensuche',
            searchSiteDesign: 'Seitensuche',
            admin: 'Admin',
            optionEditorLink: 'Bearbeiten',
            optionAdminEditorLink: 'Verwaltung',
            optionEditorPage: 'Optionseditorseite',
            optionValue: 'Optionswert',
            type: 'Typ',
            optionsNumber: 'Anzahl der Optionen',
            actions: 'Aktionen',
            newOptionValue: 'Neuer Wert',
            zoneMap: 'Zonenkarten',
            temperatureMap: 'Temperaturkarte',
            deviceMap: 'Gerätekarte',
            zoneAdmin: 'Zonen-Admin',
            system: 'System',
            scenarios: 'Szenarien',
            propertyManagementModule: 'Modul Immobilienverwaltung',
            reports: 'Berichte',
            newScenario: 'Neues Szenario',
            noScenarioAddedCurrently: 'Derzeit sind keine Szenarien konfiguriert.',
            selectOutput: 'Ausgabe auswählen',
            selectInputs: 'Eingaben auswählen',
            selectTime: 'Startzeit festlegen',
            editScenario: 'Szenario bearbeiten',
            data: 'Daten',
            responders: 'Szenarien',
            syncSites: 'Synchronisieren',
            outgoingIPAddress: 'Ausgehende IP-Adresse',
            chooseLang: 'Sprache wählen: ',
            hungarian: 'Ungarisch',
            english: 'Englisch',
            addNewPartner: 'Neuen Partner hinzufügen',
            mainType: 'Haupttyp',
            subType: 'Subtyp',
            taxId: 'Steuer ID',
            taxNumber: 'Steuernummer',
            accountNumber: 'ΑKontonummer',
            address: 'Adresse',
            country: 'Land',
            postal: 'Postleitzahl',
            county: 'Bezirk',
            city: 'Stadt',
            publicAreaName: 'Name des öffentlichen Bereichs',
            publicAreaType: 'Art des öffentlichen Bereichs',
            houseNumber: 'Hausnummer',
            stairway: 'Treppe',
            floor: 'Boden',
            door: 'Tür',
            documents: 'Dokumente',
            fileName: 'Dateiname',
            uploadDate: 'Datum des Hochladens',
            uploader: 'Hochladen',
            contacts: 'Kontakte',
            contactPhone: 'Telefonnummer',
            rank: 'Rang',
            note: 'Anmerkung',
            partners: 'Partner',
            addNewPartnerType: 'Neuer Partner hinzufügen',
            privateIndividual: 'Privatperson',
            company: 'Unternehmen',
            district: 'Bezirk',
            postalRegex: 'Zur Anzeige der Landkreise und Ortschaften füllen Sie bitte das PLZ-Feld aus!',
            baseDatas: 'Daten',
            buildings: 'Gebäude',
            uploadFile: 'Datei hochladen',
            upload: 'Hochladen',
            properties: 'Eigenschaften',
            addNewProperty: 'Neue Eigenschaft hinzufügen',
            parcelNumber: 'Paketnummer',
            gpsCoordinates: 'GPS Koordinaten',
            notes: 'Eintragshinweise',
            floorArea: 'Wohnfläche',
            mtArea: 'MT relevanter Quadratmeter',
            kng: 'KNG-Name',
            floc: 'FLOC-Nummer',
            sap: 'SAP-Code',
            propertySheetAppellation: 'Name laut Eigentumsverzeichnis',
            protection: 'Schutz',
            protectionNote: 'Schutzbeschreibung',
            numberDedicated: 'Engagiertes Personal',
            numberCanBeSeated: 'Anzahl Sitzplätze',
            numberSeated: 'Anzahl der Sitzplätze',
            classification: 'Einstufung',
            status: 'Status',
            solarPanel: 'Sonnenkollektor?',
            fuelCellGenerator: 'Brennstoffzellengenerator?',
            dieselGenerator: 'Dieselgenerator?',
            energyTradingPartner: 'Energiehandelspartner?',
            noBuildingsFound: 'Es gibt keine Gebäude für das Grundstück',
            yes: 'Ja',
            no: 'NEIN',
            addNewBuilding: 'Neues Gebäude hinzufügen',
            editPartner: 'Partner bearbeiten',
            downloadEkrReport: 'EKR-Bericht herunterladen',
            areas: 'Bereiche',
            addNewSiteDesign: 'Neu hinzufügen',
            editSiteDesign: 'Bearbeiten',
            siteDesignPlaceholder: 'z.B. Site Design Name',
            globalSiteDesignNotFound: 'Site Design nicht gefunden, aktualisieren Sie diesen Adapter.',
            delete: "Löschen",
            latitude: 'Breitengrad',
            longitude: 'Längengrad',
            mapImage: 'Kartenbild',
            building: 'Gebäude',
            mapView: 'Kartenansicht',
            property: 'Eigentum',
            'rvg-automatic-list-refresh-30-sec': 'Automatische Listenaktualisierung alle 30 Sekunden',
            'rvg-automatic-list-refresh-1-min': 'Automatische Listenaktualisierung jede Minute',
            'rvg-automatic-list-refresh-5-min': 'Automatische Listenaktualisierung alle 5 Minuten',
            mainFunction: 'Hauptfunktion',
            numberOfStairways: 'Anzahl der Treppen',
            numberOfElevators: 'Anzahl der Aufzüge',
            paymentObligations: 'Zahlungsverpflichtungen',
            noAppliedFilters: 'Keine Filter angewendet...',
            updateProperty: 'Eigenschaft bearbeiten',
            legalSituations: 'Rechtliche Situationen',
            mtResponsibles: 'MT Verantwortliche',
            structureTypes: 'Strukturtypen',
            updateContact: 'Kontakt bearbeiten',
            addLegalSituation: 'Neue Rechtslage',
            legalSituation: 'Rechtliche Situation',
            legalRelationship: 'Rechtsverhältnis',
            ownership: 'Eigentum (%)',
            legalSituationStart: 'Rechtslage Beginn',
            legalSituationEnd: 'Rechtslage Ende',
            updateLegalSituation: 'Rechtliche Situation bearbeiten',
            deleteLegalSituation: 'Rechtslage löschen',
            confirmRemoveLS: 'Möchten Sie diese Rechtslage wirklich löschen?',
        }
    }
}
export default messages;
