import store from '@/misc/vuex-store';
import jwt_decode from "jwt-decode";
import UserPermissions from '@/misc/authorization/UserPermissions';
import msalHelper from '@/misc/msalHelper';
import msalInstance from '@/misc/msalInstance';

export default {
  login(accessToken, idToken, roles) {
    store.commit('setAccessToken', accessToken);
    store.commit('setIdToken', idToken);
    store.commit('setRoles', roles);
  },
  refreshToken(accessToken, idToken) {
    store.commit('setAccessToken', accessToken);
    store.commit('setIdToken', idToken);
  },
  setGraphToken(accessToken, idToken) {
    store.commit('setGraphAccessToken', accessToken);
    store.commit('setGraphIdToken', idToken);
  },
  logout() {
    store.commit('logout')
    msalHelper.logout(msalInstance);
  },
  getLanguage(){
    return store.getters.getLanguage;
  },
  getAccessToken() {
    return store.getters.getAccessToken;
  },
  getGraphAccessToken() {
    return store.getters.getGraphAccessToken;
  },
  isLoggedIn() {
    return this.getAccessToken() !== null;
  },
  getUserData() {
    if(!this.isLoggedIn()) {
      return null;
    }
    return jwt_decode(this.getAccessToken());
  },
  getUserId() {
    const userData = this.getUserData();
    return userData ? userData.oid : null;
  },
  getUserTenantId() {
    const userData = this.getUserData();
    return userData ? userData.tid : null;
  },
  getUserName() {
    const userData = this.getUserData();
    return userData ? userData.name : this.$t("message.logoutInProgress");
  },
  getUserRoles() {
    const userData = this.getUserData();
    return userData ? userData.roles : [];
  },
  hasPermission(permission) {
    const roles = this.getUserRoles();
    for(var i in roles) {
      if(UserPermissions[roles[i]] && UserPermissions[roles[i]].includes(permission)) {
        return true;
      }
    }
    return false;
  },
  hasAnyPermission(permissions) {
    let hasAny = false;
    for(let i in permissions) {
      if(this.hasPermission(permissions[i])) {
        hasAny = true;
        break;
      }
    }
    return hasAny;
  },
  hasAllPermission(permissions) {
    let hasAll = true;
    for(let i in permissions) {
      if(!this.hasPermission(permissions[i])) {
        hasAll = false;
        break;
      }
    }
    return hasAll;
  },
};