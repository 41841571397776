<template>
  <div>
    <!--BUILDINGS TABLE-->
    <div class="space-y-6" v-if="props.title == 'buildings'">
      <div class="flex flex-row justify-between">
        <h2 class="text-xl uppercase text-[#c6c6c6] font-bold">
          {{ $t("message.buildings") }}
        </h2>
        <button
          @click="
            store.commit('showSidebar', {
              type: 'addNewBuilding',
              params: {
                onSave: () => {
                  fetchBuildings();
                },
              },
            })
          "
          class="whitespace-nowrap rounded-md text-sm font-medium transition-colors focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:pointer-events-none disabled:opacity-50 btn btn-sm bg-magenta text-white font-light text-xs shadow hover:bg-nrgGray h-9 px-4 py-2 font-bold uppercase"
        >
          {{ $t("message.addNewBuilding") }}
        </button>
      </div>
      <RobberVueGrid
        ref="buildingsGrid"
        :config="buildingsGridConfigComputed"
      />
      <input
        type="checkbox"
        class="modal-toggle"
        v-model="state.showRemoveConfirmation"
      />
      <div class="modal">
        <div class="modal-box">
          <h3
            class="text-lg text-left font-normal hover:text-gray-100 text-gray-100"
          >
            {{ $t("message.buildingDeleteConfirm") }}
          </h3>
          <div class="modal-action">
            <label
              class="btn btn-sm text-white font-light text-xs"
              @click="
                () => {
                  state.showRemoveConfirmation = false;
                }
              "
              >{{ $t("message.deny") }}</label
            >
            <label
              class="btn btn-sm bg-magenta text-white font-light text-xs"
              @click="
                () => {
                  deleteBuilding(state.rvgBuildingId);
                  state.showRemoveConfirmation = false;
                }
              "
              >{{ $t("message.confirm") }}</label
            >
          </div>
        </div>
      </div>
    </div>

    <!--PARTNERS TABLE-->
    <div class="space-y-6" v-if="props.title == 'partners'">
      <div class="flex flex-row justify-between">
        <h2 class="text-xl font-bold uppercase text-[#c6c6c6]">
          {{ $t("message.partners") }}
        </h2>
        <button
          class="whitespace-nowrap rounded-md text-sm font-medium transition-colors focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:pointer-events-none disabled:opacity-50 btn btn-sm bg-magenta text-white font-light text-xs shadow hover:bg-nrgGray h-9 px-4 py-2 font-bold uppercase"
          @click="
            store.commit('showSidebar', {
              type: 'addNewPartner',
              params: {
                onSave: () => {
                  fetchPartners();
                },
              },
            })
          "
        >
          {{ $t("message.addNewPartner") }}
        </button>
      </div>
      <RobberVueGrid ref="partnersGrid" :config="partnersGridConfigComputed" />
      <div v-if="state.showContacts">
        <div class="flex justify-end mb-2 text-gray-300 md:mr-auto">
          <h2 class="text-xl font-bold uppercase text-secondary mb-2">
            {{ state.partnerName }} - {{ $t("message.contacts") }}
          </h2>
        </div>
        <RobberVueGrid
          v-if="!state.forceRefresh"
          ref="partnerContactsGrid"
          :config="partnerContactsGridConfigComputed"
        />
      </div>
    </div>

    <!--DOCUMENTS TABLE-->
    <div v-if="props.title == 'documents'">
      <h2 class="text-xl font-bold uppercase text-[#c6c6c6]">
        {{ $t("message.documents") }}
      </h2>
      <div class="flex flex-row gap-3 w-full my-4">
        <div class="form-control w-full mt-0">
          <label class="label mt-0 pt-0 font-bold" for="">
            <span class="label-text-alt">{{ $t("message.name") }}</span>
          </label>
          <input
            v-model="state.docuName"
            type="text"
            class="input input-sm input-bordered w-full"
          />
        </div>
        <div class="form-control w-full mt-5">
          <label class="flex flex-row gap-2">
            <VueDatePicker
              format="yyyy MMM dd"
              :enable-time-picker="false"
              :time-picker-inline="true"
              :locale="$i18n.locale"
              :month-change-on-scroll="false"
              :action-row="{}"
              :clearable="true"
              select-text="OK"
              :cancel-text="t('message.cancel')"
              :teleport="true"
              model-type="yyyy-MM-dd"
              :placeholder="t('message.dateOfOrigin')"
              v-model="state.docuOriginDate"
              dark="true"
            />
          </label>
        </div>
      </div>
      <div class="flex flex-row gap-3 w-full my-4">
        <div class="form-control w-full mt-0">
          <label class="label mt-0 pt-0" for="mainTypeSelector">
            <span class="label-text-alt">{{ $t("message.mainType") }}</span>
          </label>
          <OptionValueSelector
            id="mainTypeSelector"
            v-model="state.selectedMainType"
            type="DOCUMENT_TYPE"
          />
        </div>
        <div class="form-control w-full mt-0">
          <label class="label mt-0 pt-0" for="subTypeSelector">
            <span class="label-text-alt">{{ $t("message.subType") }}</span>
          </label>
          <OptionValueSelector
            id="subTypeSelector"
            v-model="state.selectedSubType"
            type="DOCUMENT_SUB_TYPE"
          />
        </div>
      </div>
      <input
        class="file:p-2 file:bg-gray-800 file:border-none file:hover:cursor-pointer w-full text-sm text-gray-900 border border-gray-300 rounded-md bg-gray-50 dark:text-gray-400 focus:outline-none dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400"
        aria-describedby="file_input_help"
        accept=".doc,.docx,.xml,.xls,.xlsx,.pdf"
        id="file_input"
        type="file"
        ref="docsInput"
      />
      <div class="w-full flex justify-start mt-2">
        <button
          @click="uploadFile"
          class="mt-2 mb-4 whitespace-nowrap rounded-md text-sm font-medium transition-colors focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:pointer-events-none disabled:opacity-50 btn btn-sm bg-magenta text-white font-light text-xs shadow hover:bg-nrgGray h-9 px-4 py-2 font-bold uppercase"
        >
          {{ $t("message.upload") }}
        </button>
      </div>

      <RobberVueGrid
        ref="documentsGrid"
        :config="documentsGridConfigComputed"
      />
      <input
        type="checkbox"
        class="modal-toggle"
        v-model="state.showRemoveConfirmation"
      />
      <div class="modal">
        <div class="modal-box">
          <h3
            class="text-lg text-left font-normal hover:text-gray-100 text-gray-100"
          >
            {{ $t("message.confirmRemovePartner") }}
          </h3>
          <div class="modal-action">
            <label
              class="btn btn-sm text-white font-light text-xs"
              @click="
                () => {
                  state.showRemoveConfirmation = false;
                }
              "
              >{{ $t("message.deny") }}</label
            >
            <label
              class="btn btn-sm bg-magenta text-white font-light text-xs"
              @click="
                () => {
                  deleteDocument(state.rvgDocumentId);
                  state.showRemoveConfirmation = false;
                }
              "
              >{{ $t("message.confirm") }}</label
            >
          </div>
        </div>
      </div>
    </div>

    <!--PROPERTIES TABLE-->
    <div v-if="props.title == 'properties'">
      <div class="flex justify-end mb-2 text-gray-300 md:mr-auto">
        <button
          @click="
            store.commit('showSidebar', {
              type: 'addNewProperty',
              params: {
                onSave: () => {
                  getProperties();
                },
              },
            })
          "
          class="whitespace-nowrap rounded-md text-sm font-medium transition-colors focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:pointer-events-none disabled:opacity-50 btn btn-sm bg-magenta text-white font-light text-xs shadow hover:bg-nrgGray h-9 px-4 py-2 font-bold uppercase"
        >
          {{ $t("message.addNewProperty") }}
        </button>
      </div>
      <RobberVueGrid
        ref="propertiesGrid"
        :config="propertiesGridConfigComputed"
      />
    </div>

    <!--PROPERTY BASIC DATA TABLE-->
    <div v-if="props.title == 'baseData'">
      <h2 class="text-xl font-bold uppercase text-[#c6c6c6]">
        {{ $t("message.baseDatas") }}
      </h2>
      <div class="w-full flex flex-row mt-4">
        <table class="text-sm text-center w-1/2">
          <tbody>
            <tr class="bg-white border dark:bg-gray-800 dark:border-gray-700">
              <td class="py-3 px-4 text-left">
                <span class="text-secondary font-bold">{{
                  $t("message.propertySheetAppellation")
                }}</span>
              </td>
              <td class="py-3 px-4">
                {{ props.property?.basicDataPropertySheetAppellation }}
              </td>
            </tr>
            <tr class="bg-white border dark:bg-gray-800 dark:border-gray-700">
              <td class="py-3 px-4 text-left">
                <span class="text-secondary font-bold">{{
                  $t("message.floorArea")
                }}</span>
              </td>
              <td class="py-3 px-4">
                {{ props.property?.basicDataFloorArea }}
              </td>
            </tr>
            <tr class="bg-white border dark:bg-gray-800 dark:border-gray-700">
              <td class="py-3 px-4 text-left">
                <span class="text-secondary font-bold">{{
                  $t("message.mtArea")
                }}</span>
              </td>
              <td class="py-3 px-4">
                {{ props.property?.basicDataMtArea }}
              </td>
            </tr>
            <tr class="bg-white border dark:bg-gray-800 dark:border-gray-700">
              <td class="py-3 px-4 text-left">
                <span class="text-secondary font-bold">{{
                  $t("message.kng")
                }}</span>
              </td>
              <td class="py-3 px-4">
                {{ props.property?.basicDataKng }}
              </td>
            </tr>
            <tr class="bg-white border dark:bg-gray-800 dark:border-gray-700">
              <td class="py-3 px-4 text-left">
                <span class="text-secondary font-bold">{{
                  $t("message.floc")
                }}</span>
              </td>
              <td class="py-3 px-4">
                {{ props.property?.basicDataFloc }}
              </td>
            </tr>
            <tr class="bg-white border dark:bg-gray-800 dark:border-gray-700">
              <td class="py-3 px-4 text-left">
                <span class="text-secondary font-bold">{{
                  $t("message.sap")
                }}</span>
              </td>
              <td class="py-3 px-4">
                {{
                  props.property?.basicDataSap
                    ? props.property?.basicDataSap
                    : "null"
                }}
              </td>
            </tr>
            <tr class="bg-white border dark:bg-gray-800 dark:border-gray-700">
              <td class="py-3 px-4 text-left">
                <span class="text-secondary font-bold">{{
                  $t("message.type")
                }}</span>
              </td>
              <td class="py-3 px-4">
                {{
                  props.property?.basicDataTypeValue
                    ? props.property?.basicDataTypeValue
                    : "null"
                }}
              </td>
            </tr>
            <tr class="bg-white border dark:bg-gray-800 dark:border-gray-700">
              <td class="py-3 px-4 text-left">
                <span class="text-secondary font-bold">{{
                  $t("message.protection")
                }}</span>
              </td>
              <td class="py-3 px-4">
                {{ props.property?.basicDataProtection }}
              </td>
            </tr>
            <tr class="bg-white border dark:bg-gray-800 dark:border-gray-700">
              <td class="py-3 px-4 text-left">
                <span class="text-secondary font-bold">{{
                  $t("message.protectionNote")
                }}</span>
              </td>
              <td class="py-3 px-4">
                {{ props.property?.basicDataProtectionNote }}
              </td>
            </tr>
          </tbody>
        </table>

        <table class="text-sm text-center w-1/2">
          <tbody>
            <tr class="bg-white border dark:bg-gray-800 dark:border-gray-700">
              <td class="py-3 px-4 text-left">
                <span class="text-secondary font-bold">{{
                  $t("message.numberDedicated")
                }}</span>
              </td>
              <td class="py-3 px-4">
                {{ props.property?.basicDataNumberDedicated }}
              </td>
            </tr>
            <tr class="bg-white border dark:bg-gray-800 dark:border-gray-700">
              <td class="py-3 px-4 text-left">
                <span class="text-secondary font-bold">{{
                  $t("message.numberCanBeSeated")
                }}</span>
              </td>
              <td class="py-3 px-4">
                {{ props.property?.basicDataNumberCanBeSeated }}
              </td>
            </tr>
            <tr class="bg-white border dark:bg-gray-800 dark:border-gray-700">
              <td class="py-3 px-4 text-left">
                <span class="text-secondary font-bold">{{
                  $t("message.numberSeated")
                }}</span>
              </td>
              <td class="py-3 px-4">
                {{ props.property?.basicDataNumberSeated }}
              </td>
            </tr>
            <tr class="bg-white border dark:bg-gray-800 dark:border-gray-700">
              <td class="py-3 px-4 text-left">
                <span class="text-secondary font-bold">{{
                  $t("message.classification")
                }}</span>
              </td>
              <td class="py-3 px-4">
                {{
                  props.property?.basicDataClassificationValue
                    ? props.property?.basicDataClassificationValue
                    : "null"
                }}
              </td>
            </tr>
            <tr class="bg-white border dark:bg-gray-800 dark:border-gray-700">
              <td class="py-3 px-4 text-left">
                <span class="text-secondary font-bold">{{
                  $t("message.status")
                }}</span>
              </td>
              <td class="py-3 px-4">
                {{
                  props.property?.basicDataStatusValue
                    ? props.property?.basicDataStatusValue
                    : "null"
                }}
              </td>
            </tr>
            <tr class="bg-white border dark:bg-gray-800 dark:border-gray-700">
              <td class="py-3 px-4 text-left">
                <span class="text-secondary font-bold">{{
                  $t("message.solarPanel")
                }}</span>
              </td>
              <td class="py-3 px-4">
                {{
                  props.property?.basicDataSolarPanel
                    ? $t("message.yes")
                    : $t("message.no")
                }}
              </td>
            </tr>
            <tr class="bg-white border dark:bg-gray-800 dark:border-gray-700">
              <td class="py-3 px-4 text-left">
                <span class="text-secondary font-bold">{{
                  $t("message.fuelCellGenerator")
                }}</span>
              </td>
              <td class="py-3 px-4">
                {{
                  props.property?.basicDataFuelCellGenerator
                    ? $t("message.yes")
                    : $t("message.no")
                }}
              </td>
            </tr>
            <tr class="bg-white border dark:bg-gray-800 dark:border-gray-700">
              <td class="py-3 px-4 text-left">
                <span class="text-secondary font-bold">{{
                  $t("message.dieselGenerator")
                }}</span>
              </td>
              <td class="py-3 px-4">
                {{
                  props.property?.basicDataDieselGenerator
                    ? $t("message.yes")
                    : $t("message.no")
                }}
              </td>
            </tr>
            <tr class="bg-white border dark:bg-gray-800 dark:border-gray-700">
              <td class="py-3 px-4 text-left">
                <span class="text-secondary font-bold">{{
                  $t("message.energyTradingPartner")
                }}</span>
              </td>
              <td class="py-3 px-4">
                {{
                  props.property?.basicDataEnergyTradingPartner
                    ? $t("message.yes")
                    : $t("message.no")
                }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <!--PREMISES TABLE-->
    <div class="space-y-6" v-if="props.title == 'premises'">
      <div class="flex flex-row justify-between">
        <h2 class="text-xl uppercase text-[#c6c6c6] font-bold">
          {{ $t("message.premises") }}
        </h2>
        <button
          @click="
            store.commit('showSidebar', {
              type: 'addNewPremise',
              params: {
                onSave: () => {
                  fetchPremises();
                },
              },
            })
          "
          class="whitespace-nowrap rounded-md text-sm font-medium transition-colors focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:pointer-events-none disabled:opacity-50 btn btn-sm bg-magenta text-white font-light text-xs shadow hover:bg-nrgGray h-9 px-4 py-2 font-bold uppercase"
        >
          {{ $t("message.addPremise") }}
        </button>
      </div>
      <RobberVueGrid ref="premiseGrid" :config="premiseGridConfigComputed" />
      <input
        type="checkbox"
        class="modal-toggle"
        v-model="state.showRemoveConfirmation"
      />
      <div class="modal">
        <div class="modal-box">
          <h3
            class="text-lg text-left font-normal hover:text-gray-100 text-gray-100"
          >
            {{ $t("message.confirmRemovePremise") }}
          </h3>
          <div class="modal-action">
            <label
              class="btn btn-sm text-white font-light text-xs"
              @click="
                () => {
                  state.showRemoveConfirmation = false;
                }
              "
              >{{ $t("message.deny") }}</label
            >
            <label
              class="btn btn-sm bg-magenta text-white font-light text-xs"
              @click="
                () => {
                  deletePremise(state.rvgPremiseId);
                  state.showRemoveConfirmation = false;
                }
              "
              >{{ $t("message.confirm") }}</label
            >
          </div>
        </div>
      </div>
    </div>

    <!--BUILDING BASIC DATA TABLE-->
    <div v-if="props.title == 'buildingBaseData'">
      <h2 class="text-xl font-bold uppercase text-[#c6c6c6]">
        {{ $t("message.baseDatas") }}
      </h2>
      <div class="w-full flex flex-row mt-4">
        <table class="text-sm text-center w-1/2">
          <tbody>
            <tr class="bg-white border dark:bg-gray-800 dark:border-gray-700">
              <td class="py-3 px-4 text-left">
                <span class="text-secondary font-bold">{{
                  $t("message.floorArea")
                }}</span>
              </td>
              <td class="py-3 px-4">
                {{
                  props.building?.basicData?.floorArea
                    ? props.building?.basicData?.floorArea
                    : ""
                }}
              </td>
            </tr>
            <tr class="bg-white border dark:bg-gray-800 dark:border-gray-700">
              <td class="py-3 px-4 text-left">
                <span class="text-secondary font-bold">{{
                  $t("message.mtArea")
                }}</span>
              </td>
              <td class="py-3 px-4">
                {{
                  props.building?.basicData?.mtArea
                    ? props.building?.basicData?.mtArea
                    : ""
                }}
              </td>
            </tr>
            <tr class="bg-white border dark:bg-gray-800 dark:border-gray-700">
              <td class="py-3 px-4 text-left">
                <span class="text-secondary font-bold">{{
                  $t("message.mainFunction")
                }}</span>
              </td>
              <td class="py-3 px-4">
                {{
                  props.building?.basicData?.mainFunction?.value
                    ? props.building?.basicData?.mainFunction?.value
                    : ""
                }}
              </td>
            </tr>
            <tr class="bg-white border dark:bg-gray-800 dark:border-gray-700">
              <td class="py-3 px-4 text-left">
                <span class="text-secondary font-bold">{{
                  $t("message.premiseNumber")
                }}</span>
              </td>
              <td class="py-3 px-4">
                {{
                  props.building?.basicData?.premiseNumber
                    ? props.building?.basicData?.premiseNumber
                    : ""
                }}
              </td>
            </tr>
            <tr class="bg-white border dark:bg-gray-800 dark:border-gray-700">
              <td class="py-3 px-4 text-left">
                <span class="text-secondary font-bold">{{
                  $t("message.isAccessible")
                }}</span>
              </td>
              <td class="py-3 px-4">
                {{ props.building?.basicData?.accessible ? "Igen" : "Nem" }}
              </td>
            </tr>
            <tr class="bg-white border dark:bg-gray-800 dark:border-gray-700">
              <td class="py-3 px-4 text-left">
                <span class="text-secondary font-bold">{{
                  $t("message.numberDedicated")
                }}</span>
              </td>
              <td class="py-3 px-4">
                {{
                  props.building?.basicData?.numberDedicated
                    ? props.building?.basicData?.numberDedicated
                    : ""
                }}
              </td>
            </tr>
            <tr class="bg-white border dark:bg-gray-800 dark:border-gray-700">
              <td class="py-3 px-4 text-left">
                <span class="text-secondary font-bold">{{
                  $t("message.numberCanBeSeated")
                }}</span>
              </td>
              <td class="py-3 px-4">
                {{
                  props.building?.basicData?.numberCanBeSeated
                    ? props.building?.basicData?.numberCanBeSeated
                    : ""
                }}
              </td>
            </tr>
            <tr class="bg-white border dark:bg-gray-800 dark:border-gray-700">
              <td class="py-3 px-4 text-left">
                <span class="text-secondary font-bold">{{
                  $t("message.numberSeated")
                }}</span>
              </td>
              <td class="py-3 px-4">
                {{
                  props.building?.basicData?.numberSeated
                    ? props.building?.basicData?.numberSeated
                    : ""
                }}
              </td>
            </tr>
            <tr class="bg-white border dark:bg-gray-800 dark:border-gray-700">
              <td class="py-3 px-4 text-left">
                <span class="text-secondary font-bold">{{
                  $t("message.buildingMaxHeight")
                }}</span>
              </td>
              <td class="py-3 px-4">
                {{
                  props.building?.basicData?.maxHeight
                    ? props.building?.basicData?.maxHeight
                    : ""
                }}
              </td>
            </tr>
          </tbody>
        </table>

        <table class="text-sm text-center w-1/2">
          <tbody>
            <tr class="bg-white border dark:bg-gray-800 dark:border-gray-700">
              <td class="py-3 px-4 text-left">
                <span class="text-secondary font-bold">{{
                  $t("message.classification")
                }}</span>
              </td>
              <td class="py-3 px-4">
                {{
                  props.building?.basicData?.status?.value
                    ? props.building?.basicData?.status?.value
                    : ""
                }}
              </td>
            </tr>
            <tr class="bg-white border dark:bg-gray-800 dark:border-gray-700">
              <td class="py-3 px-4 text-left">
                <span class="text-secondary font-bold">{{
                  $t("message.paymentObligations")
                }}</span>
              </td>
              <td class="py-3 px-4">
                {{
                  props.building?.paymentObligation
                    ? props.building?.paymentObligation
                    : ""
                }}
              </td>
            </tr>
            <tr class="bg-white border dark:bg-gray-800 dark:border-gray-700">
              <td class="py-3 px-4 text-left">
                <span class="text-secondary font-bold">{{
                  $t("message.floorsAboveSurface")
                }}</span>
              </td>
              <td class="py-3 px-4">
                {{
                  props.building?.basicData?.floorsAboveSurface
                    ? props.building?.basicData?.floorsAboveSurface
                    : ""
                }}
              </td>
            </tr>
            <tr class="bg-white border dark:bg-gray-800 dark:border-gray-700">
              <td class="py-3 px-4 text-left">
                <span class="text-secondary font-bold">{{
                  $t("message.floorsBelowSurface")
                }}</span>
              </td>
              <td class="py-3 px-4">
                {{
                  props.building?.basicData?.floorsBelowSurface
                    ? props.building?.basicData?.floorsBelowSurface
                    : ""
                }}
              </td>
            </tr>
            <tr class="bg-white border dark:bg-gray-800 dark:border-gray-700">
              <td class="py-3 px-4 text-left">
                <span class="text-secondary font-bold">{{
                  $t("message.attic")
                }}</span>
              </td>
              <td class="py-3 px-4">
                {{
                  props.building?.basicData?.attic == true
                    ? $t("message.yes")
                    : $t("message.no")
                }}
              </td>
            </tr>
            <tr class="bg-white border dark:bg-gray-800 dark:border-gray-700">
              <td class="py-3 px-4 text-left">
                <span class="text-secondary font-bold">{{
                  $t("message.atticCanBeUsed")
                }}</span>
              </td>
              <td class="py-3 px-4">
                {{
                  props.building?.basicData?.atticCanBeUsed == true
                    ? $t("message.yes")
                    : $t("message.no")
                }}
              </td>
            </tr>
            <tr class="bg-white border dark:bg-gray-800 dark:border-gray-700">
              <td class="py-3 px-4 text-left">
                <span class="text-secondary font-bold">{{
                  $t("message.sapBuildingId")
                }}</span>
              </td>
              <td class="py-3 px-4">
                {{
                  props.building?.basicData?.sap?.value
                    ? props.building?.basicData?.sap?.value
                    : ""
                }}
              </td>
            </tr>
            <tr class="bg-white border dark:bg-gray-800 dark:border-gray-700">
              <td class="py-3 px-4 text-left">
                <span class="text-secondary font-bold">TCAFM</span>
              </td>
              <td class="py-3 px-4">
                {{
                  props.building?.basicData?.tcafm
                    ? props.building?.basicData?.tcafm
                    : ""
                }}
              </td>
            </tr>
            <tr class="bg-white border dark:bg-gray-800 dark:border-gray-700">
              <td class="py-3 px-4 text-left">
                <span class="text-secondary font-bold">{{
                  $t("message.parcelNumber")
                }}</span>
              </td>
              <td class="py-3 px-4">
                {{
                  props.building?.basicData?.parcelNumber
                    ? props.building?.basicData?.parcelNumber
                    : ""
                }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <!--MT RESPONSIBLES TABLE-->
    <div v-if="props.title == 'mtResponsibles'">
      <h2 class="text-xl font-bold uppercase text-[#c6c6c6]">
        {{ $t("message.mtResponsibles") }}
      </h2>
      <div class="w-full flex flex-row mt-4">
        <table class="text-sm text-center w-full">
          <tbody>
            <tr class="bg-white border dark:bg-gray-800 dark:border-gray-700">
              <td class="py-3 px-4 text-left">
                <span class="text-secondary font-bold">
                  {{ $t("message.operation") }}
                </span>
              </td>
              <td class="py-3 px-4">
                {{
                  props.building?.basicData?.operation
                    ? props.building?.basicData?.operation
                    : ""
                }}
              </td>
            </tr>
            <tr class="bg-white border dark:bg-gray-800 dark:border-gray-700">
              <td class="py-3 px-4 text-left">
                <span class="text-secondary font-bold">{{
                  $t("message.cleaning")
                }}</span>
              </td>
              <td class="py-3 px-4">
                {{
                  props.building?.basicData?.cleaner
                    ? props.building?.basicData?.cleaner
                    : ""
                }}
              </td>
            </tr>
            <tr class="bg-white border dark:bg-gray-800 dark:border-gray-700">
              <td class="py-3 px-4 text-left">
                <span class="text-secondary font-bold">{{
                  $t("message.supplies")
                }}</span>
              </td>
              <td class="py-3 px-4">
                {{
                  props.building?.basicData?.supplies
                    ? props.building?.basicData?.supplies
                    : ""
                }}
              </td>
            </tr>
            <tr class="bg-white border dark:bg-gray-800 dark:border-gray-700">
              <td class="py-3 px-4 text-left">
                <span class="text-secondary font-bold">TUI</span>
              </td>
              <td class="py-3 px-4">
                {{
                  props.building?.basicData?.tui
                    ? props.building?.basicData?.tui
                    : ""
                }}
              </td>
            </tr>
            <tr class="bg-white border dark:bg-gray-800 dark:border-gray-700">
              <td class="py-3 px-4 text-left">
                <span class="text-secondary font-bold">{{
                  $t("message.ownerOfAssets")
                }}</span>
              </td>
              <td class="py-3 px-4">
                {{
                  props.building?.basicData?.ownerOfAssets
                    ? props.building?.basicData?.ownerOfAssets
                    : ""
                }}
              </td>
            </tr>
            <tr class="bg-white border dark:bg-gray-800 dark:border-gray-700">
              <td class="py-3 px-4 text-left">
                <span class="text-secondary font-bold">{{
                  $t("message.assetManager")
                }}</span>
              </td>
              <td class="py-3 px-4">
                {{
                  props.building?.basicData?.assetManager
                    ? props.building?.basicData?.assetManager
                    : ""
                }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <!--STRUCTURE TYPES TABLE-->
    <div v-if="props.title == 'structureTypes'">
      <h2 class="text-xl font-bold uppercase text-[#c6c6c6]">
        {{ $t("message.structureTypes") }}
      </h2>
      <div class="w-full flex flex-row mt-4">
        <table class="text-sm text-center w-full">
          <tbody>
            <tr class="bg-white border dark:bg-gray-800 dark:border-gray-700">
              <td class="py-3 px-4 text-left">
                <span class="text-secondary font-bold">
                  {{ $t("message.foundation") }}
                </span>
              </td>
              <td class="py-3 px-4">
                {{
                  props.building?.foundation?.value
                    ? props.building?.foundation.value
                    : ""
                }}
              </td>
            </tr>
            <tr class="bg-white border dark:bg-gray-800 dark:border-gray-700">
              <td class="py-3 px-4 text-left">
                <span class="text-secondary font-bold">{{
                  $t("message.bearingMasonry")
                }}</span>
              </td>
              <td class="py-3 px-4">
                {{
                  props.building?.bearingMasonry?.value
                    ? props.building?.bearingMasonry.value
                    : ""
                }}
              </td>
            </tr>
            <tr class="bg-white border dark:bg-gray-800 dark:border-gray-700">
              <td class="py-3 px-4 text-left">
                <span class="text-secondary font-bold">{{
                  $t("message.slabStructInter")
                }}</span>
              </td>
              <td class="py-3 px-4">
                {{
                  props.building?.slabStructureInter?.value
                    ? props.building?.slabStructureInter.value
                    : ""
                }},
                {{
                  props.building?.slabSubStructureInter?.value
                    ? props.building?.slabSubStructureInter.value
                    : ""
                }}
              </td>
            </tr>
            <tr class="bg-white border dark:bg-gray-800 dark:border-gray-700">
              <td class="py-3 px-4 text-left">
                <span class="text-secondary font-bold">{{
                  $t("message.slabStructClosing")
                }}</span>
              </td>
              <td class="py-3 px-4">
                {{
                  props.building?.slabStructureClosing?.value
                    ? props.building?.slabStructureClosing.value
                    : ""
                }},
                {{
                  props.building?.slabSubStructureClosing?.value
                    ? props.building?.slabSubStructureClosing.value
                    : ""
                }}
              </td>
            </tr>
            <tr class="bg-white border dark:bg-gray-800 dark:border-gray-700">
              <td class="py-3 px-4 text-left">
                <span class="text-secondary font-bold">{{
                  $t("message.flatRoof")
                }}</span>
              </td>
              <td class="py-3 px-4">
                {{
                  props.building?.flatRoof?.value
                    ? props.building?.flatRoof.value
                    : ""
                }}
              </td>
            </tr>
            <tr class="bg-white border dark:bg-gray-800 dark:border-gray-700">
              <td class="py-3 px-4 text-left">
                <span class="text-secondary font-bold">{{
                  $t("message.roofType")
                }}</span>
              </td>
              <td class="py-3 px-4">
                {{
                  props.building?.roofing?.value
                    ? props.building?.roofing.value
                    : ""
                }}
              </td>
            </tr>
            <tr class="bg-white border dark:bg-gray-800 dark:border-gray-700">
              <td class="py-3 px-4 text-left">
                <span class="text-secondary font-bold">{{
                  $t("message.insulation")
                }}</span>
              </td>
              <td class="py-3 px-4">
                {{
                  props.building?.insulation?.value
                    ? props.building?.insulation.value
                    : ""
                }}
              </td>
            </tr>
            <tr class="bg-white border dark:bg-gray-800 dark:border-gray-700">
              <td class="py-3 px-4 text-left">
                <span class="text-secondary font-bold">{{
                  $t("message.numberOfStairways")
                }}</span>
              </td>
              <td class="py-3 px-4">
                {{
                  props.building?.numberOfStairways
                    ? props.building?.numberOfStairways
                    : ""
                }}
              </td>
            </tr>
            <tr class="bg-white border dark:bg-gray-800 dark:border-gray-700">
              <td class="py-3 px-4 text-left">
                <span class="text-secondary font-bold">{{
                  $t("message.numberOfElevators")
                }}</span>
              </td>
              <td class="py-3 px-4">
                {{
                  props.building?.numberOfElevators
                    ? props.building?.numberOfElevators
                    : ""
                }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <!--MAIN PAGE DOCUMENTS-->
    <div class="space-y-6" v-if="props.title == 'documentsMainPage'">
      <!-- <div class="flex flex-row justify-between">
        <h2 class="text-xl uppercase text-secondary font-bold">
          Dokumentumok
        </h2>
        <button
          @click="
            store.commit('showSidebar', {
              type: 'addNewMainPageDocument',
              params: {
                onSave: () => {
                  fetchDocuments();
                },
              },
            })
          "
          class="whitespace-nowrap rounded-md text-sm font-medium transition-colors focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:pointer-events-none disabled:opacity-50 bg-secondary text-nrgGray shadow hover:bg-nrgGray hover:text-secondary h-9 px-4 py-2 font-bold uppercase"
        >
          Új dokumentum rögzítése
        </button>
      </div> -->
      <div class="flex flex-row gap-3 w-full my-4">
        <div class="form-control w-full mt-0">
          <label class="label mt-0 pt-0 font-bold" for="">
            <span class="label-text-alt">{{ $t("message.name") }}</span>
          </label>
          <input
            v-model="state.mainPageDocuments.name"
            type="text"
            class="input input-sm input-bordered w-full"
          />
        </div>
        <div class="form-control w-full mt-0">
          <label class="label mt-0 pt-0 font-bold" for="mainTypeSelector">
            <span class="label-text-alt">{{ $t("message.mainType") }}</span>
          </label>
          <OptionValueSelector
            id="mainTypeSelector"
            v-model="state.mainPageDocuments.typeId"
            type="DOCUMENT_TYPE"
          />
        </div>
        <div class="form-control w-full mt-0">
          <label class="label mt-0 pt-0 font-bold" for="subTypeSelector">
            <span class="label-text-alt">{{ $t("message.subType") }}</span>
          </label>
          <OptionValueSelector
            id="subTypeSelector"
            v-model="state.mainPageDocuments.subTypeId"
            type="DOCUMENT_SUB_TYPE"
          />
        </div>
      </div>

      <div class="flex flex-row gap-3 w-full my-4">
        <div class="form-control w-full mt-0">
          <label class="label mt-0 pt-0 font-bold" for="">
            <span class="label-text-alt">{{ $t("message.uploader") }}</span>
          </label>
          <SearchOptions
            v-model="state.mainPageDocuments.uploaderId"
            type="uploader"
          />
        </div>

        <div class="form-control w-full mt-5">
          <label class="flex flex-row gap-2">
            <VueDatePicker
              format="yyyy MMM dd HH:mm"
              :enable-time-picker="true"
              :time-picker-inline="true"
              :locale="$i18n.locale"
              :month-change-on-scroll="false"
              :action-row="{}"
              :clearable="true"
              select-text="OK"
              :cancel-text="t('message.cancel')"
              :teleport="true"
              model-type="yyyy-MM-dd HH:mm"
              :placeholder="t('message.startDate') +' ('+ t('message.upload')+ ')'"
              v-model="state.mainPageDocuments.fromDate"
              dark="true"
            />
          </label>
        </div>

        <div class="form-control w-full mt-5">
          <label class="flex flex-row gap-2">
            <VueDatePicker
              format="yyyy MMM dd HH:mm"
              :enable-time-picker="true"
              :time-picker-inline="true"
              locale="hu"
              :month-change-on-scroll="false"
              :action-row="{}"
              select-text="OK"
              :cancel-text="t('message.cancel')"
              :clearable="true"
              :teleport="true"
              model-type="yyyy-MM-dd HH:mm"
              :placeholder="t('message.endDate') +' ('+ t('message.upload')+ ')'"
              v-model="state.mainPageDocuments.toDate"
              dark="true"
            />
          </label>
        </div>

        <div class="form-control w-full mt-5">
          <label class="flex flex-row gap-2">
            <VueDatePicker
              format="yyyy MMM dd"
              :enable-time-picker="false"
              :time-picker-inline="true"
              locale="hu"
              :month-change-on-scroll="false"
              :action-row="{}"
              select-text="OK"
              :cancel-text="t('message.cancel')"
              :clearable="true"
              :teleport="true"
              model-type="yyyy-MM-dd"
              :placeholder="t('message.dateOfOrigin')"
              v-model="state.mainPageDocuments.dateOfOrigin"
              dark="true"
            />
          </label>
        </div>
      </div>
      <RobberVueGrid
        v-if="!state.forceRefresh"
        ref="documentsMainPageGrid"
        :config="documentsMainPageGridConfigComputed"
      />
      <input
        type="checkbox"
        class="modal-toggle"
        v-model="state.showRemoveConfirmation"
      />
      <div class="modal">
        <div class="modal-box">
          <h3
            class="text-lg text-left font-normal hover:text-gray-100 text-gray-100"
          >
            Biztos benne, hogy törli a dokumentumot?
          </h3>
          <div class="modal-action">
            <label
              class="btn btn-sm text-white font-light text-xs"
              @click="
                () => {
                  state.showRemoveConfirmation = false;
                }
              "
              >{{ $t("message.deny") }}</label
            >
            <label
              class="btn btn-sm bg-magenta text-white font-light text-xs"
              @click="
                () => {
                  deleteDocument(state.rvgDocumentId);
                  state.showRemoveConfirmation = false;
                }
              "
              >{{ $t("message.confirm") }}</label
            >
          </div>
        </div>
      </div>
      <EditDocumentModal :showModal="modalStatus" :id="state.rvgDocumentId" />
    </div>
  </div>
</template>

<script setup>
import VueDatePicker from "@vuepic/vue-datepicker";
import { onMounted, reactive, ref, computed, watch } from "vue";
import toast from "@/misc/toast.js";
import { useI18n } from "vue-i18n";
import store from "@/misc/vuex-store";
import Utils from "../../misc/Utils";
import axios from "@/misc/axios.js";
import router from "@/misc/router";
import { useRoute } from "vue-router";
import OptionValueSelector from "../OptionValueSelector.vue";
import RobberVueGrid from "@/components/RVG/RobberVueGrid.vue";
import buildingsGridConfig from "@/misc/gridConfigs/buildingsGridConfig.js";
import propertiesGridConfig from "@/misc/gridConfigs/propertiesGridConfig.js";
import partnersGridConfig from "@/misc/gridConfigs/partnersGridConfig.js";
import documentsGridConfig from "@/misc/gridConfigs/documentsGridConfig.js";
import documentsMainPageGridConfig from "@/misc/gridConfigs/documentsMainPageGridConfig.js";
import partnerContactsGridConfig from "@/misc/gridConfigs/partnerContactsGridConfig.js";
import moment from "moment";
import SearchOptions from "../SearchOptions.vue";
import premiseGridConfig from "@/misc/gridConfigs/premiseGridConfig.js";
import EditDocumentModal from "./EditDocumentModal.vue";

const props = defineProps(["title", "property", "building"]);

const { t } = useI18n();
const route = useRoute();
const propertyId = route.params.propertyId;
const buildingId = route.params.buildingId;

const docsInput = ref("");
const documentsMainPageGrid = ref(null);
const partnerContactsGrid = ref(null);

const buildingsGridConfigComputed = computed(() => {
  return buildingsGridConfig(
    t,
    setRemoveConfirmation,
    editBuilding,
    toBuilding,
    propertyId
  );
});

const propertiesGridConfigComputed = computed(() => {
  return propertiesGridConfig(t, editProperty, toProperty);
});

const partnersGridConfigComputed = computed(() => {
  return partnersGridConfig(t, fetchPartnerById, addNewContact, fetchContacts);
});

const documentsGridConfigComputed = computed(() => {
  return documentsGridConfig(
    t,
    propCurrentTab.value,
    propertyId,
    props.building?.id,
    setRemoveConfirmation
  );
});

const partnerContactsGridConfigComputed = computed(() => {
  return partnerContactsGridConfig(t, editContact, state.partnerId);
});

const documentsMainPageGridConfigComputed = computed(() => {
  return documentsMainPageGridConfig(
    t,
    state.mainPageDocuments.name,
    state.mainPageDocuments.uploaderId,
    state.mainPageDocuments.typeId,
    state.mainPageDocuments.subTypeId,
    state.mainPageDocuments.fromDate
      ? moment(state.mainPageDocuments.fromDate)
      : null,
    state.mainPageDocuments.toDate
      ? moment(state.mainPageDocuments.toDate)
      : null,
    state.mainPageDocuments.dateOfOrigin
      ? moment(state.mainPageDocuments.dateOfOrigin)
      : null,
    editDocument,
    setRemoveConfirmation,
    downloadDoc
  );
});

const premiseGridConfigComputed = computed(() => {
  return premiseGridConfig(
    t,
    buildingId,
    toPremise,
    setRemoveConfirmation,
    editPremise
  );
});

const editDocument = async (id) => {
  const response = await Utils.fetchDocumentById(id);
  console.log(response);

  store.commit("setIsModalOpen", true);
  store.commit("setDocumentById", response);
  state.rvgDocumentId = id;
};

const modalStatus = computed(() => {
  return store.getters.getIsModalOpen;
});

const fetchContacts = (id, name) => {
  state.partnerName = name;
  state.partnerId = id;
  state.showContacts = true;
};

const setRemoveConfirmation = (id) => {
  if (propCurrentTab.value == "buildings") {
    state.rvgBuildingId = id;
  }

  if (
    propCurrentTab.value == "documents" ||
    propCurrentTab.value == "buildingDocuments" ||
    route.name == "documentsPage"
  ) {
    state.rvgDocumentId = id;
  }

  if (propCurrentTab.value == "premises") {
    state.rvgPremiseId = id;
  }

  state.showRemoveConfirmation = true;
};

const addNewContact = async (id) => {
  const response = await Utils.fetchPartnerById(id);
  state.partner = response;
  store.commit("setPartnerById", response);
  store.commit("showSidebar", {
    type: "addNewPartnerContact",
  });
};

const editContact = async (id) => {
  const response = await Utils.fetchContactById(id);

  store.commit("setContactById", response);
  store.commit("showSidebar", {
    type: "editPartnerContact",
    params: {
      onSave: () => {
        fetchContacts(response.partnerId);
      },
    },
  });
};

const state = reactive({
  showRemoveConfirmation: false,
  data: [],
  partner: {},
  properties: [],
  basicData: null,
  selectedMainType: "",
  selectedSubType: "",
  buildings: [],
  premises: [],
  documents: [],
  docuMainType: "",
  docuSubType: "",
  docuName: "",
  docuOriginDate: "",
  uploadAt: null,
  rvgBuildingId: null,
  rvgDocumentId: null,
  rvgPremiseId: null,
  showContacts: false,
  partnerName: "",
  partnerId: null,
  mainPageDocuments: {
    name: "",
    uploaderId: null,
    typeId: null,
    subTypeId: null,
    fromDate: "",
    toDate: "",
    dateOfOrigin: "",
  },
  forceRefresh: false,
});

const refreshGrid = () => {
  state.forceRefresh = true;
  setTimeout(() => {
    state.forceRefresh = false;
  }, 100);
};

watch(
  () => state.mainPageDocuments.typeId,
  () => {
    refreshGrid();
  }
);

watch(
  () => state.mainPageDocuments.subTypeId,
  () => {
    refreshGrid();
  }
);

watch(
  () => state.mainPageDocuments.name,
  () => {
    refreshGrid();
  }
);

watch(
  () => state.mainPageDocuments.uploaderId,
  () => {
    refreshGrid();
  }
);

watch(
  () => state.mainPageDocuments.fromDate,
  () => {
    refreshGrid();
  }
);

watch(
  () => state.mainPageDocuments.toDate,
  () => {
    refreshGrid();
  }
);

watch(
  () => state.mainPageDocuments.dateOfOrigin,
  () => {
    refreshGrid();
  }
);

watch(
  () => state.partnerId,
  () => {
    refreshGrid();
  }
);

watch(
  () => state.properties,
  () => {
    refreshGrid();
  }
);

const toBuilding = (id) => {
  router.push({
    name: "buildingPage",
    params: { buildingId: id },
  });
};

const toProperty = (id) => {
  router.push({
    name: "propertyPage",
    params: { propertyId: id },
  });
};

const toPremise = (id) => {
  router.push({
    name: "premisePage",
    params: { premiseId: id },
  });
};

async function fetchPremises() {
  const uri = process.env.VUE_APP_FRONTENDV2_API_URL + `/rem/premise`;
  try {
    const response = await axios.get(uri, {
      onTokenRefreshed: () => {
        this.fetchPremises();
      },
    });
    state.premises = response.data.content;
    console.log(state.premises);
  } catch (err) {
    console.log("ERR", err);
  }
}

async function fetchBuildings() {
  const uri = process.env.VUE_APP_FRONTENDV2_API_URL + `/rem/building`;
  try {
    const response = await axios.get(uri, {
      onTokenRefreshed: () => {
        this.fetchBuildings();
      },
    });
    state.buildings = response.data.content;
    console.log(state.buildings);
  } catch (error) {
    console.log(error);
  }
}

const downloadDoc = (id, name) => {
  axios
    .get(
      process.env.VUE_APP_FRONTENDV2_API_URL + `/rem/document/${id}/download`,
      {
        responseType: "blob",
      }
    )
    .then((response) => {
      var url = window.URL.createObjectURL(
        new Blob([response.data], {
          type: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
        })
      );
      var link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", name);
      document.body.appendChild(link);
      link.click();
    });
};

async function getProperties() {
  const uri = process.env.VUE_APP_FRONTENDV2_API_URL + `/rem/property`;
  try {
    const response = await axios.get(uri, {
      onTokenRefreshed: () => {
        this.getProperties();
      },
    });
    state.properties = response.data.content;
    console.log(state.properties);
  } catch (err) {
    console.log("ERR", err);
  }
}

async function fetchPartnerById(id) {
  const response = await Utils.fetchPartnerById(id);
  state.partner = response;
  store.commit("setPartnerById", response);
  store.commit("showSidebar", {
    type: "editPartner",
    params: {
      onSave: () => {
        fetchPartners();
      },
    },
  });
}

async function fetchPartners() {
  try {
    const response = await Utils.fetchPartners();
    state.data = response;
    console.log(state.data);
  } catch (error) {
    console.log(error);
    toast.error(t("message.error"));
  }
}

async function uploadFile() {
  let uri = "";

  if (propCurrentTab.value == "documents") {
    uri =
      process.env.VUE_APP_FRONTENDV2_API_URL +
      `/rem/property/${props.property?.id}/document`;
  } else if (propCurrentTab.value == "buildingDocuments") {
    uri =
      process.env.VUE_APP_FRONTENDV2_API_URL +
      `/rem/building/${props.building?.id}/document`;
  }

  const formData = new FormData();
  formData.append("mainType", state.selectedMainType);
  formData.append("subType", state.selectedSubType);
  formData.append("document", docsInput.value.files[0]);
  formData.append("name", state.docuName);
  formData.append("dateOfOrigin", state.docuOriginDate);

  try {
    await axios.post(uri, formData);
    emptyDocUploader();
    toast.success(t("message.successfulSave"));
  } catch (error) {
    console.log(error);
    toast.error(t("message.saveError"));
  }
}

const emptyDocUploader = () => {
  (state.docuName = ""),
    (state.docuOriginDate = ""),
    (state.selectedMainType = ""),
    (state.selectedSubType = ""),
    (document.getElementById("file_input").value = "");
};

async function editBuilding(id) {
  const response = await Utils.fetchBuildingById(id);

  store.commit("setBuildingById", response);
  store.commit("showSidebar", {
    type: "editBuilding",
    params: {
      onSave: () => {
        fetchBuildings();
      },
    },
  });
}

const editProperty = async (id) => {
  const response = await Utils.fetchPropertyById(id);

  store.commit("setPropertyById", response);
  store.commit("showSidebar", {
    type: "editProperty",
  });
};

const editPremise = async (id) => {
  const response = await Utils.fetchPremiseById(id);

  store.commit("setPremiseById", response);
  store.commit("showSidebar", {
    type: "editPremise",
  });
};

const deleteBuilding = async (id) => {
  const uri = process.env.VUE_APP_FRONTENDV2_API_URL + `/rem/building/${id}`;
  try {
    await axios.delete(uri, {
      onTokenRefreshed: () => {
        deleteBuilding(id);
      },
    });
    toast.success(t("message.deleteSuccessful"));
    fetchBuildings();
  } catch (error) {
    console.log(error);
    toast.error(t("message.deleteNotSuccessful"));
  }
};

const deletePremise = async (id) => {
  const uri = process.env.VUE_APP_FRONTENDV2_API_URL + `/rem/premise/${id}`;
  try {
    await axios.delete(uri, {
      onTokenRefreshed: () => {
        deletePremise(id);
      },
    });
    toast.success(t("message.deleteSuccessful"));
  } catch (error) {
    console.log(error);
    toast.error(t("message.deleteNotSuccessful"));
  }
};

const deleteDocument = async (id) => {
  const uri = process.env.VUE_APP_FRONTENDV2_API_URL + `/rem/document/${id}`;
  try {
    await axios.delete(uri, {
      onTokenRefreshed: () => {
        deleteDocument(id);
      },
    });
    toast.success(t("message.deleteSuccessful"));
    fetchDocuments();
  } catch (error) {
    console.log(error);
    toast.error(t("message.deleteNotSuccessful"));
  }
};

const fetchDocuments = async () => {
  let uri = "";

  if (propCurrentTab.value == "documents") {
    uri =
      process.env.VUE_APP_FRONTENDV2_API_URL +
      `/rem/property/${props.property?.id}/documents`;
  } else if (propCurrentTab.value == "buildingDocuments") {
    uri =
      process.env.VUE_APP_FRONTENDV2_API_URL +
      `/rem/building/${props.building?.id}/documents`;
  }

  try {
    const response = await axios.get(uri, {
      onTokenRefreshed: () => {
        fetchDocuments();
      },
    });
    state.documents = response.data.content;
    console.log(state.documents);
  } catch (error) {
    console.log(error);
  }
};

const propCurrentTab = computed(() => {
  return store.getters.getPropCurrentTab;
});

onMounted(() => {});
</script>

<style></style>
