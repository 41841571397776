<template>
  <div>
    <div v-if="props.propEntity == 'property'">
      <a
        class="cursor-pointer text-white mt-2 btn btn-circle btn-sm bg-magenta"
        @click="openMenu()"
        ><svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          fill="none"
          stroke-width="1.5"
          stroke="currentColor"
          class="w-6 h-6"
        >
          <path
            d="M10,17L15,12L10,7V17Z"
            stroke-linecap="round"
            stroke-linejoin="round"
          /></svg
      ></a>
      <div
        class="fixed top-0 left-[-250px] w-[200px] h-screen z-50 bg-nrgGray p-5 flex flex-col space-y-5 text-white duration-300"
        :class="{ opened: state.isOpen, closed: !state.isOpen }"
      >
        <a
          href="javascript:void(0)"
          class="text-right text-4xl hover:text-magenta hover:cursor-pointer"
          @click="closeMenu()"
          >&times;</a
        >
        <a
          class="hover:text-magenta hover:cursor-pointer"
          @click="
            store.commit('setPropCurrentTab', 'buildings');
            closeMenu();
          "
          :class="{
            'text-magenta hover:text-white': propCurrentTab == 'buildings',
          }"
          >{{ $t("message.buildings") }}</a
        >

        <a
          class="hover:text-magenta hover:cursor-pointer"
          @click="
            store.commit('setPropCurrentTab', 'addresses');
            closeMenu();
          "
          :class="{
            'text-magenta hover:text-white': propCurrentTab == 'addresses',
          }"
          >{{ $t("message.addresses") }}</a
        >

        <a
          class="hover:text-magenta hover:cursor-pointer"
          @click="
            store.commit('setPropCurrentTab', 'partners');
            closeMenu();
          "
          :class="{
            'text-magenta hover:text-white': propCurrentTab == 'partners',
          }"
          >{{ $t("message.partners") }}</a
        >

        <a
          class="hover:text-magenta hover:cursor-pointer"
          @click="
            store.commit('setPropCurrentTab', 'documents');
            closeMenu();
          "
          :class="{
            'text-magenta hover:text-white': propCurrentTab == 'documents',
          }"
          >{{ $t("message.documents") }}</a
        >

        <a
          class="hover:text-magenta hover:cursor-pointer"
          @click="
            store.commit('setPropCurrentTab', 'baseDatas');
            closeMenu();
          "
          :class="{
            'text-magenta hover:text-white': propCurrentTab == 'baseDatas',
          }"
          >{{ $t("message.baseDatas") }}</a
        >

        <a
          class="hover:text-magenta hover:cursor-pointer"
          @click="
            store.commit('setPropCurrentTab', 'propLegalSituations');
            closeMenu();
          "
          :class="{
            'text-magenta hover:text-white':
              propCurrentTab == 'propLegalSituations',
          }"
          >{{ $t("message.legalSituations") }} & <br />
          {{ $t("message.obligations") }}</a
        >

        <a
          class="hover:text-magenta hover:cursor-pointer"
          @click="
            store.commit('setPropCurrentTab', 'utilizations');
            closeMenu();
          "
          :class="{
            'text-magenta hover:text-white': propCurrentTab == 'utilizations',
          }"
          >{{ $t("message.utilizations") }}</a
        >
      </div>
    </div>
    <div v-if="props.propEntity == 'building'">
      <a
        class="cursor-pointer text-white mt-2 btn btn-circle btn-sm bg-magenta"
        @click="openMenu()"
        ><svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          fill="none"
          stroke-width="1.5"
          stroke="currentColor"
          class="w-6 h-6"
        >
          <path
            d="M10,17L15,12L10,7V17Z"
            stroke-linecap="round"
            stroke-linejoin="round"
          /></svg
      ></a>

      <div
        class="fixed top-0 left-[-250px] w-[200px] h-screen z-50 bg-nrgGray p-5 flex flex-col space-y-5 text-white duration-300"
        :class="{ opened: state.isOpen, closed: !state.isOpen }"
      >
        <a
          href="javascript:void(0)"
          class="text-right text-4xl hover:text-magenta hover:cursor-pointer"
          @click="closeMenu()"
          >&times;</a
        >
        <a
          class="hover:text-magenta hover:cursor-pointer"
          @click="
            store.commit('setPropCurrentTab', 'premises');
            closeMenu();
          "
          :class="{
            'text-magenta hover:text-white': propCurrentTab == 'premises',
          }"
          >{{ $t("message.premises") }}</a
        >

        <a
          class="hover:text-magenta hover:cursor-pointer"
          @click="
            store.commit('setPropCurrentTab', 'buildingDocuments');
            closeMenu();
          "
          :class="{
            'text-magenta hover:text-white':
              propCurrentTab == 'buildingDocuments',
          }"
          >{{ $t("message.documents") }}</a
        >

        <a
          class="hover:text-magenta hover:cursor-pointer"
          @click="
            store.commit('setPropCurrentTab', 'baseDatas');
            closeMenu();
          "
          :class="{
            'text-magenta hover:text-white': propCurrentTab == 'baseDatas',
          }"
          >{{ $t("message.baseDatas") }}</a
        >

        <a
          class="hover:text-magenta hover:cursor-pointer"
          @click="
            store.commit('setPropCurrentTab', 'mtResponsibles');
            closeMenu();
          "
          :class="{
            'text-magenta hover:text-white': propCurrentTab == 'mtResponsibles',
          }"
          >{{ $t("message.mtResponsibles") }}</a
        >

        <a
          class="hover:text-magenta hover:cursor-pointer"
          @click="
            store.commit('setPropCurrentTab', 'structureTypes');
            closeMenu();
          "
          :class="{
            'text-magenta hover:text-white': propCurrentTab == 'structureTypes',
          }"
          >{{ $t("message.structureTypes") }}</a
        >

        <a
          class="hover:text-magenta hover:cursor-pointer"
          @click="
            store.commit('setPropCurrentTab', 'buildingLegalSituations');
            closeMenu();
          "
          :class="{
            'text-magenta hover:text-white':
              propCurrentTab == 'buildingLegalSituations',
          }"
          >{{ $t("message.legalSituations") }}</a
        >

        <a
          class="hover:text-magenta hover:cursor-pointer"
          @click="
            store.commit('setPropCurrentTab', 'buildingUtilizations');
            closeMenu();
          "
          :class="{
            'text-magenta hover:text-white':
              propCurrentTab == 'buildingUtilizations',
          }"
          >{{ $t("message.utilizations") }}</a
        >

        <a
          class="hover:text-magenta hover:cursor-pointer"
          @click="
            store.commit('setPropCurrentTab', 'buildingMeters');
            closeMenu();
          "
          :class="{
            'text-magenta hover:text-white': propCurrentTab == 'buildingMeters',
          }"
          >{{ $t("message.meters") }}</a
        >
      </div>
    </div>
  </div>
</template>

<script setup>
import store from "@/misc/vuex-store.js";
import { onMounted, computed, reactive } from "vue";

const props = defineProps(["propEntity"]);

const state = reactive({
  isOpen: false,
});

const closeMenu = () => {
  state.isOpen = false;
  store.commit("setSidebarState", state.isOpen);
};

const openMenu = () => {
  state.isOpen = true;
  store.commit("setSidebarState", state.isOpen);
};

onMounted(() => {
  if (props.propEntity == "property") {
    store.commit("setPropCurrentTab", "buildings");
  }

  if (props.propEntity == "building") {
    store.commit("setPropCurrentTab", "premises");
  }
});

const propCurrentTab = computed(() => {
  return store.getters.getPropCurrentTab;
});
</script>

<style scoped>
.cardBg {
  background-color: hsl(220, 17.647%, 20%);
}

.closed {
  left: -250px;
}

.opened {
  left: 0;
}
</style>
