<template>
  <div class="h-full overflow-y-auto w-full">
    <div
      class="mt-5 mx-5 flex flex-row grow border-b-0 border-base-300 justify-between"
    >
      <div class="flex flex-row items-center gap-2 justify-center">
        <h2
          class="text-base font-sans font-semibold uppercase text-secondary flex flex-row items-center gap-2 grow"
        >
          <span class="text-secondary">{{ $t("message.addNewProperty") }}</span>
        </h2>
      </div>
      <a
        href="javascript:void(null)"
        class="hover:text-secondary"
        @click="hideSidebar"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke-width="1.5"
          stroke="currentColor"
          class="w-6 h-6"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            d="M6 18L18 6M6 6l12 12"
          />
        </svg>
      </a>
    </div>

    <div class="px-5 pb-5">
      <div class="flex flex-col items-center justify-center gap-2 w-full">
        <div class="flex flex-row gap-3 w-full mt-2">
          <div class="form-control w-full mt-0">
            <label class="label mt-0 pt-0" for="">
              <span class="label-text-alt">{{
                $t("message.propertySheetAppellation")
              }} <span class="text-error">*</span></span>
            </label>
            <input
              v-model="state.basicData.propertySheetAppellation"
              type="text"
              class="input input-sm input-bordered w-full"
            />
          </div>
        </div>

        <div class="flex flex-row gap-3 w-full mt-2">
          <div class="form-control w-full mt-0">
            <label class="label mt-0 pt-0" for="">
              <span class="label-text-alt">{{ $t("message.type") }}</span>
            </label>
            <OptionValueSelector
              type="PROPERTY_TYPE"
              v-model="state.basicData.type"
            />
          </div>
        </div>

        <div class="flex flex-row gap-3 w-full mt-2">
          <div class="form-control w-full mt-0">
            <label class="label mt-0 pt-0" for="">
              <span class="label-text-alt">{{
                $t("message.classification")
              }}</span>
            </label>
            <OptionValueSelector
              v-model="state.basicData.classification"
              type="PROPERTY_CLASSIFICATION"
            />
          </div>

          <div class="form-control w-full mt-0">
            <label class="label mt-0 pt-0" for="">
              <span class="label-text-alt">{{ $t("message.status") }}</span>
            </label>
            <OptionValueSelector
              v-model="state.basicData.status"
              type="PROPERTY_STATUS"
            />
          </div>
        </div>

        <div
          class="flex flex-row gap-3 w-full border-b-2 border-secondary mt-4 font-bold"
        >
          <div class="form-control w-full mt-2">
            <label class="label mt-0 pt-0" for="">
              <span class="label-text">{{ $t("message.address") }}</span>
            </label>
          </div>
        </div>

        <div class="flex flex-row gap-3 w-full mt-2">
          <div class="form-control w-3/5 mt-0">
            <label class="label mt-0 pt-0" for="">
              <span class="label-text-alt">{{ $t("message.country") }} <span class="text-error">*</span></span>
            </label>
            <input
              v-model="state.addresses.country"
              type="text"
              class="input input-sm input-bordered w-full"
            />
          </div>
          <div class="form-control w-2/5 mt-0">
            <label class="label mt-0 pt-0" for="">
              <span class="label-text-alt">{{ $t("message.postal") }} <span class="text-error">*</span></span>
            </label>
            <input
              v-model="state.addresses.postal"
              type="number"
              class="input input-sm input-bordered w-full"
              @blur="fetchCountiesAndCities"
            />
          </div>
        </div>

        <div
          v-if="!/^\d{4}$/.test(state.addresses.postal)"
          class="flex flex-row gap-3 w-full mt-2"
        >
          <div class="form-control w-full mt-0">
            <label class="label mt-0 pt-0" for="">
              <span class="label-text-alt text-red-400">{{
                postalWarning
              }}</span>
            </label>
          </div>
        </div>

        <div class="flex flex-row gap-3 w-full">
          <div class="form-control w-full mt-0">
            <label class="label mt-0 pt-0" for="">
              <span class="label-text-alt">{{ $t("message.county") }} <span class="text-error">*</span></span>
            </label>
            <select
              v-model="state.addresses.county"
              class="select select-sm w-full select-bordered"
              :disabled="state.isCountyDisabled"
            >
              <option selected disabled>{{ $t("message.choose") }}</option>
              <option v-for="item in state.cities" :key="item.id">
                {{ item.county }}
              </option>
            </select>
          </div>
          <div class="form-control w-full mt-0">
            <label class="label mt-0 pt-0" for="">
              <span class="label-text-alt">{{ $t("message.city") }} <span class="text-error">*</span></span>
            </label>
            <select
              v-model="state.addresses.city"
              class="select select-sm w-full select-bordered"
              :disabled="state.isCityDisabled"
            >
              <option disabled selected>{{ $t("message.choose") }}</option>
              <option v-for="item in state.cities" :key="item.id">
                {{ item.name }}
              </option>
            </select>
          </div>
        </div>

        <div class="flex flex-row gap-3 w-full">
          <div class="form-control w-3/5 mt-0">
            <label class="label mt-0 pt-0" for="">
              <span class="label-text-alt">{{
                $t("message.publicAreaName")
              }} <span class="text-error">*</span></span>
            </label>
            <input
              v-model="state.addresses.public_area_name"
              type="text"
              class="input input-sm input-bordered w-full"
            />
          </div>
          <div class="form-control w-2/5 mt-0">
            <label class="label mt-0 pt-0" for="">
              <span class="label-text-alt">{{
                $t("message.publicAreaType")
              }} <span class="text-error">*</span></span>
            </label>
            <OptionValueSelector type="PUBLIC_AREA_TYPE" v-model="state.addresses.public_area_type" />
          </div>
        </div>

        <div class="flex flex-row gap-3 w-full mt-2">
          <div class="form-control w-full mt-0">
            <label class="label mt-0 pt-0" for="">
              <span class="label-text-alt">{{
                $t("message.houseNumber")
              }} <span class="text-error">*</span></span>
            </label>
            <input
              v-model="state.addresses.house_number"
              type="text"
              class="input input-sm input-bordered w-full"
            />
          </div>

          <div class="form-control w-full mt-0">
            <label class="label mt-0 pt-0" for="">
              <span class="label-text-alt">{{ $t("message.stairway") }}</span>
            </label>
            <input
              v-model="state.addresses.stairway"
              type="text"
              class="input input-sm input-bordered w-full"
            />
          </div>

          <div class="form-control w-full mt-0">
            <label class="label mt-0 pt-0" for="">
              <span class="label-text-alt">{{ $t("message.floor") }}</span>
            </label>
            <input
              v-model="state.addresses.floor"
              type="text"
              class="input input-sm input-bordered w-full"
            />
          </div>

          <div class="form-control w-full mt-0">
            <label class="label mt-0 pt-0" for="">
              <span class="label-text-alt">{{ $t("message.door") }}</span>
            </label>
            <input
              v-model="state.addresses.door"
              type="text"
              class="input input-sm input-bordered w-full"
            />
          </div>
        </div>

        <div class="flex flex-row gap-3 w-full mt-2">
          <div class="form-control w-full mt-0">
            <label class="label mt-0 pt-0" for="">
              <span class="label-text-alt">{{ $t("message.notes") }}</span>
            </label>
            <input
              v-model="state.addresses.notes"
              type="text"
              class="input input-sm input-bordered w-full"
            />
          </div>
        </div>

        <div
          class="flex flex-row gap-3 w-full border-b-2 border-secondary mt-4 font-bold"
        >
          <div class="form-control w-full mt-2">
            <label class="label mt-0 pt-0" for="">
              <span class="label-text">{{ $t("message.baseDatas") }}</span>
            </label>
          </div>
        </div>

        <div class="flex flex-row gap-3 w-full mt-2">
          <div class="form-control w-full mt-0">
            <label class="label mt-0 pt-0" for="">
              <span class="label-text-alt">{{
                $t("message.parcelNumber")
              }}</span>
            </label>
            <input
              v-model="state.addresses.parcelNumber"
              type="text"
              class="input input-sm input-bordered w-full"
            />
          </div>

          <div class="form-control w-full mt-0">
            <label class="label mt-0 pt-0" for="">
              <span class="label-text-alt">{{
                $t("message.gpsCoordinates")
              }}</span>
            </label>
            <input
              v-model="state.addresses.gpsCoordinates"
              type="text"
              class="input input-sm input-bordered w-full"
            />
          </div>
        </div>

        <div class="flex flex-row gap-3 w-full mt-2">
          <div class="form-control w-full mt-0">
            <label class="label mt-0 pt-0" for="">
              <span class="label-text-alt">{{ $t("message.floorArea") }} <span class="text-error">*</span></span>
            </label>
            <input
              v-model="state.basicData.floorArea"
              type="number"
              class="input input-sm input-bordered w-full"
            />
          </div>

          <div class="form-control w-full mt-0">
            <label class="label mt-0 pt-0" for="">
              <span class="label-text-alt">{{ $t("message.mtArea") }}</span>
            </label>
            <input
              v-model="state.basicData.mtArea"
              type="number"
              class="input input-sm input-bordered w-full"
            />
          </div>
        </div>

        <div class="flex flex-row gap-3 w-full mt-2">
          <div class="form-control w-full mt-0">
            <label class="label mt-0 pt-0" for="">
              <span class="label-text-alt">{{ $t("message.kng") }}</span>
            </label>
            <input
              v-model="state.basicData.kng"
              type="text"
              class="input input-sm input-bordered w-full"
            />
          </div>

          <div class="form-control w-full mt-0">
            <label class="label mt-0 pt-0" for="">
              <span class="label-text-alt">{{ $t("message.floc") }}</span>
            </label>
            <input
              v-model="state.basicData.floc"
              type="text"
              class="input input-sm input-bordered w-full"
            />
          </div>

          <div class="form-control w-full mt-0">
            <label class="label mt-0 pt-0" for="">
              <span class="label-text-alt">{{ $t("message.sap") }}</span>
            </label>
            <input
              v-model="state.basicData.sap"
              type="text"
              class="input input-sm input-bordered w-full"
            />
          </div>
        </div>

        <div class="flex flex-row gap-3 w-full mt-2">
          <div class="form-control w-full mt-0">
            <label class="label mt-0 pt-0" for="">
              <span class="label-text-alt">{{ $t("message.protection") }}</span>
            </label>
            <input
              v-model="state.basicData.protection"
              type="text"
              class="input input-sm input-bordered w-full"
            />
          </div>

          <div class="form-control w-full mt-0">
            <label class="label mt-0 pt-0" for="">
              <span class="label-text-alt">{{
                $t("message.protectionNote")
              }}</span>
            </label>
            <input
              v-model="state.basicData.protectionNote"
              type="text"
              class="input input-sm input-bordered w-full"
            />
          </div>
        </div>

        <div class="flex flex-row gap-3 w-full mt-2">
          <div class="form-control w-full mt-0">
            <label class="label mt-0 pt-0" for="">
              <span class="label-text-alt">{{
                $t("message.numberDedicated")
              }}</span>
            </label>
            <input
              v-model="state.basicData.numberDedicated"
              type="number"
              class="input input-sm input-bordered w-full"
            />
          </div>

          <div class="form-control w-full mt-0">
            <label class="label mt-0 pt-0" for="">
              <span class="label-text-alt">{{
                $t("message.numberCanBeSeated")
              }}</span>
            </label>
            <input
              v-model="state.basicData.numberCanBeSeated"
              type="number"
              class="input input-sm input-bordered w-full"
            />
          </div>

          <div class="form-control w-full mt-0">
            <label class="label mt-0 pt-0" for="">
              <span class="label-text-alt">{{
                $t("message.numberSeated")
              }}</span>
            </label>
            <input
              v-model="state.basicData.numberSeated"
              type="number"
              class="input input-sm input-bordered w-full"
            />
          </div>
        </div>

        <div class="flex flex-row gap-3 w-full mt-2">
          <div class="form-control w-full mt-0 pt-4">
            <label class="label mt-0 pt-0" for="">
              <span class="label-text-alt">{{ $t("message.solarPanel") }}</span>
            </label>
            <select
              v-model="state.selectedSolar"
              class="select select-sm w-full select-bordered"
              @change="updateSolar"
            >
              <option disabled selected>{{ $t("message.choose") }}</option>
              <option value="yes">{{ $t("message.yes") }}</option>
              <option value="no">{{ $t("message.no") }}</option>
            </select>
          </div>

          <div class="form-control w-full mt-0">
            <label class="label mt-0 pt-0" for="">
              <span class="label-text-alt">{{
                $t("message.fuelCellGenerator")
              }}</span>
            </label>
            <select
              v-model="state.selectedFuelCell"
              class="select select-sm w-full select-bordered"
              @change="updateFuelCell"
            >
              <option disabled selected>{{ $t("message.choose") }}</option>
              <option value="yes">{{ $t("message.yes") }}</option>
              <option value="no">{{ $t("message.no") }}</option>
            </select>
          </div>
        </div>

        <div class="flex flex-row gap-3 w-full mt-2">
          <div class="form-control w-full mt-0">
            <label class="label mt-0 pt-0" for="">
              <span class="label-text-alt">{{
                $t("message.dieselGenerator")
              }}</span>
            </label>
            <select
              v-model="state.selectedDiesel"
              class="select select-sm w-full select-bordered"
              @change="updateDiesel"
            >
              <option disabled selected>{{ $t("message.choose") }}</option>
              <option value="yes">{{ $t("message.yes") }}</option>
              <option value="no">{{ $t("message.no") }}</option>
            </select>
          </div>

          <div class="form-control w-full mt-0">
            <label class="label mt-0 pt-0" for="">
              <span class="label-text-alt">{{
                $t("message.energyTradingPartner")
              }}</span>
            </label>
            <select
              v-model="state.selectedEnergyPartner"
              class="select select-sm w-full select-bordered"
              @change="updateEnergyPartner"
            >
              <option disabled selected>{{ $t("message.choose") }}</option>
              <option value="yes">{{ $t("message.yes") }}</option>
              <option value="no">{{ $t("message.no") }}</option>
            </select>
          </div>
        </div>

        <div class="w-full mt-3">
          <button
            :disabled="!validation()"
            class="disabled:opacity-60 btn btn-sm bg-magenta text-white font-light text-xs border-none"
            @click="addNewProperty"
          >
            {{ $t("message.addNewProperty") }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import Axios from "@/misc/axios";
import { reactive, computed, onMounted } from "vue";
import toast from "@/misc/toast.js";
import { useI18n } from "vue-i18n";
import store from "@/misc/vuex-store";
import OptionValueSelector from "../../../../components/OptionValueSelector.vue";

const { t } = useI18n();

const props = defineProps(["params"])

const state = reactive({
  selectedSolar: null,
  selectedFuelCell: null,
  selectedDiesel: null,
  selectedEnergyPartner: null,
  isCountyDisabled: false,
  isCityDisabled: false,
  cities: [],
  addresses: {
    country: "",
    postal: null,
    county: "",
    city: "",
    district: "",
    public_area_name: "",
    public_area_type: "",
    house_number: "",
    stairway: "",
    floor: "",
    door: "",
    parcelNumber: "",
    gpsCoordinates: "",
    notes: "",
  },
  basicData: {
    floorArea: null,
    mtArea: null,
    kng: "",
    floc: "",
    sap: "",
    type: null,
    propertySheetAppellation: "",
    protection: "",
    protectionNote: "",
    numberDedicated: null,
    numberCanBeSeated: null,
    numberSeated: null,
    classification: "",
    status: "",
    solarPanel: null,
    fuelCellGenerator: null,
    dieselGenerator: null,
    energyTradingPartner: null,
  },
});

// function validation() {
//   const postalRegex = /^\d{4}$/;
//   if (!postalRegex.test(state.addresses.postal)) return false;

//   return true;
// }

async function fetchCountiesAndCities() {
  if (state.addresses.postal !== "") {
    const uri =
      process.env.VUE_APP_FRONTENDV2_API_URL +
      `/rem/partner/settlement/postal?postalCode=${state.addresses.postal}`;

    try {
      {
        const response = await Axios.get(uri);
        state.cities = response.data;
      }
      autoFill();
    } catch (error) {
      console.log(error);
    }
  } else {
    emptyFill();
  }
}
const autoFill = () => {
  if (state.cities.length == 1) {
    state.addresses.county = state.cities[0].county;
    state.addresses.city = state.cities[0].name;
  } else {
    state.addresses.county = state.cities[0].county;
    state.addresses.city = t("message.choose");
    state.isCountyDisabled = true;
    state.isCityDisabled = false;
  }
};

const emptyFill = () => {
  if (state.addresses.postal === "") {
    state.cities = [];
    state.isCountyDisabled = false;
    state.isCityDisabled = false;
  }
};

const postalWarning = computed(() => {
  return "*" + t("message.postalRegex");
});

async function addNewProperty() {
  const uri = process.env.VUE_APP_FRONTENDV2_API_URL + `/rem/property`;
  const postBody = {
    addresses: [
      {
        addressType: "PRIMARY",
        country: state.addresses.country,
        postalCode: state.addresses.postal,
        county: state.addresses.county,
        city: state.addresses.city,
        publicAreaName: state.addresses.public_area_name,
        publicAreaType: state.addresses.public_area_type,
        houseNumber: state.addresses.house_number,
        stairWay: state.addresses.stairway,
        floor: state.addresses.floor,
        door: state.addresses.door,
        parcelNumber: state.addresses.parcelNumber,
        gpsCoordinates: state.addresses.gpsCoordinates,
        notes: state.addresses.notes,
      },
    ],
    basicData: {
      floorArea: state.basicData.floorArea,
      mtArea: state.basicData.mtArea,
      kng: state.basicData.kng,
      floc: state.basicData.floc,
      sap: state.basicData.sap,
      type: state.basicData.type,
      propertySheetAppellation: state.basicData.propertySheetAppellation,
      protection: state.basicData.protection,
      protectionNote: state.basicData.protectionNote,
      numberDedicated: state.basicData.numberDedicated,
      numberCanBeSeated: state.basicData.numberCanBeSeated,
      numberSeated: state.basicData.numberSeated,
      classification: state.basicData.classification,
      status: state.basicData.status,
      solarPanel: state.basicData.solarPanel,
      fuelCellGenerator: state.basicData.fuelCellGenerator,
      dieselGenerator: state.basicData.dieselGenerator,
      energyTradingPartner: state.basicData.energyTradingPartner,
    },
    propertyObligations: [
      {
        obligationType: "RIGHT",
        otherRight: 148,
        permission: "asd",
        obliged: "asd"
      },
      {
        obligationType: "OBLIGATION",
        otherRight: 151,
        permission: "asd",
        obliged: "asd"
      }
    ]
  };

  try {
    await Axios.post(uri, postBody, {
      onTokenRefreshed: () => {
        addNewProperty();
      },
    });
    hideSidebar();
    if (props.params && props.params.onSave) {
      props.params.onSave();
    }
    toast.success(t("message.successfulSave"));
  } catch (err) {
    console.log(err);
    toast.error(t("message.saveError"));
  }
}

const updateSolar = () => {
  state.selectedSolar === "yes"
    ? (state.basicData.solarPanel = 1)
    : (state.basicData.solarPanel = 0);
};

const updateFuelCell = () => {
  state.selectedFuelCell === "yes"
    ? (state.basicData.fuelCellGenerator = 1)
    : (state.basicData.fuelCellGenerator = 0);
};

const updateDiesel = () => {
  state.selectedDiesel === "yes"
    ? (state.basicData.dieselGenerator = 1)
    : (state.basicData.dieselGenerator = 0);
};

const updateEnergyPartner = () => {
  state.selectedEnergyPartner === "yes"
    ? (state.basicData.energyTradingPartner = 1)
    : (state.basicData.energyTradingPartner = 0);
};

function validation() {
  const empty =
    !state.addresses.country ||
    !state.addresses.postal ||
    !state.addresses.county ||
    !state.addresses.city ||
    !state.addresses.public_area_name ||
    !state.addresses.public_area_type ||
    !state.addresses.house_number ||
    !state.basicData.propertySheetAppellation ||
    !state.basicData.floorArea

  if (empty) return false;

  return true;
}

const hideSidebar = () => {
  store.commit("hideSidebar");
};

onMounted(() => {});
</script>

<style scoped></style>
