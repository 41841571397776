<template>
  <b-row class="RVGFilterableLookup">
    <b-col md="4">
      <b-form-group>
        <label for="selectorOperationField">Művelet:</label>
        <b-form-select id="selectorOperationField" v-model="operation" :options="possibleOperations"></b-form-select>
      </b-form-group>
    </b-col>
    <b-col md="8">
      <b-form-group>

        <label for="selectorLookupSelector" class="inlineLabel">Értékek kiválasztása:</label>

        <b-form-select
          class="selectorLookupSelector"
          :disabled="!availableOptions || availableOptions.length === 0"
          :options="availableOptions"
          @change="addTag"
          v-model="currentSelectedValue"
        >
          <template #first>
            <!-- This is required to prevent bugs with Safari -->
            <option disabled value="null">--Lehetséges értékek--</option>
          </template>
        </b-form-select>

        <ul v-if="selectedItems && selectedItems.length > 0" class="list-inline d-inline-block mb-2 mt-3 pull-left">
          <li v-for="tag in selectedItems" :key="tag[config.idKey]" class="list-inline-item">
            <b-form-tag
              @remove="removeTag(tag[config.idKey])"
              variant="secondary"
            >{{ config.displayFormatterForSelectedTag(tag) }}</b-form-tag>
          </li>
        </ul>

        <b-form-invalid-feedback :state="validation" class="pull-left">
          Kötelező mező
        </b-form-invalid-feedback>
      </b-form-group>
    </b-col>
  </b-row>
</template>

<script>
export default {
  name: "RVGFilterableLookup",
  props: ['data', 'config', 'id', 'formatterConfig'],
  components: {
  },
  data() {
    return {
      operation: 'in',
      dataObjects: [],
      selectedItems: [],
      currentSelectedValue: "null"
    }
  },
  computed: {
    possibleOperations() {
      return {
        'in': 'az alábbiak valamelyike:'
      }
    },
    validation() {
      return !!(this.selectedItems.length > 0)
    },
    availableOptions() {
      var opts = {};
      for(var i in this.dataObjects) {
        var f = false;
        for(var j in this.selectedItems) {
          if(this.selectedItems[j][this.config.idKey] == this.dataObjects[i][this.config.idKey]) {
            f = true
          }
        }

        if(!f) {
          opts[this.dataObjects[i][this.config.idKey]] = this.config.displayFormatterForDropdown(this.dataObjects[i])
        }
      }

      return opts
    }
  },
  mounted() {
    var vm = this;
    if(this.config.fetchData) {
      this.config.fetchData((data) => {
        vm.dataObjects = data;
      }, (err) => {
        console.log(err)
      })
    }
  },
  methods: {
    changeValue() {
      var ids = [];
      var titles = [];
      for(var i in this.currentValue) {
        ids.push(this.currentValue[i][this.config.idKey]);
        titles.push(this.config.displayFormatterForTextual(this.currentValue[i]))
      }

      this.$emit('changeValue', this.id, {
        field: this.id,
        filterKey: this.config.filterKey ? this.config.filterKey : this.id,
        type: 'lookup',
        operation: this.operation,
        value: ids,
        isValid: this.validation,
        textual: '%%fieldname%% ' + this.possibleOperations[this.operation] + ' ' + titles.join(", ")
      })
    },
    addTag(optId) {
      var obj = null;

      for(var i in this.dataObjects) {
        if(this.dataObjects[i][this.config.idKey] == optId) {
          obj = this.dataObjects[i];
        }
      }

      this.selectedItems.push(obj)

      this.currentSelectedValue = "null";
      this.changeValue()
    },
    removeTag(optId) {
      var ind = -1;
      for(var i in this.selectedItems) {
        if(this.selectedItems[i][this.config.idKey] == optId) {
          ind = i
          break;
        }
      }

      if(ind > -1) {
        this.selectedItems.splice(ind, 1)
      }

      this.currentSelectedValue = "null";
      this.changeValue()
    },
  }
};
</script>

<style scoped>
  label {
    float: left;
  }

  .pull-left {
    float: left;
    text-align: left;
  }
</style>
