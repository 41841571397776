import RVGLocalStorageService from './RobberVueGrid.LocalStorage.js';
import Utils from './RobberVueGridUtils.js'
import Axios from 'axios'
import RVGFilterableNumberConfig from './Filterables/RVGFilterableNumber.config.js';
import RVGFilterableDateConfig from './Filterables/RVGFilterableDate.config.js';
import RVGFilterableStatusConfig from './Filterables/RVGFilterableStatus.config.js';
import RVGFilterableTextConfig from './Filterables/RVGFilterableText.config.js';
import l from './Helpers/lang.js'

export default {
  getLang(key, params = null) {
    return l(key, params);
  },
  refresh() {
    this.refreshNeeded = true;
  },
  reloadPage() {
    window.location.reload();
  },
  changePageSize(size) {
    this.pagination.size = size;
    if(this.config.pagination && this.config.pagination.gridUniqueId) {
      RVGLocalStorageService.getService().setStorageValue('pagesizevalue_' + this.config.pagination.gridUniqueId, size)
    }
    this.refresh();
  },
  registerEvent(event, callback) {
    this.registeredEvents[event] = callback;
  },
  setSimpleFilter(filter) {
    this.simpleFilter = filter; 
    this.pagination.page = 0; 
    this.refreshNeeded = true;
  },
  async refetchData() {
    try {
      if (!this.refreshNeeded) {
        return;
      }
      
      const response = await Axios.get(this.config.endpoint, {
        params: this.axiosParams,
        onTokenRefreshed: () => {
          this.refetchData();
        }
      });

      let resData = response.data;
      if(typeof resData == "string") {
        resData = JSON.parse(resData);
      }

      var rec = null;
      if (this.config.rootkey) {
        rec = resData[this.config.rootkey];
      }
      else {
        rec = resData;
      }

      if(this.config.events && this.config.events.onDataLoad) {
        var eventResult = this.config.events.onDataLoad(rec);
        if(typeof eventResult == "object") {
          rec = eventResult;
        }
      }

      if(this.config.events && this.config.events.onDataLoadWithFullResponse) {
        this.config.events.onDataLoadWithFullResponse(response);
      }

      if(this.registeredEvents && this.registeredEvents.onDataLoad) {
        this.registeredEvents.onDataLoad(rec);
      }

      this.records = rec;

      if(this.config.pagination && this.config.pagination.active) {
        this.pagination.totalPages = Math.ceil(resData.totalElements / this.pagination.size);
        this.pagination.totalElements = resData.totalElements
      }

      if(this.config.events && this.config.events.readyAfterRefresh) {
        this.$nextTick(function () {
          this.config.events.readyAfterRefresh();
        });
      }

      this.refreshNeeded = false;
    } catch (error) {
      //console.log(error);
    }
  },
  getPagesizeIconClass(size) {
    return (this.pagination.size == size) ? 'refrechCheckEnabled' : 'refrechCheckDisabled';
  },
  orderChange(event, field) {
    if (!this.order.active) {
      this.order.active = true
      this.order.field = field
    }
    else {
      if (this.order.field == field) {
        if (this.order.direction == "asc") {
          this.order.direction = "desc"
        }
        else {
          this.order.direction = "asc"
        }
      }
      else {
        this.order.field = field
        this.order.direction = "asc"
      }
    }

    if(!this.config.orderLocal) {
      this.refreshNeeded = true
    }
  },
  removeOrder() {
    this.order.active = false;
    this.order.field = null;
    this.order.direction = 'asc';
    if(!this.config.orderLocal) {
      this.refreshNeeded = true
    }
  },
  paginationBack() {
    if (this.pagination.page > 0) {
      this.pagination.page = this.pagination.page - 1;
      this.refreshNeeded = true;
    }
  },
  paginationNext() {
    if (this.pagination.page < this.pagination.totalPages) {
      this.pagination.page = this.pagination.page + 1;
      this.refreshNeeded = true;
    }
  },
  showFilterBar() {
    if (!this.filter.filterBarShown) {
      this.filter.filterBarShown = true;
    }
    else {
      this.filter.conditions = [];
      this.filter.filterBarShown = false;
      this.refreshNeeded = true;
    }
  },
  changeFilterValue(id, condition) {
    this.filter.conditions.push(condition);
    this.refreshNeeded = true;
  },
  checkAll(value) {
    if (!value) {
      this.checkedRows = [];
      return;
    }

    var newCheckedRows = [];
    for (var i in this.records) {
      newCheckedRows.push(this.records[i].id)
    }
    this.checkedRows = newCheckedRows;
  },
  isFilterAlreadyExists(field) {
    for(var i in this.filters) {
      if(this.filters[i].field == field) {
        return true;
      }
    }

    return false;
  },
  updateFilterParametersInURL() {
    const query = JSON.parse(JSON.stringify(this.$route.query));
    delete query.filters;
    delete query.filter_operator;

    if(this.filters && this.filters.length > 0) {
      var newFilters = [];
      for(var i in this.filters) {
        var cFilter = JSON.parse(JSON.stringify(this.filters[i]));
        delete cFilter.textual;
        newFilters.push(cFilter);
      }
      query.filters = Utils.btoa_utf8(JSON.stringify(newFilters));
    }

    if(this.filterOperator && this.filters.length > 1) {
      query.filter_operator = this.filterOperator;
    }

    if(this.$route.query.filter_operator != query.filter_operator || this.$route.query.filters != query.filters) {
      this.$router.replace({ path: this.$route.path, hash: this.$route.hash, query: query });
    }
  },
  pushFilter(filter) {
    if(this.isFilterAlreadyExists(filter.field)) {
      this.removeFilter(filter.field)
    }

    this.filters.push(filter);
    if(!this.config.filtering.local) {
      this.refreshNeeded = true;
    }
    this.updateFilterParametersInURL();
  },
  changeFilterOperator(op) {
    this.filterOperator = op;
    if(!this.config.filtering.local) {
      this.refreshNeeded = true;
    }
    this.updateFilterParametersInURL();
  },
  removeFilter(field) {
    var ind = -1;
    for(var i in this.filters) {
      if(this.filters[i].field == field) {
        ind = i;
        break;
      }
    }
    
    this.filters.splice(ind, 1);
    if(!this.config.filtering.local) {
      this.refreshNeeded = true;
    }
    this.updateFilterParametersInURL();
  },
  mainRowClick(recordId) {
    if(!this.isExtraRowEnabled) {
      return;
    }

    if(this.openedRow.includes(recordId)) {
      this.openedRow.splice(this.openedRow.indexOf(recordId), 1)
    }
    else {
      if(this.isExtraRowMultiOpenEnable) {
        this.openedRow.push(recordId);
      }
      else {
        this.openedRow = [recordId];
      }
    }
  },
  isRowOpened(recordId) {
    return this.openedRow.includes(recordId);
  },
  getRefreshableIconClass(key) {
    return (this.autoRefresh == key) ? 'refrechCheckEnabled' : 'refrechCheckDisabled';
  },
  changeAutoRefreshValue(key) {
    this.autoRefresh = key;
    if(this.config.refreshable && this.config.refreshable.gridUniqueId) {
      RVGLocalStorageService.getService().setStorageValue('autorefreshvalue_' + this.config.refreshable.gridUniqueId, this.autoRefresh)
    }
    this.setAutoRefreshIntervalIfAny();
  },
  setAutoRefreshIntervalIfAny() {
    if(this.autoRefreshInterval) { // clearing the current interval, if any
      clearInterval(this.autoRefreshInterval);
      this.autoRefreshInterval = null;
    }

    if(!this.autoRefresh || this.autoRefresh == "disabled") { // if autoRefresh is not exists or its disabled, we should not start any interval task
      return ;
    }

    // finding the new refresh type, because we need the interval value
    var newRefreshType = null;
    if(this.config.refreshable && this.config.refreshable.autoValues && this.config.refreshable.autoValues.length > 0) {
      for(var i in this.config.refreshable.autoValues) {
        if(this.config.refreshable.autoValues[i].key == this.autoRefresh) {
          newRefreshType = this.config.refreshable.autoValues[i];
          break;
        }
      }
    }

    //console.log("set interval for " + newRefreshType.refreshInterval * 1000)
    if(newRefreshType) {
      var vm = this;
      this.autoRefreshInterval = setInterval(() => {
        vm.refreshNeeded = true;
      }, newRefreshType.refreshInterval * 1000);
    }
  },
  isRecordMatchTheCurrentFiltering(record) {
    if(this.filters.length <= 0) {
      return true;
    }

    var matchBools = [];
    for(var i in this.filters) {
      var cFilter = this.filters[i];
      if(cFilter.type == "number") {
        matchBools.push(RVGFilterableNumberConfig.isMatch(record[cFilter.field], cFilter.operation, cFilter.value));
      }
      if(cFilter.type == "date") {
        matchBools.push(RVGFilterableDateConfig.isMatch(record[cFilter.field], cFilter.operation, cFilter.value));
      }
      if(cFilter.type == "status") {
        matchBools.push(RVGFilterableStatusConfig.isMatch(record[cFilter.field], cFilter.operation, cFilter.value));
      }
      if(cFilter.type == "text") {
        matchBools.push(RVGFilterableTextConfig.isMatch(record[cFilter.field], cFilter.operation, cFilter.value));
      }
    }

    if(matchBools.length <= 0) {
      return true;
    }

    if(this.filterOperator.toLowerCase() == "and") {
      return matchBools.includes(false) ? false : true;
    }

    if(this.filterOperator.toLowerCase() == "or") {
      return matchBools.includes(true) ? true : false;
    }
  },
  sortRecords(recArray, field, direction) {
    var fieldMappingConfig = this.config.mapping[field];
    return recArray.sort(function(first, second) {
      var a;
      var b;

      if(fieldMappingConfig && fieldMappingConfig.formatter && fieldMappingConfig.formatter.type && fieldMappingConfig.formatter.type.toLowerCase() == "status" && fieldMappingConfig.formatter.mapping) {
        a = fieldMappingConfig.formatter.mapping[first[field]].title.toLowerCase();
        b = fieldMappingConfig.formatter.mapping[second[field]].title.toLowerCase();        
      }
      else {
        if(first[field] && typeof first[field] == "string") { a = first[field].toLowerCase(); } else { a = ""; }
        if(second[field] && typeof second[field] == "string") { b = second[field].toLowerCase(); } else { b = ""; }

        if(typeof first[field] == "number") { a = first[field] }
        if(typeof second[field] == "number") { b = second[field] }
      }

      if(direction == "asc") {
        if(a < b) { return -1; }
        else if(a > b) { return 1; }
        else { return 0;}
      }
      else {
        if(a > b) { return -1; }
        else if(a < b) { return 1; }
        else { return 0; }
      }
    });
  },
  onBulkMethod() {
    var actionFn;
    for(var i in this.config.bulkOperation.methods) {
      if(this.config.bulkOperation.methods[i].key == this.bulkMethod) {
        actionFn = this.config.bulkOperation.methods[i].action;
        break;
      }
    }
    
    if(actionFn) {
      var that = this;
      actionFn(this.checkedRows, () => {
        that.bulkMethod = "null";
        that.checkedRows = [];
      });
    }

  }
}