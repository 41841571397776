<template>
  <div class="h-full overflow-y-scroll w-full">
    <div class="p-5 pb-2 mr-2 flex flex-col grow border-b-0 border-base-300">
      <div class="flex flex-row items-center gap-2 justify-center">
        <h2
          class="text-base font-sans font-semibold uppercase text-secondary flex flex-row items-center gap-2 grow"
        >
          <span class="text-secondary">{{ $t("message.editPremise") }}</span>
        </h2>
        <a
          href="javascript:void(null)"
          class="hover:text-secondary"
          @click="hideSidebar"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
            class="w-6 h-6"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M6 18L18 6M6 6l12 12"
            />
          </svg>
        </a>
      </div>
    </div>
    <div class="px-5 pb-5">
      <div class="flex flex-col items-center justify-center gap-2 w-full">
        <div class="flex flex-row gap-3 w-full mt-2">
          <div class="form-control w-full mt-0">
            <label class="label mt-0 pt-0" for="">
              <span class="label-text-alt">
                {{ $t("message.function") }}
                <span class="text-error">*</span></span
              >
            </label>
            <OptionValueSelector
              v-model="state.function"
              type="PREMISE_FUNCTION"
            />
          </div>

          <div class="form-control w-full mt-0">
            <label class="label mt-0 pt-0" for="">
              <span class="label-text-alt">{{
                $t("message.cremClassification")
              }}</span>
            </label>
            <OptionValueSelector
              v-model="state.cremClass"
              type="CREM_CLASSIFICATION"
            />
          </div>
        </div>

        <div class="flex flex-row gap-3 w-full mt-2">
          <div class="form-control w-full mt-0">
            <label class="label mt-0 pt-0" for="">
              <span class="label-text-alt"
                >{{ $t("message.identifier") }}
                <span class="text-error">*</span></span
              >
            </label>
            <input
              v-model="state.identifier"
              type="text"
              class="input input-sm input-bordered w-full"
            />
          </div>

          <div class="form-control w-full mt-0">
            <label class="label mt-0 pt-0" for="">
              <span class="label-text-alt">{{
                $t("message.premiseNumber")
              }}</span>
            </label>
            <input
              v-model="state.premiseNumber"
              type="text"
              class="input input-sm input-bordered w-full"
            />
          </div>

          <div class="form-control w-full mt-0">
            <label class="label mt-0 pt-0" for="">
              <span class="label-text-alt">{{ $t("message.floorArea") }}</span>
            </label>
            <input
              v-model="state.floorSpace"
              type="number"
              class="input input-sm input-bordered w-full"
            />
          </div>
        </div>

        <div class="flex flex-row gap-3 w-full mt-2">
          <div class="form-control w-full mt-0">
            <label class="label mt-0 pt-0" for="">
              <span class="label-text-alt">{{
                $t("message.isAccessible")
              }}</span>
            </label>
            <select
              v-model="state.selectedAccessibility"
              class="select select-sm w-full select-bordered"
              @change="updateAccessibility"
            >
              <option disabled selected>{{ $t("message.choose") }}</option>
              <option value="yes">{{ $t("message.yes") }}</option>
              <option value="no">{{ $t("message.no") }}</option>
            </select>
          </div>
        </div>

        <div
          class="flex flex-row gap-3 w-full border-b-2 border-secondary mt-4 font-bold"
        >
          <div class="form-control w-full mt-2">
            <label class="label mt-0 pt-0" for="">
              <span class="label-text">{{ $t("message.stairway") }}</span>
            </label>
          </div>
        </div>

        <div class="flex flex-row gap-3 w-full mt-2">
          <div class="form-control w-full mt-0">
            <label class="label mt-0 pt-0" for="">
              <span class="label-text-alt">{{ $t("message.stairwayId") }}</span>
            </label>
            <input
              v-model="state.stairwayIdentifier"
              type="text"
              class="input input-sm input-bordered w-full"
            />
          </div>

          <div class="form-control w-full mt-0">
            <label class="label mt-0 pt-0" for="">
              <span class="label-text-alt">{{
                $t("message.stairwayType")
              }}</span>
            </label>
            <OptionValueSelector
              v-model="state.stairwayType"
              type="STAIRWAY_TYPE"
            />
          </div>
        </div>

        <div class="flex flex-row gap-3 w-full mt-2">
          <div class="form-control w-full mt-0">
            <label class="label mt-0 pt-0" for="">
              <span class="label-text-alt">{{
                $t("message.isSmokeFree")
              }}</span>
            </label>
            <select
              v-model="state.selectedSmokeFree"
              class="select select-sm w-full select-bordered"
              @change="updateSmokeFree"
            >
              <option disabled selected>{{ $t("message.choose") }}</option>
              <option value="yes">{{ $t("message.yes") }}</option>
              <option value="no">{{ $t("message.no") }}</option>
            </select>
          </div>

          <div class="form-control w-full mt-0">
            <label class="label mt-0 pt-0" for="">
              <span class="label-text-alt">{{
                $t("message.isEscapeRoute")
              }}</span>
            </label>
            <select
              v-model="state.selectedFireRoute"
              class="select select-sm w-full select-bordered"
              @change="updateFireRoute"
            >
              <option disabled selected>{{ $t("message.choose") }}</option>
              <option value="yes">{{ $t("message.yes") }}</option>
              <option value="no">{{ $t("message.no") }}</option>
            </select>
          </div>
        </div>

        <div
          class="flex flex-row gap-3 w-full border-b-2 border-secondary mt-4 font-bold"
        >
          <div class="form-control w-full mt-2">
            <label class="label mt-0 pt-0" for="">
              <span class="label-text">{{ $t("message.elevator") }}</span>
            </label>
          </div>
        </div>

        <div class="flex flex-row gap-3 w-full mt-2">
          <div class="form-control w-full mt-0">
            <label class="label mt-0 pt-0" for="">
              <span class="label-text-alt">{{
                $t("message.numberOfPerson")
              }}</span>
            </label>
            <input
              v-model="state.elevatorNumberPersons"
              type="text"
              class="input input-sm input-bordered w-full"
            />
          </div>

          <div class="form-control w-full mt-0">
            <label class="label mt-0 pt-0" for="">
              <span class="label-text-alt">{{
                $t("message.loadCapacity")
              }}</span>
            </label>
            <input
              v-model="state.elevatorLoadCapacity"
              type="text"
              class="input input-sm input-bordered w-full"
            />
          </div>
        </div>

        <div class="flex flex-row gap-3 w-full mt-2">
          <div class="form-control w-full mt-0">
            <label class="label mt-0 pt-0" for="">
              <span class="label-text-alt">{{
                $t("message.elevatorType")
              }}</span>
            </label>
            <OptionValueSelector
              v-model="state.elevatorType"
              type="ELEVATOR_TYPE"
            />
          </div>

          <div class="form-control w-full mt-0">
            <label class="label mt-0 pt-0" for="">
              <span class="label-text-alt">{{
                $t("message.isEmergencyElevator")
              }}</span>
            </label>
            <select
              v-model="state.selectedEmergencyElevator"
              class="select select-sm w-full select-bordered"
              @change="updateEmergencyElevator"
            >
              <option disabled selected>{{ $t("message.choose") }}</option>
              <option value="yes">{{ $t("message.yes") }}</option>
              <option value="no">{{ $t("message.no") }}</option>
            </select>
          </div>
        </div>

        <div class="w-full mt-3">
          <button
            :disabled="!validation()"
            class="disabled:opacity-60 btn btn-sm bg-magenta text-white font-light text-xs border-none"
            @click="updatePremise"
          >
            {{ $t("message.save") }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import axios from "axios";
import toast from "@/misc/toast.js";
import { onMounted, reactive } from "vue";
import { useRoute } from "vue-router";
import store from "@/misc/vuex-store.js";
import { useI18n } from "vue-i18n";
import OptionValueSelector from "../../../../components/OptionValueSelector.vue";
import Utils from "../../../../misc/Utils";

const route = useRoute();
const buildingId = route.params.buildingId;

const props = defineProps(["params"]);

const { t } = useI18n();

const state = reactive({
  premiseById: null,
  partnerIds: [],
  selectedSmokeFree: "",
  selectedFireRoute: "",
  selectedEmergencyElevator: "",
  selectedAccessibility: "",
  function: null,
  cremClass: null,
  identifier: "",
  premiseNumber: "",
  floorSpace: null,
  stairwayIdentifier: "",
  stairwayType: null,
  smokeFree: null,
  fireRoute: null,
  elevatorNumberPersons: null,
  elevatorLoadCapacity: null,
  elevatorType: null,
  emergencyElevator: null,
  accessibility: null,
});

const getPartnerIds = async () => {
  const response = await Utils.fetchPartners();
  for (const element of response) {
    state.partnerIds.push(element.id);
  }
};

const fillPremise = () => {
  state.premiseById = store.getters.getPremiseById;
  console.log(state.premiseById);

  state.function = state.premiseById.basicData?.premiseFunction?.id;
  state.cremClass = state.premiseById.basicData?.cremClass?.id;
  state.identifier = state.premiseById.basicData?.identifier;
  state.premiseNumber = state.premiseById.basicData?.identifier;
  state.floorSpace = state.premiseById.basicData?.floorSpace;
  state.stairwayIdentifier = state.premiseById.basicData?.stairwayIdentifier;
  state.stairwayType = state.premiseById.basicData?.stairwayType;
  state.selectedSmokeFree = state.premiseById.basicData?.smokeFree ? "yes" : "no";
  state.selectedFireRoute = state.premiseById.basicData?.fireRoute ? "yes" : "no";
  state.elevatorNumberPersons = state.premiseById.basicData?.elevatorNumberPersons;
  state.elevatorLoadCapacity = state.premiseById.basicData?.elevatorLoadCapacity;
  state.elevatorType = state.premiseById.basicData?.elevatorType;
  state.selectedEmergencyElevator = state.premiseById.basicData?.emergencyElevator ? "yes" : "no";
  state.selectedAccessibility = state.premiseById.basicData?.accessibility ? "yes" : "no";
};

function validation() {
  const empty = !state.function || !state.identifier;

  if (empty) return false;

  return true;
}

//Jelenleg az összes partnert hozzárendelem minden helyiséghez.

async function updatePremise() {
  const uri =
    process.env.VUE_APP_FRONTENDV2_API_URL +
    `/rem/premise/${state.premiseById.id}`;
  let postBody = {
    buildingId: buildingId,
    partnerIds: state.partnerIds,
    basicData: {
      premiseFunction: state.function,
      cremClass: state.cremClass,
      identifier: state.identifier,
      premiseNumber: state.premiseNumber,
      floorSpace: state.floorSpace,
      stairwayIdentifier: state.stairwayIdentifier,
      stairwayType: state.stairwayType,
      smokeFree: state.smokeFree,
      fireRoute: state.fireRoute,
      elevatorNumberPersons: state.elevatorNumberPersons,
      elevatorLoadCapacity: state.elevatorLoadCapacity,
      elevatorType: state.elevatorType,
      emergencyElevator: state.emergencyElevator,
      accessibility: state.accessibility,
    },
  };

  try {
    await axios.patch(uri, postBody, {
      onTokenRefreshed: () => {
        updatePremise();
      },
    });
    hideSidebar();
    if (props.params && props.params.onSave) {
      props.params.onSave();
    }
    toast.success(t("message.successfulSave"));
  } catch (err) {
    console.log(err);
    toast.error(t("message.saveError"));
  }
}

const updateSmokeFree = () => {
  state.selectedSmokeFree === "yes"
    ? (state.smokeFree = 1)
    : (state.smokeFree = 0);
};

const updateFireRoute = () => {
  state.selectedFireRoute === "yes"
    ? (state.fireRoute = 1)
    : (state.fireRoute = 0);
};

const updateEmergencyElevator = () => {
  state.selectedEmergencyElevator === "yes"
    ? (state.emergencyElevator = 1)
    : (state.emergencyElevator = 0);
};

const updateAccessibility = () => {
  state.selectedAccessibility === "yes"
    ? (state.accessibility = 1)
    : (state.accessibility = 0);
};

const hideSidebar = () => {
  store.commit("hideSidebar");
};

onMounted(() => {
  getPartnerIds();
  fillPremise();
});
</script>

<style scoped></style>
