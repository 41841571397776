export default {
  basicData: [
    {
      title: "Funkció",
      value: "",
      valueLabel: "",
      model: "basicDataPremiseFunction",
      type: "option",
      searchable: true,
      optionValue: "PREMISE_FUNCTION",
    },
    {
      title: "CREM Besorolás",
      value: "",
      valueLabel: "",
      model: "basicDataCremClass",
      type: "option",
      optionValue: "CREM_CLASSIFICATION",
    },
    {
      title: "Azonosító (F Kód)",
      value: "",
      model: "basicDataIdentifier",
      type: "text",
    },
    {
      title: "Helyiségszám",
      value: "",
      model: "basicDataPremiseNumber",
      type: "number",
    },
    {
      title: "Négyzetméter",
      value: "",
      model: "basicDataFloorSpace",
      type: "number",
    },
    {
      title: "Akadálymentesített",
      value: "",
      model: "basicDataAccessibility",
      type: "checkbox",
    },
  ],
  staircaseData: [
    {
      title: "Lépcső azonsító",
      value: "",
      model: "basicDataStairwayIdentifier",
      type: "text",
    },
    {
      title: "Lépcső Típus",
      value: "",
      model: "basicDataStairwayType",
      type: "text",
    },
    {
      title: "Menekülési Útvonal",
      value: "",
      model: "basicDataFireRoute",
      type: "checkbox",
    },
    {
      title: "Füstmentes",
      value: "",
      model: "basicDataSmokeFree",
      type: "checkbox",
    },
  ],
  elevatorData: [
    {
      title: "Személyek száma",
      value: "",
      model: "basicDataElevatorNumberPersons",
      type: "text",
    },
    {
      title: "Lift Típus",
      value: "",
      model: "basicDataElevatorType",
      type: "text",
    },
    {
      title: "Terherbírás",
      value: "",
      model: "basicDataElevatorLoadCapacity",
      type: "number",
    },
    {
      title: "Mentő lift",
      value: "",
      model: "basicDataEmergencyElevator",
      type: "checkbox",
    },
  ],
  specialData: [
    {
      title: "Szintek",
      value: "",
      model: "floor",
      type: "number",
    },
    {
      title: "Bejutási megjegyzések",
      value: "",
      model: "entryNote",
      type: "text",
    },
    {
      title: "Szepa",
      value: "",
      model: "szepa",
      type: "text",
    },
    {
      title: "Burkolat",
      value: "",
      model: "floorCovering",
      type: "text",
    },
    {
      title: "Álpadló",
      value: "",
      model: "falseFloor",
      type: "checkbox",
    },
    {
      title: "Álmennyezet",
      value: "",
      model: "falseCeiling",
      type: "checkbox",
    },
    {
      title: "Személyzet",
      type: "label",
    },
    {
      title: "Dedikált Létszám",
      value: "",
      model: "numberDedicated",
      type: "number",
    },
    {
      title: "Ültethető Létszám",
      value: "",
      model: "numberCanBeSeated",
      type: "number",
    },
    {
      title: "Ültetett létszám",
      value: "",
      model: "numberSeated",
      type: "number",
    },
    {
      title: "Tűzvédelem",
      type: "label",
    },
    {
      title: "Tűzvédelmi besorolás",
      value: "",
      model: "fireProtectionClass",
      type: "text",
      optionValue: "PREMISE_FUNCTION",
    },
    {
      title: "Kiépített tűzvédelem",
      value: "",
      model: "builtInFireProtection",
      type: "text",
      optionValue: "PREMISE_FUNCTION",
    },

    {
      title: "Nyílászárók",
      type: "label",
    },
    {
      title: "Ajtó típusa",
      value: "",
      model: "doorType",
      type: "text",
    },
    {
      title: "Nyitható ablak",
      value: "",
      model: "openableWindow",
      type: "checkbox",
    },
    {
      title: "Ablak anyaga",
      value: "",
      model: "windowMaterial",
      type: "text",
      optionValue: "WINDOW_MATERIAL",
    },
    {
      title: "Ablak Mélység",
      value: "",
      model: "windowDepth",
      type: "number",
    },
    {
      title: "Ablak Magasság",
      value: "",
      model: "windowHeight",
      type: "number",
    },{
      title: "Ablak Méret",
      value: "",
      model: "windowSize",
      type: "text",
    },
    {
      title: "Műszaki adatok",
      type: "label",
    }, {
      title: "Minimum magasság",
      value: "",
      model: "minHeight",
      type: "text",
    },{
      title: "Maximum magasság",
      value: "",
      model: "maxHeight",
      type: "text",
    },{
      title: "Közös használatú",
      value: "",
      model: "sharedUse",
      type: "checkbox",
    },{
      title: "Hűthető",
      value: "",
      model: "canBeCooled",
      type: "checkbox",
    },{
      title: "Fűthető",
      value: "",
      model: "canBeHeated",
      type: "checkbox",
    }
  ],
  utilizationData: [
    {
      title: "Terület",
      value: "",
      model: "floorSpace",
      type: "number",
    },
    {
      title: "legalRelationship",
      value: "",
      model: "legalRelationship",
      type: "option",
      optionValue: "UTILIZATION_LEGAL_RELATIONSHIP",
    },
    {
      title: "legalRelationshipStart",
      value: "",
      model: "legalRelationshipStart",
      type: "date",
    },
    {
      title: "legalRelationshipEnd",
      value: "",
      model: "legalRelationshipEnd",
      type: "date",
    },
    {
      title: "partner",
      value: "",
      model: "partner",
      type: "optionapi",
      endPoint: process.env.VUE_APP_FRONTENDV2_API_URL + "/rem/partner",
      displayValue: "id",
    },
  ],
  legalsitData: [
    
    {
      title: "Ingatlan",
      value: "",
      model: "property",
      type: "optionapi",
      endPoint: process.env.VUE_APP_FRONTENDV2_API_URL + "/rem/property",
      displayValue: "id",
    },
    {
      title: "Típus",
      value: "",
      valueLabel: "",
      model: "legalSituationType",
      type: "option",
      optionValue: "LEGAL_SITUATION",
    },
    {
      title: "Jogi kapcsolat típusa",
      value: "",
      valueLabel: "",
      model: "legalRelationshipType",
      type: "option",
      optionValue: "LEGAL_RELATIONSHIP_TYPE",
    },
    {
      title: "Tulajdoni hányad",
      value: "",
      model: "ownership",
      type: "text",
    },
    {
      title: "legalRelationshipStart",
      value: "",
      model: "legalRelationshipStart",
      type: "date",
    },
    {
      title: "legalRelationshipEnd",
      value: "",
      model: "legalRelationshipEnd",
      type: "date",
    },
  ],
  editLegalsit:{
      property: 0,
      building: 0,
      legalSituationType: {
        id:"",
        value: ""
      },
      legalRelationshipType: {
        id:"",
        value: ""
      },
      ownership: "",
      legalRelationshipStart: "",
      legalRelationshipEnd: ""
    
  },
  partnerData: [
    {
      title: "name",
      value: "",
      model: "name",
      type: "text",
    },
    {
      title: "Fő típus",
      value: "",
      model: "partnerMainType",
      type: "option",
      optionValue: "PARTNER_MAIN_TYPE",
    },
    {
      title: "Altípus",
      value: "",
      model: "partnerSubType",
      type: "option",
      optionValue: "PARTNER_SUB_TYPE",
      onchangeMethod : (options, optionsRepo, value) => {
        options.value = optionsRepo.filter(elem => (elem.parent) == value);
      }
    },
    {
      title: "Adóazonosító",
      value: "",
      model: "taxId",
      type: "text",
    },
    {
      title: "Adószám",
      value: "",
      model: "taxNumber",
      type: "text",
    }
  ],
};
