import Axios from "@/misc/axios";
import toast from "@/misc/toast.js";
import { watchEffect /*onMounted, reactive,*/ } from "vue";

export default {
  // setup scripts

  asyncGet: async function (state, premiseId, formData) {
    const response = await Axios.get(
      process.env.VUE_APP_FRONTENDV2_API_URL + "/rem/premise/" + premiseId,
      {
        onTokenRefreshed: () => {
          this.asyncGet();
        },
      }
    );
    if (response && response.data) {
      //console.log(response.data)
      state.value.orig = response.data;
      
      this.getOptions(
        response.data,
        state.value.basicData,
        formData.basicData,
        true
      );
      this.getOptions(
        response.data,
        state.value.staircaseData,
        formData.basicData,
        true
      );
      this.getOptions(
        response.data,
        state.value.elevatorData,
        formData.elevatorData,
        true
      );
      watchEffect(() => {
        //console.log('change2')
        if (state.value.basicData[0]) {
          state.value.isStaire = state.value.basicData[0].value == 216;
          state.value.isElevator = state.value.basicData[0].value == 217;
        }
      });

      watchEffect(() => {
        if (state.value.partnerData[1].value) {
          state.value.partnerData[2].value =
            "CHANGECODE###" + state.value.partnerData[1].value;
        }
      });

      watchEffect(() => {
        if (state.value.documentSetup.documentType) {
          state.value.documentSetup.documentSubType =
            "CHANGECODE###" + state.value.documentSetup.documentType;
        }
      });
    }

    await Axios.get(
      process.env.VUE_APP_FRONTENDV2_API_URL +
        "/rem/premise/" +
        premiseId +
        "/special-data",
      {
        onTokenRefreshed: () => {
          this.asyncGet();
        },
      }
    ).then((resp) => {
      
      if (resp && resp.data) {
        this.getOptions(
          resp.data,
          state.value.specialData,
          formData.specialData,
          true
        );
      }
    });

    await Axios.get(
      process.env.VUE_APP_FRONTENDV2_API_URL +
        "/rem/premise/" +
        premiseId +
        "/partners",
      {
        onTokenRefreshed: () => {
          this.asyncGet();
        },
      }
    ).then((resp) => {
      
      if (resp && resp.data) {
        resp.data.content.forEach((e) => {
          state.value.partnerList.push(e.id)
        })
      }
    });
  },
  getOptions: async function (
    source,
    target,
    schema,
    isThereWeirdFormattingForOption
  ) {
    schema.forEach((e) => {
      if (e.type == "label") {
        target.push(e);
        return;
      }

      const hermes = e;
      if (hermes.type == "option" && isThereWeirdFormattingForOption == true) {
        if (source[e.model + 'Id']) {
          hermes.value = source[e.model + 'Id'];
          hermes.valueLabel = source[e.model + 'Value'];
        }
      } else {
        hermes.value = source[e.model];
      }
      target.push(hermes);
    });
  },

  //savers
  asyncSavePremise: async function (state, premiseId, t) {
    state.value.basicData.forEach((e) => {
      if (e.type == "label") return;
      if(e.type == "option") {
        state.value.orig.basicData[e.model + 'Id'] = e.value;
      }else{
        state.value.orig.basicData[e.model + 'Id'] = e.value;
      } 
    });
    state.value.staircaseData.forEach((e) => {
      if (e.type == "label") return;
      state.value.orig.basicData[e.model] = e.value;
    });
    state.value.elevatorData.forEach((e) => {
      if (e.type == "label") return;
      state.value.orig.basicData[e.model] = e.value;
    });

    const hermes = {
      buildingId: state.value.orig.building.id,
      basicData: state.value.orig.basicData,
      partnerIds: state.value.orig.partners,
      //coolingHeatingDevice: state.value.orig.coolingHeatingDevice
    };

    Axios.patch(
      process.env.VUE_APP_FRONTENDV2_API_URL + "/rem/premise/" + premiseId,
      hermes,
      {
        onTokenRefreshed: () => {
          this.asyncSavePremise(state, premiseId, t);
        },
      }
    ).then(function (resp) {
      if (resp) {
        if (resp.status == 200) {
          toast.success(t("message.successfulSave"));
        } else {
          toast.error(t("message.notSuccessfulSave"));
        }
        //console.log(response);
      } else {
        toast.error(t("message.notSuccessfulSave"));
      }
    });
  },
  asyncSavePremiseSpecialData: async function (state, premiseId, t) {
    state.value.specialData.forEach((e) => {
      if (e.type == "label") return;
      state.value.orig.specialData[e.model] = e.value;
    });

    const hermes = state.value.orig.specialData;

    Axios.patch(
      process.env.VUE_APP_FRONTENDV2_API_URL +
        "/rem/premise/" +
        premiseId +
        "/special-data",
      hermes,
      {
        onTokenRefreshed: () => {
          this.asyncSavePremise(state, premiseId, t);
        },
      }
    ).then(function (resp) {
      if (resp) {
        if (resp.status == 200) {
          toast.success(t("message.successfulSave"));
        } else {
          toast.error(t("message.notSuccessfulSave"));
        }
        //console.log(response);
      } else {
        toast.error(t("message.notSuccessfulSave"));
      }
    });
  },
  uploadFile: async function (state, documentFile, premiseId, t) {
    const formData = new FormData();
    formData.append("mainType", state.value.documentSetup.documentType);
    formData.append("subType", state.value.documentSetup.documentSubType);
    formData.append("document", documentFile.value.files[0]);

    await Axios.post(
      process.env.VUE_APP_FRONTENDV2_API_URL +
        `/rem/premise/${premiseId}/document`,
      formData
    ).then((resp) => {
      if (resp) {
        if (resp.status == 200) {
          toast.success(t("message.successfulSave"));
        } else {
          toast.error(t("message.notSuccessfulSave"));
        }
      } else {
        toast.error(t("message.notSuccessfulSave"));
      }
    });
  },
  saveLegalsit: async function (state, t) {
    const hermes = {
      property: 0,
      legalSituationType: "",
      legalRelationshipType: "",
      ownership: "",
      legalRelationshipStart: "",
      legalRelationshipEnd: "",
    };

    state.value.legalsitData.forEach((e) => {
      hermes[e.model] = e.value;
    });

    if (state.value.legalsitId != 0) {
      await Axios.patch(
        process.env.VUE_APP_FRONTENDV2_API_URL +
          "/rem/legal-situation/" +
          state.value.legalsitId,
        hermes,
        {
          onTokenRefreshed: () => {
            this.saveLegalsit(state, t);
          },
        }
      ).then((resp) => {
        if (resp) {
          if (resp.status == 201) {
            toast.success(t("message.successfulSave"));
            state.value.legalsitData.forEach((e) => {
              e.value = "";
            });
            state.value.tab = 2;
            state.value.legalsitId = 0;
          } else {
            toast.error(t("message.notSuccessfulSave"));
          }
        } else {
          toast.error(t("message.notSuccessfulSave"));
        }
      });
    } else {
      await Axios.post(
        process.env.VUE_APP_FRONTENDV2_API_URL + "/rem/legal-situation",
        hermes,
        {
          onTokenRefreshed: () => {
            this.saveLegalsit(state, t);
          },
        }
      ).then((resp) => {
        if (resp) {
          if (resp.status == 201) {
            toast.success(t("message.successfulSave"));
            state.value.legalsitData.forEach((e) => {
              e.value = "";
            });
            state.value.tab = 2;
            state.value.legalsitId = 0;
          } else {
            toast.error(t("message.notSuccessfulSave"));
          }
        } else {
          toast.error(t("message.notSuccessfulSave"));
        }
      });
    }
  },

  saveUtilization: async function (state, premiseId, t) {
    const hermes = {
      premiseId: premiseId,
      floorSpace: "",
      legalRelationship: "",
      legalRelationshipStart: "",
      legalRelationshipEnd: "",
      partnerId: "",
    };

    state.value.utilizationData.forEach((e) => {
      hermes[e.model] = e.value;
    });

    console.log(hermes);
    if (state.value.utilizationId != 0) {
      hermes.id = state.value.utilizationId;
      await Axios.patch(
        process.env.VUE_APP_FRONTENDV2_API_URL +
          "/rem/utilization/" +
          state.value.utilizationId,
        hermes,
        {
          onTokenRefreshed: () => {
            this.saveUtilization(state, premiseId, t);
          },
        }
      ).then((resp) => {
        if (resp) {
          if (resp.status == 200) {
            toast.success(t("message.successfulSave"));
            state.value.utilizationData.forEach((e) => {
              e.value = "";
            });
            state.value.tab = 3;
            state.value.utilizationId = 0;
          } else {
            toast.error(t("message.notSuccessfulSave"));
          }
        } else {
          toast.error(t("message.notSuccessfulSave"));
        }
      });
    } else {
      await Axios.post(
        process.env.VUE_APP_FRONTENDV2_API_URL + "/rem/utilization",
        hermes,
        {
          onTokenRefreshed: () => {
            this.saveUtilization(state, premiseId, t);
          },
        }
      ).then((resp) => {
        if (resp) {
          if (resp.status == 201) {
            toast.success(t("message.successfulSave"));
            state.value.utilizationData.forEach((e) => {
              e.value = "";
            });
            state.value.tab = 3;
            state.value.utilizationId = 0;
          } else {
            toast.error(t("message.notSuccessfulSave"));
          }
        } else {
          toast.error(t("message.notSuccessfulSave"));
        }
      });
    }
  },
  savePartner: async function (state, premiseId, t) {
    const hermes = {
      partnerMainType: 0,
      partnerSubType: 0,
      name: "",
      taxId: "",
      taxNumber: "",
      partnerAddresses: [],
    };

    state.value.partnerData.forEach((e) => {
      hermes[e.model] = e.value;
    });

    console.log(hermes);
    if (state.value.partnerId != 0) {
      hermes.id = state.value.partnerId;
      await Axios.patch(
        process.env.VUE_APP_FRONTENDV2_API_URL +
          "/rem/partner/" +
          state.value.partnerId,
        hermes,
        {
          onTokenRefreshed: () => {
            this.savePartner(state, premiseId, t);
          },
        }
      ).then((resp) => {
        if (resp) {
          if (resp.status == 200) {
            toast.success(t("message.successfulSave"));
            state.value.partnerData.forEach((e) => {
              e.value = "";
            });

            state.value.tab = 9;
            state.value.partnerId = 0;
          } else {
            toast.error(t("message.notSuccessfulSave"));
          }
        } else {
          toast.error(t("message.notSuccessfulSave"));
        }
      });
    } else {
      await Axios.post(
        process.env.VUE_APP_FRONTENDV2_API_URL + "/rem/partner",
        hermes,
        {
          onTokenRefreshed: () => {
            this.savePartner(state, premiseId, t);
          },
        }
      ).then((resp) => {
        if (resp) {
          if (resp.status == 201) {
            state.value.partnerData.forEach((e) => {
              e.value = "";
            });
            const hermesAdd = {
              partnerIds: [],
            };
            state.value.orig.partners.forEach((e) => {
              hermesAdd.partnerIds.push(e.id);
            });
            hermesAdd.partnerIds.push(resp.data.id);

            Axios.patch(
              process.env.VUE_APP_FRONTENDV2_API_URL +
                "/rem/premise/" +
                premiseId,
              hermesAdd,
              {
                onTokenRefreshed: () => {
                  this.savePartner(state, premiseId, t);
                },
              }
            ).then(function (resp) {
              if (resp) {
                if (resp.status == 200) {
                  toast.success(t("message.successfulSave"));
                  state.value.tab = 9;
                  state.value.partnerId = 0;
                } else {
                  toast.error(t("message.notSuccessfulSave"));
                }
                //console.log(response);
              } else {
                toast.error(t("message.notSuccessfulSave"));
              }
            });
          } else {
            toast.error(t("message.notSuccessfulSave"));
          }
        } else {
          toast.error(t("message.notSuccessfulSave"));
        }
      });
    }
  },
};
