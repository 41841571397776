<template>
  <div class="flex flex-row items-center">
    <label class="inline-flex items-center space-x-2 mr-2">
      <input @change="() => { onTick(true) }" :name="'checkbox_' + this.rowid" :checked="this.data === true" class="form-radio is-basic h-5 w-5 !rounded-full border-slate-400/70 checked:border-primary checked:bg-primary hover:border-primary focus:border-primary dark:border-navy-400 dark:checked:border-accent dark:checked:bg-accent dark:hover:border-accent dark:focus:border-accent" type="radio">
      <span>Megjelent</span>
    </label>

    <label class="inline-flex items-center space-x-2">
      <input @change="() => { onTick(false) }" :name="'checkbox_' + this.rowid" :checked="this.data === false" class="form-radio is-basic h-5 w-5 !rounded-full border-slate-400/70 checked:border-primary checked:bg-primary hover:border-primary focus:border-primary dark:border-navy-400 dark:checked:border-accent dark:checked:bg-accent dark:hover:border-accent dark:focus:border-accent" type="radio">
      <span>Nem jelent meg </span>
    </label>
  </div>
</template>

<script>

export default {
  name: "RVGBoolean",
  components: {
  },
  props: ['data', 'config', 'rowid', 'record'],
  computed: {
  },
  mounted() {
  },
  data() {
    return {
      isChecked: this.data
    }
  },
  methods: {
    onTick(value) {
      if(this.config && this.config.formatter && this.config.formatter.onTick) {
        this.config.formatter.onTick(this.rowid, this.record, value);
      }
    }
  }
};
</script>

<style scoped>
  .icon {
    font-size: 28px;
  }
</style>
