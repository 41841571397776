<template>
  <div class="flex flex-row items-center justify-center text-center m-auto">
    <button :disabled="!data" @click="() => { modalShown = true }" data-test="openButton" class="btn h-9 w-9 !rounded-full bg-slate-150 !p-0 font-medium text-slate-800 hover:bg-slate-200 hover:shadow-lg hover:shadow-slate-200/50 focus:bg-slate-200 focus:shadow-lg focus:shadow-slate-200/50 active:bg-slate-200/80 dark:bg-navy-500 dark:text-navy-50 dark:hover:bg-navy-450 dark:hover:shadow-navy-450/50 dark:focus:bg-navy-450 disabled:pointer-events-none disabled:select-none disabled:opacity-40 dark:focus:shadow-navy-450/50 dark:active:bg-navy-450/90" title="Megnyitás">
      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-4 h-4">
        <path stroke-linecap="round" stroke-linejoin="round" d="M14.25 9.75 16.5 12l-2.25 2.25m-4.5 0L7.5 12l2.25-2.25M6 20.25h12A2.25 2.25 0 0 0 20.25 18V6A2.25 2.25 0 0 0 18 3.75H6A2.25 2.25 0 0 0 3.75 6v12A2.25 2.25 0 0 0 6 20.25Z" />
      </svg>
    </button>
    <CustomContentModal :show="modalShown" :title="config.formatter.title ? config.formatter.title : getLang('untitled')" cancelButtonTitle="" okButtonTitle="" :disableButtons="true" :close="() => { modalShown = false }">
      <template #content>
        <div v-if="data" class="w-full overflow-hidden whitespace-normal">
          <vue-json-pretty :showLine="false" :data="parsedData" />
        </div>
        <div v-else class="w-full overflow-hidden whitespace-normal italic">
          Nincs megadva
        </div>
      </template>
    </CustomContentModal>
  </div>
</template>

<script>
import CustomContentModal from '../Components/CustomContentModal.vue'
import VueJsonPretty from 'vue-json-pretty';
import 'vue-json-pretty/lib/styles.css';
import l from '../Helpers/lang.js'

export default {
  name: "RVGJsonViewer",
  components: {
    CustomContentModal,
    VueJsonPretty
  },
  props: ['data', 'config'],
  mounted() {
  },
  data() {
    return {
      modalShown: false
    }
  },
  computed: {
    parsedData() {
      try {
        return JSON.parse(this.data);
      } catch (e) {
        return this.data;
      }
    }
  },
  methods: {
    getLang(key) {
      return l(key)
    }
  }
};
</script>

<style scoped>
</style>
