let uniqueField = "legalsitGrid";

let l = (key) => {
  // @TODO: Implement localization
  return key;
};

export default (/*premiseId*/state) => {
  return {
    endpoint: process.env.VUE_APP_FRONTENDV2_API_URL + `/rem/legal-situation`,
    idkey: "id",
    defaultOrderKey: "id",
    defaultOrderDirection: "desc",
    orderLocal: false,
    rootkey: "content",
    pagination: {
      gridUniqueId: uniqueField,
      active: true,
      page: 0,
      size: 10,
      // possiblePageSizes: [10, 20, 50, 100],
    },
    filtering: {
      active: true,
      //local: false,
      //premiseId: premiseId,
      //simpleHelpText: l('rvg-simple-filter-help-text'),
    },
    bulkOperation: {
      active: false,
    },
    columnSelector: {
      active: true,
      gridUniqueId: uniqueField,
      mode: "server", // server or local/null
      serverGridKey: "worksGridColumns",
    },
    xlsxExport: {
      active: true,
    },
    refreshable: {
      manualActive: true,
      autoActive: true,
      autoCanBeDisabled: true,
      gridUniqueId: uniqueField,
      autoValues: [
        {
          key: "30sec",
          refreshInterval: 30,
          title: l("rvg-automatic-list-refresh-30-sec"),
          default: true,
        },
        {
          key: "1min",
          refreshInterval: 60,
          title: l("rvg-automatic-list-refresh-1-min"),
        },
        {
          key: "5min",
          refreshInterval: 300,
          title: l("rvg-automatic-list-refresh-5-min"),
        },
      ],
    },
    mapping: {
      $operations: {
        title: "",
        visible: true,
        filterable: false,
        selectable: false,
        orderable: false,
        width: "50px",
        formatter: {
          type: "Operations",
          config: {
            buttons: (vm, callMeToRefreshTheGrid, rowid, record) => {
              var buttons = [];

              buttons.push({
                title: "",
                tooltip: "Edit property",
                icon: '<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-4"><path stroke-linecap="round" stroke-linejoin="round" d="M12 3L2 12H5V20H10V14H14V15.11L19.43 9.68L12 3M21.04 11.14C20.9 11.14 20.76 11.2 20.65 11.3L19.65 12.3L21.7 14.35L22.7 13.35C22.91 13.14 22.91 12.79 22.7 12.58L21.42 11.3C21.32 11.2 21.18 11.14 21.04 11.14M19.06 12.88L13 18.94V21H15.06L21.11 14.93L19.06 12.88Z" /></svg>',
                classList: "btn btn-secondary btn-circle btn-sm hover:!opacity-80",
                testValueAttribute: "propertyEditButton",
                dropdowned: false,
                //disabled: record.endDate ? false : true,
                onClick: () => {
                  console.log(record.id);
                  state.value.legalsitId = record.id;
                  state.value.legalsitData.forEach((e) =>{
                    if(e.model == "building"){
                      e.value = record.buildingId
                      e.valueLabel = record.buildingId
                    }else if(e.model == "property"){
                      e.value = record.propertyId
                      e.valueLabel = record.propertyId
                    }else{
                      e.value = record[e.model]
                    }

                    if(e.type == "option"){
                      e.value = record[e.model + "Id"]
                      e.valueLabel = record[e.model + "Value"]
                    }

                  })
                  state.value.tab = 8;
                },
              });

              return buttons;
            },
          },
        },
      },
      id: {
        title: "ID",
        visible: true,
        selectable: false, // non-optional, must be selected
        filterable: {
          active: true,
          type: "Text",
        },
        exportable: true,
        orderable: true,
      },
      property: {
        title: "Ingatlan",
        visible: true,
        selectable: true, // non-optional, must be selected
        filterable: {
          active: false,
          type: "Text",
        },
        exportable: true,
        orderable: false,
        middleware: (value, record) => {
          return record.propertyId ? record.propertyId : "N/A";
        },
      },
      building: {
        title: "Épület",
        visible: true,
        selectable: true, // non-optional, must be selected
        filterable: {
          active: false,
          type: "Text",
        },
        exportable: true,
        orderable: false,
        middleware: (value, record) => {
          return record.buildingId ? record.buildingId : "N/A";
        },
      },
      legalRelationshipTypeValue: {
        title: "Jogi Kapcsolat",
        visible: true,
        selectable: true, // non-optional, must be selected
        filterable: {
          active: false,
          type: "Text",
        },
        exportable: true,
        orderable: false,
        middleware: (value, record) => {
          return record.legalRelationshipTypeValue
            ? record.legalRelationshipTypeValue
            : "N/A";
        },
      },
      legalSituationTypeValue: {
        title: "Jogi Helyzet",
        visible: true,
        selectable: true, // non-optional, must be selected
        filterable: {
          active: false,
          type: "Text",
        },
        exportable: true,
        orderable: false,
        middleware: (value, record) => {
          return record.legalSituationTypeValue
            ? record.legalSituationTypeValue
            : "N/A";
        },
      },
      legalRelationshipStart: {
        title: "Kezdet",
        visible: true,
        selectable: true, // non-optional, must be selected
        filterable: {
          active: false,
          type: "Text",
        },
        exportable: true,
        orderable: false,
        middleware: (value, record) => {
          return record.legalRelationshipStart
            ? new Date(record.legalRelationshipStart).toLocaleString()
            : "N/A";
        },
      },
      legalRelationshipEnd: {
        title: "Kapcsolat vége",
        visible: true,
        selectable: true, // non-optional, must be selected
        filterable: {
          active: false,
          type: "Text",
        },
        exportable: true,
        orderable: false,
        middleware: (value, record) => {
          return record.legalRelationshipEnd ? new Date(record.legalRelationshipEnd).toLocaleString() : "N/A";
        },
      },
    },
  };
};
