let uniqueField = 'partnersGrid';

export default (t, editPartnerFn, addNewContactFn, ListContactsFn) => {
  return {
    endpoint: process.env.VUE_APP_FRONTENDV2_API_URL + "/rem/partner",
    idkey: "id",
    defaultOrderKey: 'id',
    defaultOrderDirection: 'desc',
    orderLocal: false,
    rootkey: "content",
    pagination: {
      gridUniqueId: uniqueField,
      active: true,
      page: 0,
      size: 10,
      // possiblePageSizes: [10, 20, 50, 100]
    },
    filtering: {
      active: true,
      local: false,
      //simple: true,
      //simpleHelpText: l('rvg-simple-filter-help-text'),
    },
    bulkOperation: {
      active: false
    },
    columnSelector: {
      active: true,
      gridUniqueId: uniqueField,
      mode: 'server', // server or local/null
      serverGridKey: 'worksGridColumns'
    },
    xlsxExport: {
      active: true
    },
    refreshable: {
      manualActive: true,
      autoActive: true,
      autoCanBeDisabled: true,
      gridUniqueId: uniqueField,
      autoValues: [{
        key: '30sec',
        refreshInterval: 30,
        title: t('message.rvg-automatic-list-refresh-30-sec'),
        default: true
      }, {
        key: '1min',
        refreshInterval: 60,
        title: t('message.rvg-automatic-list-refresh-1-min'),
      }, {
        key: '5min',
        refreshInterval: 300,
        title: t('message.rvg-automatic-list-refresh-5-min'),
      }]
    },
    mapping: {
      "$operations": {
        title: "",
        visible: true,
        filterable: false,
        selectable: false,
        orderable: false,
        width: '50px',
        formatter: {
          type: 'Operations',
          config: {
            buttons: (vm, callMeToRefreshTheGrid, rowid, record) => {
              var buttons = [];

              buttons.push({
                title: '',
                tooltip: 'Edit partner',
                icon: '<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-4"><path stroke-linecap="round" stroke-linejoin="round" d="M12 3L2 12H5V20H10V14H14V15.11L19.43 9.68L12 3M21.04 11.14C20.9 11.14 20.76 11.2 20.65 11.3L19.65 12.3L21.7 14.35L22.7 13.35C22.91 13.14 22.91 12.79 22.7 12.58L21.42 11.3C21.32 11.2 21.18 11.14 21.04 11.14M19.06 12.88L13 18.94V21H15.06L21.11 14.93L19.06 12.88Z" /></svg>',
                classList: 'btn btn-secondary btn-sm hover:!opacity-80 btn-circle',
                testValueAttribute: 'partnerEditButton',
                dropdowned: false,
                //disabled: record.endDate ? false : true,
                onClick: () => {
                  editPartnerFn(record.id)
                }
              })

              buttons.push({
                title: '',
                tooltip: 'Add new contact',
                icon: '<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-4"><path stroke-linecap="round" stroke-linejoin="round" d="M19,13H13V19H11V13H5V11H11V5H13V11H19V13Z" /></svg>',
                classList: 'btn btn-secondary btn-sm hover:!opacity-80 btn-circle',
                testValueAttribute: 'addContactButton',
                dropdowned: false,
                //disabled: record.endDate ? false : true,
                onClick: () => {
                  addNewContactFn(record.id)
                }
              })

              buttons.push({
                title: '',
                tooltip: 'List contacts',
                icon: '<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-4"><path stroke-linecap="round" stroke-linejoin="round" d="M12,5.5A3.5,3.5 0 0,1 15.5,9A3.5,3.5 0 0,1 12,12.5A3.5,3.5 0 0,1 8.5,9A3.5,3.5 0 0,1 12,5.5M5,8C5.56,8 6.08,8.15 6.53,8.42C6.38,9.85 6.8,11.27 7.66,12.38C7.16,13.34 6.16,14 5,14A3,3 0 0,1 2,11A3,3 0 0,1 5,8M19,8A3,3 0 0,1 22,11A3,3 0 0,1 19,14C17.84,14 16.84,13.34 16.34,12.38C17.2,11.27 17.62,9.85 17.47,8.42C17.92,8.15 18.44,8 19,8M5.5,18.25C5.5,16.18 8.41,14.5 12,14.5C15.59,14.5 18.5,16.18 18.5,18.25V20H5.5V18.25M0,20V18.5C0,17.11 1.89,15.94 4.45,15.6C3.86,16.28 3.5,17.22 3.5,18.25V20H0M24,20H20.5V18.25C20.5,17.22 20.14,16.28 19.55,15.6C22.11,15.94 24,17.11 24,18.5V20Z" /></svg>',
                classList: 'btn btn-secondary btn-sm hover:!opacity-80 btn-circle',
                testValueAttribute: 'listContactButton',
                dropdowned: false,
                //disabled: record.endDate ? false : true,
                onClick: () => {
                  ListContactsFn(record.id, record.name)
                }
              })

              return buttons;
            }
          }
        }
      },
      "id": {
        title: "ID",
        visible: false,
        selectable: false, // non-optional, must be selected
        filterable: {
          active: true,
          type: 'Text'
        },
        exportable: true,
        orderable: true,
      },
      "partner.name": {
        title: t("message.name"),
        visible: true,
        selectable: true, // non-optional, must be selected
        filterable: {
          active: false,
          type: 'Text'
        },
        exportable: true,
        orderable: false,
        middleware: (value, record) => {
          return record.name ? record.name : 'N/A'
        }
      },
      "partner.mainType": {
        title: t("message.mainType"),
        visible: true,
        selectable: true, // non-optional, must be selected
        filterable: {
          active: false,
          type: 'Text'
        },
        exportable: true,
        orderable: false,
        middleware: (value, record) => {    
          return record.partnerMainType?.value ? record.partnerMainType?.value : 'N/A'
        },             
      },
      "partner.subType": {
        title: t("message.subType"),
        visible: true,
        selectable: true, // non-optional, must be selected
        filterable: {
          active: false,
          type: 'Text'
        },
        exportable: true,
        orderable: false,
        middleware: (value, record) => {    
          return record.partnerSubType?.value ? record.partnerSubType?.value : 'N/A'
        },             
      },
    }
  }
}
