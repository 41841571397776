<template>
    <div v-html="content"></div>
</template>
<script setup>
    import { computed } from 'vue';
    import { defineProps } from 'vue';
    import svgStore from '@/misc/svgStorage';
    
    const props = defineProps({
        svgKey: {
            type: String,
            required: true,
        },
    });
    const content = computed(() => {
        return svgStore.getSvg(props.svgKey);
    });
</script>