<template>
  <div>
    <div class="space-y-6">
      <div class="flex flex-row justify-between">
        <h2 class="text-xl uppercase text-[#c6c6c6] font-bold">
          {{ $t("message.obligations") }}
        </h2>
        <!--A kommentelt kód direkt maradt a fájlban, végpontok létrehozásával/változtatásával szükség lesz majd rájuk-->
        <!-- <button
          @click="
            store.commit('showSidebar', {
              type: 'addNewObligation',
              params: {
                onSave: () => {
                  fetchObligations();
                },
              },
            })
          "
          class="whitespace-nowrap rounded-md text-sm font-medium transition-colors focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:pointer-events-none disabled:opacity-50 bg-secondary text-nrgGray shadow hover:bg-nrgGray hover:text-secondary h-9 px-4 py-2 font-bold uppercase"
        >
          {{ $t("message.addNewObligation") }}
        </button> -->
      </div>
      <RobberVueGrid
        v-if="!state.forceRefresh"
        ref="propObligationsGrid"
        :config="propObligationsGridConfigComputed"
      />
      <!-- <input
        type="checkbox"
        class="modal-toggle"
        v-model="state.showRemoveConfirmation"
      />
      <div class="modal">
        <div class="modal-box">
          <h3
            class="text-lg text-left font-normal hover:text-gray-100 text-gray-100"
          >
            {{$t("message.deleteObligationConfirm")}}
          </h3>
          <div class="modal-action">
            <label
              class="btn btn-sm text-white font-light text-xs"
              @click="
                () => {
                  state.showRemoveConfirmation = false;
                }
              "
              >{{ $t("message.deny") }}</label
            >
            <label
              class="btn btn-sm bg-magenta text-white font-light text-xs"
              @click="
                () => {
                  deleteBuilding(state.rvgBuildingId);
                  state.showRemoveConfirmation = false;
                }
              "
              >{{ $t("message.confirm") }}</label
            >
          </div>
        </div>
      </div> -->
    </div>
  </div>
</template>

<script setup>
import { computed, reactive, watch } from "vue";
import { useI18n } from "vue-i18n";
import { useRoute } from "vue-router";
// import Axios from "@/misc/axios.js";
import propObligationsGridConfig from "@/misc/gridConfigs/propObligationsGridConfig.js";
import RobberVueGrid from "../../components/RVG/RobberVueGrid.vue";

const { t } = useI18n();
const route = useRoute();
const propertyId = route.params.propertyId;

const propObligationsGridConfigComputed = computed(() => {
  return propObligationsGridConfig(t, propertyId);
});

const state = reactive({
  obligations: [],
  forceRefresh: false,
});

// const fetchObligations = async () => {
//   const uri =
//     process.env.VUE_APP_FRONTENDV2_API_URL +
//     `/rem/property/${propertyId}/obligations`;
//   try {
//     const response = await Axios.get(uri, {
//       onTokenRefreshed: () => {
//         fetchObligations();
//       },
//     });

//     state.obligations = response.content;
//   } catch (error) {
//     console.log(error);
//   }
// };

const refreshGrid = () => {
  state.forceRefresh = true;
  setTimeout(() => {
    state.forceRefresh = false;
  }, 100);
};

watch(
  () => state.obligations,
  () => {
    refreshGrid();
  }
);
</script>

<style scoped></style>
