<template>
  <div class="flex flex-row gap-2 my-2 ">
    <div class="w-1/2">
      <label for="selectorOperationField">Művelet:</label>
      <select @change="changeValue" v-model="operation" class="form-select mt-1.5 w-full rounded-lg border border-slate-300 bg-white px-3 py-2 hover:border-slate-400 focus:border-primary dark:border-navy-450 dark:bg-navy-700 dark:hover:border-navy-400 dark:focus:border-accent">
        <option v-for="(option, key) in possibleOperations" :value="key" :key="key">{{ option }}</option>
      </select>
    </div>
    <div class="w-1/2">
      <label for="selectorNumberValue">Érték:</label>
      <input id="selectorNumberValue" :state="validation" v-model="currentValue" type="number" :min="(!isNaN(config.min) ? config.min : '')" :max="(!isNaN(config.max) ? config.max : '')" placeholder="Szűrőfeltétel beírása..." @change="changeValue" class=" mt-1.5 form-input peer w-full rounded-lg border border-slate-300 bg-transparent px-3 py-2 placeholder:text-slate-400/70 hover:border-slate-400 focus:border-primary dark:border-navy-450 dark:hover:border-navy-400 dark:focus:border-accent" :class="[{'!border-error': !validation}]" />
    </div>
  </div>
</template>

<script>

import Config from './RVGFilterableNumber.config.js'

export default {
  name: "RVGFilterableNumber",
  components: {
  },
  data() {
    return {
      operation: 'eq',
      currentValue: null
    }
  },
  computed: {
    possibleOperations() {
      return Config.possibleOperations()
    },
    validation() {
      return this.currentValue !== null && this.currentValue !== '' && !isNaN(this.currentValue)
    }
  },
  props: ['data', 'config', 'id'],
  methods: {
    changeValue() {
      this.$emit('changeValue', this.id, {
        field: this.id,
        filterKey: this.config.filterKey ? this.config.filterKey : this.id,
        type: 'number',
        operation: this.operation,
        value: this.currentValue,
        isValid: this.validation,
        textual: Config.getTextual(this.operation, this.currentValue)
      })
    }
  }
};
</script>

<style scoped>
  label {
    float: left;
  }

  .pull-left {
    float: left;
    text-align: left;
  }
</style>
