<template>
  <div class="h-full overflow-y-auto w-full">
    <div
      class="mt-5 mx-5 flex flex-row grow border-b-0 border-base-300 justify-between"
    >
      <div class="flex flex-row items-center gap-2 justify-center">
        <h2
          class="text-base font-sans font-semibold uppercase text-secondary flex flex-row items-center gap-2 grow"
        >
          <span class="text-secondary">{{ $t("message.addNewMeter") }}</span>
        </h2>
      </div>
      <a
        href="javascript:void(null)"
        class="hover:text-secondary"
        @click="hideSidebar"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke-width="1.5"
          stroke="currentColor"
          class="w-6 h-6"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            d="M6 18L18 6M6 6l12 12"
          />
        </svg>
      </a>
    </div>

    <div class="px-5 pb-5">
      <div class="flex flex-col items-center justify-center gap-2 w-full">
        <div class="flex flex-row gap-3 w-full mt-2">
          <div class="form-control w-full mt-0">
            <label class="label mt-0 pt-0" for="">
              <span class="label-text-alt">Gist</span>
            </label>
            <SearchOptions type="gist" v-model="state.gistId" />
          </div>
        </div>

        <div class="flex flex-row gap-3 w-full mt-2">
          <div class="form-control w-full mt-0">
            <label class="label mt-0 pt-0" for="">
              <span class="label-text-alt">{{ $t("message.meterType") }}</span>
            </label>
            <select
              v-model="state.meterType"
              class="select select-sm w-full select-bordered"
            >
              <option disabled selected>{{ $t("message.choose") }}</option>
              <option>MAIN</option>
              <option>SUB</option>
            </select>
          </div>

          <div class="form-control w-full mt-0">
            <label class="label mt-0 pt-0" for="">
              <span class="label-text-alt">{{
                $t("message.publicUtilityType")
              }}</span>
            </label>
            <select
              v-model="state.publicUtilityType"
              class="select select-sm w-full select-bordered"
            >
              <option disabled selected>{{ $t("message.choose") }}</option>
              <option>GAS</option>
              <option>WATER</option>
              <option>CALORIMETER</option>
              <option>ELECTRICITY</option>
            </select>
          </div>
        </div>

        <div class="flex flex-row gap-3 w-full mt-2">
          <div class="form-control w-full mt-0">
            <label class="label mt-0 pt-0" for="">
              <span class="label-text-alt">{{ $t("message.identifier") }}</span>
            </label>
            <input
              v-model="state.identifier"
              type="text"
              class="input input-sm input-bordered w-full"
            />
          </div>

          <div class="form-control w-full mt-0">
            <label class="label mt-0 pt-0" for="">
              <span class="label-text-alt">{{ $t("message.placement") }}</span>
            </label>
            <input
              v-model="state.placement"
              type="text"
              class="input input-sm input-bordered w-full"
            />
          </div>
        </div>

        <div class="flex flex-row gap-3 w-full mt-2">
          <div class="form-control w-full mt-0">
            <label class="label mt-0 pt-0" for="">
              <span class="label-text-alt">{{ $t("message.readings") }}</span>
            </label>
            <input
              v-model="state.readings"
              type="text"
              class="input input-sm input-bordered w-full"
            />
          </div>

          <div class="form-control w-full mt-0">
            <label class="label mt-0 pt-0" for="">
              <span class="label-text-alt">{{
                $t("message.readingFrequency")
              }}</span>
            </label>
            <select
              v-model="state.readingFrequency"
              class="select select-sm w-full select-bordered"
            >
              <option disabled selected>{{ $t("message.choose") }}</option>
              <option>SEMI_ANNUAL</option>
              <option>MONTHLY</option>
              <option>BIMONTHLY</option>
              <option>ANNUAL</option>
            </select>
          </div>
        </div>

        <div class="flex flex-row gap-3 w-full mt-2">
          <div class="form-control w-full mt-0">
            <label class="label mt-0 pt-0" for="">
              <span class="label-text-alt">{{ $t("message.commType") }}</span>
            </label>
            <select
              v-model="state.commType"
              class="select select-sm w-full select-bordered"
            >
              <option disabled selected>{{ $t("message.choose") }}</option>
              <option>SMART</option>
              <option>CAN_BE_SMARTENED</option>
              <option>MECHANICAL</option>
              <option>SMARTENED</option>
            </select>
          </div>
        </div>

        <div class="flex flex-row gap-3 w-full mt-2">
          <div class="form-control w-full mt-0">
            <label class="label mt-0 pt-0" for="">
              <span class="label-text-alt">{{
                $t("message.expirationDate")
              }}</span>
            </label>
            <input
              v-model="state.expirationDate"
              type="date"
              class="input input-sm input-bordered w-full"
            />
          </div>
        </div>

        <div class="w-full mt-3">
          <button
            class="disabled:opacity-60 btn btn-sm bg-magenta text-white font-light text-xs border-none"
            @click="addNewMeter"
          >
            {{ $t("message.save") }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import axios from "axios";
import { reactive } from "vue";
import toast from "@/misc/toast.js";
import { useI18n } from "vue-i18n";
import store from "@/misc/vuex-store";
import { useRoute } from "vue-router";
import SearchOptions from "../../../../components/SearchOptions.vue";

const route = useRoute();
const buildingId = route.params.buildingId;

const { t } = useI18n();
const props = defineProps(["params"]);

const state = reactive({
  gistId: null,
  meterType: "",
  publicUtilityType: "",
  identifier: "",
  placement: "",
  readings: "",
  readingFrequency: "",
  commType: "",
  expirationDate: "",
});

const addNewMeter = async () => {
  const uri = process.env.VUE_APP_FRONTENDV2_API_URL + `/rem/meter`;
  let postBody = {
    gistId: state.gistId,
    buildingId: buildingId,
    meterType: state.meterType,
    publicUtilityType: state.publicUtilityType,
    partnerId: null,
    identifier: state.identifier,
    placement: state.placement,
    readings: state.readings,
    readingFrequency: state.readingFrequency,
    commType: state.commType,
    expirationDate: state.expirationDate,
  };

  try {
    await axios.post(uri, postBody, {
      onTokenRefreshed: () => {
        addNewMeter();
      },
    });
    hideSidebar();
    if (props.params && props.params.onSave) {
      props.params.onSave();
    }
    toast.success(t("message.successfulSave"));
  } catch (err) {
    console.log(err);
    toast.error(t("message.saveError"));
  }
};

const hideSidebar = () => {
  store.commit("hideSidebar");
};
</script>

<style lang="scss" scoped></style>
