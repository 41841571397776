<template>
  <div class="flex flex-col gap-2 my-2 ">

    <div>
      <label for="selectorOperationField">Művelet:</label>
      <select @change="changeValue" v-model="operation" class="form-select mt-1.5 w-full rounded-lg border border-slate-300 bg-white px-3 py-2 hover:border-slate-400 focus:border-primary dark:border-navy-450 dark:bg-navy-700 dark:hover:border-navy-400 dark:focus:border-accent">
        <option v-for="(option, key) in possibleOperations" :value="key" :key="key">{{ option }}</option>
      </select>
    </div>

    <div class="flex flex-col" v-if="operation == 'eq'">
      <label>Érték:</label>
      <span class="relative mt-1.5 flex">
        <VueDatePicker 
          format="yyyy-MM-dd" 
          :enable-time-picker="false" 
          :month-change-on-scroll="false"
          :action-row="{}"
          :auto-apply="true"
          :clearable="false"
          :teleport="true"
          model-type="yyyy-MM-dd"
          placeholder="Dátum"
          v-model="currentValue"
          :dark="darkModeState()"
          @update:model-value="changeValue"
        />
      </span>
      <span v-if="!validation" class="text-tiny+ text-error mt-1">
        Válasszon dátumot!
      </span>
    </div>

    <div class="flex flex-row gap-2" v-if="operation == 'bw'">
      <div class="w-1/2 flex flex-col">
        <label for="selectorDateValueStart">Kezdő dátum:</label>
        <span class="relative mt-1.5 flex">
          <VueDatePicker 
            format="yyyy-MM-dd" 
            :enable-time-picker="false" 
            :month-change-on-scroll="false"
            :action-row="{}"
            :auto-apply="true"
            :clearable="false"
            :teleport="true"
            model-type="yyyy-MM-dd"
            placeholder="Dátum"
            v-model="currentValue"
            :dark="darkModeState()"
            @update:model-value="changeValue"
          />
        </span>
      </div>

      <div class="w-1/2 flex flex-col">
        <label for="selectorDateValueStart">Záró dátum:</label>
        <span class="relative mt-1.5 flex">
          <VueDatePicker 
            format="yyyy-MM-dd" 
            :enable-time-picker="false" 
            :month-change-on-scroll="false"
            :action-row="{}"
            :auto-apply="true"
            :clearable="false"
            :teleport="true"
            model-type="yyyy-MM-dd"
            placeholder="Dátum"
            v-model="currentValueEnd"
            :dark="darkModeState()"
            @update:model-value="changeValue"
          />
        </span>
      </div>
    </div>
      

  </div>
</template>

<script>
import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'
import Config from './RVGFilterableDate.config.js'
import moment from 'moment';

export default {
  name: "RVGFilterableDate",
  components: {
    VueDatePicker,
    
  },
  data() {
    return {
      operation: 'eq',
      currentValue: null,
      currentValueEnd: null
    }
  },
  computed: {
    possibleOperations() {
      return Config.possibleOperations();
    },
    validation() {
      if(this.operation == 'eq') {
        return !!(this.currentValue !== null && this.currentValue !== '' && this.currentValue.match(/[0-9]{4}-[0-9]{2}-[0-9]{2}/g))
      }
      
      if(this.operation == "bw") {
        return (!!(this.currentValue !== null && this.currentValue !== '' && this.currentValue.match(/[0-9]{4}-[0-9]{2}-[0-9]{2}/g)) &&
          !!(this.currentValueEnd !== null && this.currentValueEnd !== '' && this.currentValueEnd.match(/[0-9]{4}-[0-9]{2}-[0-9]{2}/g)) && 
          this.currentValue < this.currentValueEnd)
      }

      return true;
    }
  },
  props: ['data', 'config', 'id'],
  methods: {
    darkModeState() {
      //return LocalStorageService.getDarkModeState() && LocalStorageService.getDarkModeState() != 'false' ? 'true' : null
      return false; // @TODO: implement dark mode
    },
    changeValue() {
      var value, textual;

      if(this.operation == "eq") {
        value = moment(this.currentValue).format('YYYY-MM-DD');
        textual = Config.getTextual(this.operation, value, true)
      }

      if(this.operation == "bw") {
        value = {
          start: moment(this.currentValue).format('YYYY-MM-DD'),
          end: moment(this.currentValueEnd).format('YYYY-MM-DD')
        };
        textual = Config.getTextual(this.operation, value, true)
      }

      this.$emit('changeValue', this.id, {
        field: this.id,
        filterKey: this.config.filterKey ? this.config.filterKey : this.id,
        type: 'date',
        operation: this.operation,
        value: value,
        isValid: this.validation,
        textual: textual
      })
    }
  }
};
</script>

<style scoped>
  label {
    float: left;
  }

  .pull-left {
    float: left;
    text-align: left;
  }
</style>
