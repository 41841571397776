import UserRoles from "./UserRoles";

const nrgmap_user = [
  "MainMap",
  "MapView",
  "Heatmap",
  // 'Devices', // Uncomment this line to enable the Devices page for NRGMAP_USER
  "TemperatureMapView",
  "ViewGists",
  "ViewProperty",
];

const nrgmap_admin = [
  "Devices",
  "ZoneAdmin",
  "AddNewGist",
  "EditGist",
  "DeleteGist",
  "AddNewProperty",
  "EditProperty",
  "DeleteProperty",
  "ViewReports",
  "AddNewSiteDesign",
  "EditSiteDesign",
  "DeleteSiteDesign",
];

const rem_user = [
  "MainMap",
  "DocumentsPage",
  "PropertiesPage",
  "PremisePage",
  "EnergeticsPage",
  "BuildingPage"
];

const rem_admin = [
  "VocabularyAdmin"
];



const addRoles = (roleArray) => {
  let roles = [];
  roleArray.forEach((element) => {
    //console.log(element);

    roles = roles.concat(element);
    //console.log(roles);
  });
  //console.log(roles);
  return roles;
};

export default {
  [UserRoles.NRGMAP_USER]: addRoles([nrgmap_user, nrgmap_admin]),

  [UserRoles.NRGMAP_REM_USER]: addRoles([rem_user]),
  [UserRoles.NRGMAP_REM_ADMIN]: addRoles([rem_user, rem_admin]),
  [UserRoles.NRGMAP_ADMIN]: addRoles([
    nrgmap_user,
    nrgmap_admin,
    rem_user,
    rem_admin,
  ]),
  [UserRoles.NRGMAP_SITE_ADMIN]: []
};

/**
 * README
 *
 * NRGMAP_ADMIN:
 *    - NRGMAP_USER + nrgmap_admin + REM_USER + rem_admin
 *
 * REM_ADMIN:
 *    - REM_USER + rem_admin
 */
