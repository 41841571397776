<template>
  <div>
    <input type="checkbox" id="my_modal_6" class="modal-toggle hidden" v-model="state.showModal" />
    <div ref="modal" class="modal modal-scale fixed inset-0 z-[100] flex flex-col items-center justify-center overflow-hidden px-4 py-6 sm:px-5" role="dialog">
      <div class="modal-overlay absolute inset-0 bg-slate-900/60"></div>
      <div class="modal-content relative flex w-full origin-top flex-col overflow-hidden rounded-lg bg-slate-800 dark:bg-navy-700" :class="[{ '!overflow-visible': overflowVisible }, maxWidthClass]">
        <div class="flex flex-row items-center justify-between rounded-t-lg bg-slate-900 px-4 py-3 dark:bg-navy-800 sm:px-5">
          <h3 class="text-base font-medium text-slate-500 dark:text-navy-100">
            {{ title }}
          </h3>
          <button @click="close()" class="btn btn-sm">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="h-3.5 w-3.5"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              stroke-width="2"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          </button>
        </div>
        <div class="scrollbar-sm overflow-y-auto px-4 py-4 sm:px-5" :class="[{ '!overflow-y-visible': overflowVisible }]">
          <slot name="content"></slot>
          <div v-if="disableButtons !== true" class="space-x-2 text-right mt-3 flex flex-row items-center justify-end">
            <button @click="close()" :disabled="okButtonLoading" class="disabled:opacity-60 btn btn-sm">
              {{ cancelButtonTitle ? cancelButtonTitle : 'Cancel' }}
            </button>
            <button v-if="!noNeedOkButton" :disabled="okButtonLoading || disableOkButton" @click="() => { ok(close); }" class="disabled:opacity-60  btn btn-sm">
              <span v-if="!okButtonLoading">{{ okButtonTitle ? okButtonTitle : 'OK' }}</span>
              <SpinnerLoader v-else sizeClasses="h-5.5 w-5.5" />
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
  import { reactive, watch } from 'vue';
  import SpinnerLoader from './SpinnerLoader.vue';

  const props = defineProps({
    show: {
      required: true
    },
    title: {
      type: String,
      required: true
    },
    cancelButtonTitle: {
      type: String
    },
    okButtonTitle: {
      type: String
    },
    close: {
      type: Function,
      required: true
    },
    ok: {
      type: Function,
      required: false
    },
    disableButtons: {
      type: Boolean,
      default: false,
      required: false
    },
    disableOkButton: {
      type: Boolean,
      default: false,
      required: false
    },
    okButtonLoading: {
      type: Boolean,
      default: false,
      required: false
    },
    noNeedOkButton: {
      type: Boolean,
      default: false,
      required: false
    },
    maxWidthClass: {
      type: String,
      default: 'max-w-2xl',
      required: false
    },
    overflowVisible: {
      type: Boolean,
      default: false,
      required: false
    }
  });

  const state = reactive({
    showModal: props.show
  });

  watch(() => props.show, (value) => {
    state.showModal = value;
  });

</script>