<template>
  <div class="flex flex-row gap-2 my-2 ">
    <div class="w-1/2">
      <label>Művelet:</label>
      <select @change="changeValue" v-model="operation" class="form-select mt-1.5 w-full rounded-lg border border-slate-300 bg-white px-3 py-2 hover:border-slate-400 focus:border-primary dark:border-navy-450 dark:bg-navy-700 dark:hover:border-navy-400 dark:focus:border-accent">
        <option v-for="(option, key) in possibleOperations" :value="key" :key="key">{{ option }}</option>
      </select>
    </div>
    <div class="w-1/2">
      <label>Értékek kiválasztása:</label>
      <select :disabled="availableOptions.length === 0" @change="(e) => { addTag(e.target.value) }" v-model="currentSelectedValue" class="form-select mt-1.5 w-full rounded-lg border border-slate-300 bg-white px-3 py-2 hover:border-slate-400 focus:border-primary dark:border-navy-450 dark:bg-navy-700 dark:hover:border-navy-400 dark:focus:border-accent">
        <option value="null">Válasszon az értékek közül</option>
        <option v-for="(option, key) in availableOptions" :value="key" :key="key">{{ option }}</option>
      </select>

      <div v-if="currentValue.length > 0" class="flex flex-row whitespace-normal overflow-auto flex-wrap gap-2 mt-2">
        <div v-for="tag in currentValue" :key="tag.id" class="cursor-pointer">
          <div :class="tag.classList"
            @click="removeTag(tag.id)"
          >{{ tag.title }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import Config from './RVGFilterableStatus.config.js'

export default {
  name: "RVGFilterableStatus",
  props: ['data', 'config', 'id', 'formatterConfig'],
  components: {
  },
  data() {
    return {
      operation: 'in',
      currentValue: [],
      currentSelectedValue: "null"
    }
  },
  computed: {
    possibleOperations() {
      return Config.possibleOperations()
    },
    validation() {
      return this.currentValue.length > 0
    },
    availableOptions() {
      var opts = {}
      for(var i in this.formatterConfig.mapping) {
        var f = false;
        for(var j in this.currentValue) {
          if(this.currentValue[j].id == i) {
            f = true
          }
        }

        if(!f) {
          opts[i] = this.formatterConfig.mapping[i].title
        }
      }

      return opts
    }
  },
  methods: {
    getOptionById(optId) {
      for(var i in this.formatterConfig.mapping) {
        if(i == optId) {
          return this.formatterConfig.mapping[i]
        }
      }

      return null;
    },
    addTag(optId) {
      var opt = this.formatterConfig.mapping[optId]
      if(!opt) {
        return 
      }

      this.currentValue.push({
        id: optId,
        title: opt.title,
        classList: opt.classList
      })

      this.currentSelectedValue = "null";
      this.changeValue()
    },
    removeTag(optId) {
      var ind = -1;
      for(var i in this.currentValue) {
        if(this.currentValue[i].id == optId) {
          ind = i
          break;
        }
      }

      if(ind > -1) {
        this.currentValue.splice(ind, 1)
      }

      this.currentSelectedValue = "null";
      this.changeValue()
    },
    changeValue() {
      var ids = [];
      var titles = [];

      for(var i in this.currentValue) {
        if(this.formatterConfig.dataType == "integer") {
          ids.push(window.parseInt(this.currentValue[i].id))
        }
        if(this.formatterConfig.dataType == "boolean") {
          var bValue = this.currentValue[i].id == "true" ? true : false;
          ids.push(bValue)
        }
        if(this.formatterConfig.dataType == "text") {
          ids.push(this.currentValue[i].id)
        }

        titles.push(this.currentValue[i].title)
      }

      this.$emit('changeValue', this.id, {
        field: this.id,
        filterKey: this.config.filterKey ? this.config.filterKey : this.id,
        type: 'status',
        operation: this.operation,
        value: ids,
        isValid: this.validation,
        textual: Config.getTextual(this.operation, titles)
      })
    }
  }
};
</script>

<style scoped>
  label {
    float: left;
  }

  .pull-left {
    float: left;
    text-align: left;
  }

  .inliner {
    display: inline-block;
  }

  .inlineLabel {
    width: 100%;
    text-align: left;;
  }

  .selectorStatusSelector {
    float: left;
  }
</style>
