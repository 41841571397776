<template>
  <strong class="RVGStrong">
    {{ data }}
  </strong>
</template>

<script>
export default {
  name: "RVGStrong",
  components: {
  },
  props: ['data']
};
</script>

<style scoped>
</style>
