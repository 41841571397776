<template>
  <div class="h-full overflow-y-auto w-full">
    <div
      class="mt-5 mx-5 flex flex-row grow border-b-0 border-base-300 justify-between"
    >
      <div class="flex flex-row items-center gap-2 justify-center">
        <h2
          class="text-base font-sans font-semibold uppercase text-secondary flex flex-row items-center gap-2 grow"
        >
          <span class="text-secondary">{{$t("message.updateLegalSituation")}}</span>
        </h2>
      </div>
      <a
        href="javascript:void(null)"
        class="hover:text-secondary"
        @click="hideSidebar"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke-width="1.5"
          stroke="currentColor"
          class="w-6 h-6"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            d="M6 18L18 6M6 6l12 12"
          />
        </svg>
      </a>
    </div>

    <div class="px-5 pb-5">
      <div class="flex flex-col items-center justify-center gap-2 w-full">
        <div class="flex flex-row gap-3 w-full mt-2">
          <div class="form-control w-full mt-0">
            <label class="label mt-0 pt-0" for="">
              <span class="label-text-alt">{{$t("message.legalSituation")}}</span>
            </label>
            <OptionValueSelector
              type="LEGAL_SITUATION"
              v-model="state.legalSituation"
            />
          </div>
        </div>

        <div class="flex flex-row gap-3 w-full mt-2">
          <div class="form-control w-full mt-0">
            <label class="label mt-0 pt-0" for="">
              <span class="label-text-alt">{{$t("message.legalRelationship")}}</span>
            </label>
            <OptionValueSelector
              type="LEGAL_RELATIONSHIP_TYPE"
              v-model="state.legalRelationshipType"
            />
          </div>
        </div>

        <div class="flex flex-row gap-3 w-full mt-2">
          <div class="form-control w-full mt-0">
            <label class="label mt-0 pt-0" for="">
              <span class="label-text-alt">{{$t("message.ownership")}}</span>
            </label>
            <input
              v-model="state.ownership"
              type="text"
              class="input input-sm input-bordered w-full"
            />
          </div>
        </div>

        <div class="flex flex-row gap-3 w-full mt-2">
          <div class="form-control w-full mt-0">
            <label class="label mt-0 pt-0" for="">
              <span class="label-text-alt">{{$t("message.legalSituationStart")}}</span>
            </label>
            <input
              v-model="state.legalRelationshipStart"
              type="date"
              class="input input-sm input-bordered w-full"
            />
          </div>
        </div>

        <div class="flex flex-row gap-3 w-full mt-2">
          <div class="form-control w-full mt-0">
            <label class="label mt-0 pt-0" for="">
              <span class="label-text-alt">{{$t("message.legalSituationEnd")}}</span>
            </label>
            <input
              v-model="state.legalRelationshipEnd"
              type="date"
              class="input input-sm input-bordered w-full"
            />
          </div>
        </div>

        <div class="w-full mt-3 flex justify-between">
          <button
            class="disabled:opacity-60 btn btn-sm bg-magenta text-white font-light text-xs border-none"
            @click="updateLegalSituation"
          >
            {{ $t("message.save") }}
          </button>
          <button
            class="disabled:opacity-60 btn btn-sm bg-red-600 text-white font-light text-xs border-none"
            @click="
              () => {
                state.showRemoveConfirmation = true;
              }
            "
          >
          {{$t("message.deleteLegalSituation")}}
          </button>
        </div>

        <input
          type="checkbox"
          class="modal-toggle"
          v-model="state.showRemoveConfirmation"
        />
        <div class="modal">
          <div class="modal-box">
            <h3 class="text-lg text-left font-normal">
              {{$t("message.confirmRemoveLS")}}
            </h3>
            <div class="modal-action">
              <label
                class="btn btn-sm text-white font-light text-xs"
                @click="
                  () => {
                    state.showRemoveConfirmation = false;
                  }
                "
                >{{ $t("message.deny") }}</label
              >
              <label
                class="btn btn-sm bg-magenta text-white font-light text-xs"
                @click="
                  () => {
                    deleteLegalSituation();
                    state.showRemoveConfirmation = false;
                  }
                "
                >{{ $t("message.confirm") }}</label
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import axios from "axios";
import { reactive, computed, onMounted } from "vue";
import toast from "@/misc/toast.js";
import { useI18n } from "vue-i18n";
import store from "@/misc/vuex-store";
import { useRoute } from "vue-router";
import OptionValueSelector from "../../../../components/OptionValueSelector.vue";

const route = useRoute();
const buildingId = route.params.buildingId;
const propertyId = route.params.propertyId;

const { t } = useI18n();
const props = defineProps(["params"]);

const state = reactive({
  showRemoveConfirmation: false,
  lsById: null,
  legalSituation: null,
  legalRelationshipType: null,
  ownership: "",
  legalRelationshipStart: "",
  legalRelationshipEnd: "",
});

const propCurrentTab = computed(() => {
  return store.getters.getPropCurrentTab;
});

const fillLegalSituation = () => {
  state.lsById = store.getters.getLegalSituationById;

  state.legalSituation = state.lsById.legalSituationTypeId;
  state.legalRelationshipType = state.lsById.legalRelationshipTypeId;
  state.ownership = state.lsById.ownership;
  state.legalRelationshipStart = state.lsById.legalRelationshipStart;
  state.legalRelationshipEnd = state.lsById.legalRelationshipEnd;
};

const updateLegalSituation = async () => {
  const uri =
    process.env.VUE_APP_FRONTENDV2_API_URL +
    `/rem/legal-situation/${state.lsById.id}`;
  let postBody = null;

  if (propCurrentTab.value == "propLegalSituations") {
    postBody = {
      property: propertyId,
      legalSituationType: state.legalSituation,
      legalRelationshipType: state.legalRelationshipType,
      ownership: state.ownership,
      legalRelationshipStart: state.legalRelationshipStart,
      legalRelationshipEnd: state.legalRelationshipEnd,
    };
  }

  if (propCurrentTab.value == "buildingLegalSituations") {
    postBody = {
      building: buildingId,
      legalSituationType: state.legalSituation,
      legalRelationshipType: state.legalRelationshipType,
      ownership: state.ownership,
      legalRelationshipStart: state.legalRelationshipStart,
      legalRelationshipEnd: state.legalRelationshipEnd,
    };
  }

  try {
    await axios.patch(uri, postBody, {
      onTokenRefreshed: () => {
        updateLegalSituation();
      },
    });
    hideSidebar();
    if (props.params && props.params.onSave) {
      props.params.onSave();
    }
    toast.success(t("message.successfulSave"));
  } catch (err) {
    console.log(err);
    toast.error(t("message.saveError"));
  }
};

async function deleteLegalSituation() {
  const uri =
    process.env.VUE_APP_FRONTENDV2_API_URL +
    `/rem/legal-situation/${state.lsById.id}`;
  try {
    await axios.delete(uri, {
      onTokenRefreshed: () => {
        deleteLegalSituation();
      },
    });
    toast.success(t("message.deleteSuccessful"));
    hideSidebar();
    if (props.params && props.params.onSave) {
      props.params.onSave();
    }
  } catch (error) {
    console.log(error);
    toast.error(t("message.deleteNotSuccessful"));
  }
}

const hideSidebar = () => {
  store.commit("hideSidebar");
};

onMounted(() => {
  fillLegalSituation();
});
</script>

<style lang="scss" scoped></style>
