import { createWebHistory, createRouter } from "vue-router";
import MapPage from "../pages/MapPage.vue";
import SiteDesignSelectorPage from "../pages/SiteDesignSelectorPage.vue";
import ZoneEditorPage from "../pages/ZoneEditorPage.vue";
import DevicesPage from "../pages/DevicesPage.vue";
//import HeatmapPage from '../pages/HeatmapPage.vue'
import User from "../misc/User";
import PermissionDeniedPage from "../pages/PermissionDeniedPage/PermissionDeniedPage.vue";
import UnauthorizedTenantPage from "../pages/UnauthorizedTenantPage/UnauthorizedTenantPage.vue";
import TokenExpiredPage from "../pages/TokenExpiredPage/TokenExpiredPage.vue";
import TemperatureMapPage from "../pages/TemperatureMapPage.vue";
import ResponderPage from "../pages/ResponderPage.vue";
import msalInstance from "./msalInstance";
import RespondersPage from "../pages/RespondersPage.vue";
//import msalHelper from './msalHelper';
import ReportsPage from "../pages/ReportsPage.vue";
import PropertyPage from "../pages/PropertyPageComponents/PropertyPage.vue";
import PropertiesPage from "../pages/PropertiesPage.vue";
import BuildingPage from "../pages/BuildingPageComponents/BuildingPage.vue";
import PremisePage from "../pages/PremisePageComponents/PremisePage.vue"
import EnergeticsPage from "../pages/EnergeticsPage.vue";
import VocabularyAdminEditorPage from "../pages/VocabularyAdminEditorPage.vue"
import DocumentsPage from "../pages/DocumentsPageComponents/DocumentsPage.vue"
import Utils from "./Utils";


async function requireAuthAndValidTenant(to, from, next) {
  if (User.isLoggedIn()) {
    // already logged in
    const allowedTenants = process.env.VUE_APP_ALLOWED_TENANTS.split(",");
    if (allowedTenants.includes(User.getUserTenantId())) {
      if(typeof to.meta.module !== 'undefined' && !Utils.isModuleEnabled(to.meta.module)) {
        next('/error/permission-denied');
        return;
      }
      if (!to.meta.permission || User.hasPermission(to.meta.permission)) {
        next();
      } else {
        next("/error/permission-denied");
      }
    } else {
      next("/error/unauthorized-tenant");
    }
  } else {
    const response = await msalInstance.handleRedirectPromise();
    //msalHelper.fetchMainToken(msalInstance);

    if (response) {
      // handling login response
      User.login(
        response.accessToken,
        response.idToken,
        response.idTokenClaims.roles
      );
      //msalHelper.fetchGraphToken(msalInstance);
      next();
    } else {
      // invoke login
      await msalInstance.loginRedirect({
        scopes: [process.env.VUE_APP_AZURE_API_TOKEN_REQUEST],
      });
    }
  }
}

let router = createRouter({
  history: createWebHistory(),
  routes: [
    {
      path: "/rem/documents",
      component: DocumentsPage,
      name: "documentsPage",
      meta: {
        requiresAuth: true,
        permission: "DocumentsPage",
      },
      beforeEnter: requireAuthAndValidTenant,
    },
    {
      path: "/properties",
      component: PropertiesPage,
      name: "propertiesPage",
      meta: {
        requiresAuth: true,
        permission: "PropertiesPage",
      },
      beforeEnter: requireAuthAndValidTenant,
    },
    {
      path: "/properties/:propertyId",
      component: PropertyPage,
      name: "propertyPage",
      meta: {
        requiresAuth: true,
        permission: "PropertiesPage",
      },
      beforeEnter: requireAuthAndValidTenant,
    },
    {
      path: "/properties/:propertyId/buildings/:buildingId",
      component: BuildingPage,
      name: "buildingPage",
      meta: {
        requiresAuth: true,
        permission: "BuildingPage",
      },
      beforeEnter: requireAuthAndValidTenant,
    },
    {
      path: "/properties/:propertyId/buildings/:buildingId/premises/:premiseId",
      component: PremisePage,
      name: "premisePage",
      meta: {
        requiresAuth: true,
        permission: "PremisePage",
      },
      beforeEnter: requireAuthAndValidTenant,
    },
    {
      path: "/properties/:propertyId/buildings/:buildingId/premises/:premiseId/energetics",
      component: EnergeticsPage,
      name: "energeticsPage",
      meta: {
        requiresAuth: true,
        permission: "EnergeticsPage",
      },
      beforeEnter: requireAuthAndValidTenant,
    },
    {
      path: '/reports',
      component: ReportsPage,
      name: 'powerBiReportPage',
      meta: { 
        requiresAuth: true,
        permission: 'ViewReports'
      },
      beforeEnter: requireAuthAndValidTenant,
    },
    {
      path: "/",
      component: SiteDesignSelectorPage,
      name: "siteDesignSelector",
      meta: {
        requiresAuth: true,
        permission: "MainMap",
      },
      beforeEnter: requireAuthAndValidTenant,
    },
    {
      path: '/map/:siteDesignId/:zone?',
      component: MapPage,
      name: "map",
      meta: {
        requiresAuth: true,
        permission: "MapView",
      },
      beforeEnter: requireAuthAndValidTenant,
    },
    {
      path: "/temperature-map/:siteDesignId",
      component: TemperatureMapPage,
      name: "temperatureMap",
      meta: {
        requiresAuth: true,
        permission: "TemperatureMapView",
      },
      beforeEnter: requireAuthAndValidTenant,
    },
    // {
    //   path: '/map/:siteDesignId/heatmap',
    //   component: HeatmapPage,
    //   name: 'heatmap',
    //   meta: {
    //     requiresAuth: true,
    //     permission: 'Heatmap'
    //   },
    //   beforeEnter: requireAuthAndValidTenant
    // },
    {
      path: "/map/:siteDesignId/devices",
      component: DevicesPage,
      name: "devices",
      meta: {
        requiresAuth: true,
        permission: "Devices",
      },
      beforeEnter: requireAuthAndValidTenant,
    },
    {
      path: "/map/:siteDesignId/edit/:type",
      component: ZoneEditorPage,
      name: "zoneEditor",
      meta: {
        requiresAuth: true,
        permission: "ZoneAdmin",
      },
      beforeEnter: requireAuthAndValidTenant,
    },
    {
      path: '/vocabularyAdmin',
      component: VocabularyAdminEditorPage,
      name: 'vocabularyAdmin',
      meta: { 
        requiresAuth: true,
        permission: 'VocabularyAdmin'
      },
      beforeEnter: requireAuthAndValidTenant
    },
    {
      path: '/error/permission-denied',
      component: PermissionDeniedPage,
      name: "permissionDenied",
      meta: {
        requiresAuth: false,
        permission: null,
      },
    },
    {
      path: "/error/unauthorized-tenant",
      component: UnauthorizedTenantPage,
      name: "unauthorizedTenant",
      meta: {
        requiresAuth: false,
        permission: null,
      },
    },
    {
      path: "/responders",
      component: RespondersPage,
      name: "respondersPage",
      meta: {
        requiresAuth: false,
        permission: null,
      },
    },
    {
      path: "/responder/:responderId",
      component: ResponderPage,
      name: "responderPage",
      meta: {
        requiresAuth: false,
        permission: null,
      },
    },
    {
      path: "/error/token-expired",
      component: TokenExpiredPage,
      name: "tokenExpired",
      meta: {
        requiresAuth: false,
        permission: null,
      },
    },
  ],
});

export default router;
