<!------------
README

<ImFormInput 
v-model="VARIABLE FOR TWO WAY BINDING WITH THE INPUT VALUE" 

type="THE TYPE OF THE INPUT FIELD EG. TEXT, NUMBER, EMAIL
    UNIQUE VERSION ARE 
        LABEL - FORM SECTION LABEL WITH HORIZONTAL RULE
        CHECKBOX - DISPLAYED AS A YES-NO SELECT
        OPTION - FETCHES AZ OPTIONVALUE
            USE THE OPTIONVALUE FIELD TO DEFINE THE OPTIONVALUE TYPE
        OPTIONAPI - FETCHES VALUES FROM A DEDICATED ENDPOINT
            USE THE ENDPOINT FIELD TO DEFINE THE ENDPOINT 
            THE VALUE OF THE OPTIONS WILL BE THE RECORD ID
            USE THE DISPLAYVALUE FIELD TO DEFINE THE OPTIONS LABEL FIELD" 

title="LABEL OF THE INPUT FIELD" 
options="LIST OF THE OPTIONS" 
valueLabel="LABEL OF THE INITIAL VALUE"
searchable="SEARCH BETWEEN THE OPTIONS (OPTION AND OPTIONAPi ONLY)"

searchable="TRUE / FALSE - ENABLES SEARCHING IN THE OPTIONS"

optionValue="OPTIONVALUE TO FETCH THE OPTIONS FROM E.G. DOCUMENT_TYPE (ONLY FOR OPTIONVALUE)"   

endPoint="ENDPOINT TO FETCH THE OPTIONS FROM" 
displayValue="WHICH FIELD SHOULD BE THE LABEL OF THE OPTION (ONLY FOR OPTIONAPI)">
</ImFormInput>


-->

<template>
    <div>
        <label v-if="type != 'label'" class="label mt-0 pt-0" for="">
            <span class="label-text-alt">{{ title }} </span>
        </label>
        <input
            v-if="type != 'option' && type != 'optionapi' && type != 'checkbox' && type != 'label' && type != 'toggle'"
            :type="type" class="input input-sm input-bordered w-full" v-model="inputValue">
        <div v-if="type == 'label'">
            <h2 class=" text-xl text-secondary w-full font-bold">{{ title }}</h2>
            <hr class="mb-8" />
        </div>
        <div v-if="type == 'option' || type == 'optionapi'">
            <div v-if="searchable == true">
                <input type="hidden" v-model="inputValue" class="input input-sm input-bordered w-full">
                <div class="im-dropdown w-full">
                    <input type="text" list="optionList" v-model="optionSearchValue" @focusin="focus = true"
                        @focusout="setFocus" class="input input-sm input-bordered w-full">

                    <ul class="im-dropdown-content bg-base-100 rounded-box z-50 w-full p-2 shadow z-40"
                        v-if="focus == true">
                        <li><a @click="optionSearchValue = ''; inputValue = ''">Select</a></li>
                        <li v-for="op in options" v-bind:key="op.value + op.label"
                            @click="optionSearchValue = op.label; inputValue = op.value">{{ op.label }}</li>
                    </ul>
                </div>

            </div>
            <select v-else v-model="inputValue" class="input input-sm input-bordered w-full">
                <option value="">Select</option>

                <option v-for="op in options" v-bind:key="op.value + op.label" :value="op.value" :parent="op.parent">{{
                    op.label }}
                </option>
            </select>

        </div>

        <select v-if="type == 'checkbox'" v-model="inputValue" class="input input-sm input-bordered w-full">
            <option value="true">Igen</option>
            <option value="false">Nem</option>
        </select>
        <input v-if="type == 'toggle'" v-model="inputValue" type="checkbox" class="toggle toggle-secondary"
            checked="checked" />
    </div>
</template>

<script setup>

import { ref, defineModel, onMounted, watch, computed } from 'vue';
import Axios from "@/misc/axios";
import store from "@/misc/vuex-store";

const props = defineProps(['title', 'type', 'valueLabel', 'optionValue', 'endPoint', 'displayValue', 'searchable', 'onchangeMethod']);
const inputValue = defineModel();

const language = computed(() => store.getters.getLanguage);



const focus = ref(false);

const options = ref([])

let optionsRepo = [];


const optionSearchValue = ref(props.valueLabel);



onMounted(() => {

    if (props.type == "option") {

        Axios.get(
            process.env.VUE_APP_FRONTENDV2_API_URL +
            "/rem/option/options/" +
            props.optionValue,
            {
                'Accept-Language': language.value
            },
            {
                onTokenRefreshed: () => {
                    this.asyncGet();
                },
            }
        ).then(function (resp) {
            //console.log(e.optionValue);
            //console.log(resp.data);

            resp.data.forEach((op) => {
                options.value.push({
                    label: op.value,
                    value: op.id,
                    parent: op.parent != null ? op.parent.id : "",

                });
            });
        });
    } else if (props.type == "optionapi") {
        Axios.get(
            props.endPoint,
            {
                page: 0,
                size: 1,
                sort: ["id"],
            },
            {
                onTokenRefreshed: () => {
                    this.asyncGet();
                },
            }
        ).then(function (resp) {
            if (resp.data && resp.data.content) {
                resp.data.content.forEach((op) => {
                    options.value.push({
                        label: op[props.displayValue],
                        value: op.id,
                    });
                });
            }
        });

    }
    optionsRepo = options.value


})

const setFocus = function () {

    setTimeout(() => { focus.value = false }, 500);
}

watch(optionSearchValue, (newValue, oldValue) => {
    if (newValue == "") {
        options.value = optionsRepo
    }
    else if (oldValue != newValue) {
        options.value = optionsRepo.filter(elem => (elem.label.toLowerCase()).includes(newValue.toLowerCase()));
    }
    // console.log(newValue + " " + oldValue);

})

watch(() => inputValue.value, (newValue, oldValue) => {
    //console.log(newValue)
    if ((newValue + '').split('###')[0] == 'CHANGECODE') {
        props.onchangeMethod(options, optionsRepo, (newValue + '').split('###')[1])
        if (options.value.filter(elem => (elem.value == oldValue))) {
            inputValue.value = oldValue
        } else {
            inputValue.value = ''
        }

    }

    // inputValue.value = oldValue
})

</script>

<style scoped>
.im-dropdown-content {
    position: absolute;
    visibility: visible;

}

.im-invisible {
    display: none;

}
</style>