<template>
  <button
    v-if="props.sidebarEntity.type != null"
    @click="showPrevSidebar"
    class="text-magenta font-light"
  >
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      stroke-width="1.5"
      stroke="currentColor"
      class="w-6 h-6"
    >
      <path
        stroke-linecap="round"
        stroke-linejoin="round"
        d="M7.41,15.41L12,10.83L16.59,15.41L18,14L12,8L6,14L7.41,15.41Z"
      />
    </svg>
  </button>
</template>

<script setup>
import { reactive } from "vue";
import Utils from "../misc/Utils.js";
import store from "../misc/vuex-store.js";

const props = defineProps(["sidebarEntity"]);

const state = reactive({
  id: null,
});

const showPrevSidebar = () => {
  if (props.sidebarEntity.parentId) {
    state.id = props.sidebarEntity.parentId;
  } else if (props.sidebarEntity.siteDesignId) {
    state.id = props.sidebarEntity.siteDesignId;
  } else {
    state.id = 2;
  }
  const siteDesign = Utils.getSiteDesignById(state.id);
  if (siteDesign !== null) {
    switch(siteDesign.type)
    {
      case null:
        store.commit('setSiteDesignSidebarCurrentTab', 'areas')
        break;
      case 'area':
        store.commit('setSiteDesignSidebarCurrentTab', 'buildings')
        break;
      case 'building':
        store.commit('setSiteDesignSidebarCurrentTab', 'floors')
        break;
    }
    store.commit("showSidebar", { type: "siteDesign", entity: siteDesign });
  } else {
    console.log("A megadott id-val nem szerepel szülő az adatbázisban!");
  }
};
</script>

<style lang="scss" scoped></style>
