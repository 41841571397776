<template>
  <div id="BuildingPage" class="h-screen">
    <BaseLayout>
      <template #leftButtons>
        <PropertyManagementNavbar propEntity="building" />
      </template>
      <template #mainContent>
        <!--Header-->
        <header class="w-full mt-4 shadow-sm body-font cardBg">
          <div class="items-start px-6 pb-6 mx-auto md:flex-row">
            <div class="flex justify-between">
              <div class="flex flex-row">
                <p
                  class="flex mb-4 text-xl text-secondary md:mb-0 uppercase font-bold"
                >
                  {{ $t("message.building") }} -
                  {{
                    state.building?.siteDesign?.name
                      ? state.building.siteDesign.name
                      : "N/A"
                  }}
                </p>
                <span @click="toLevelSelector">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke-width="1.5"
                    stroke="currentColor"
                    class="w-8 h-8 pl-2 pb-1 text-magenta hover:text-gray-200 hover:cursor-pointer"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M12,11.5A2.5,2.5 0 0,1 9.5,9A2.5,2.5 0 0,1 12,6.5A2.5,2.5 0 0,1 14.5,9A2.5,2.5 0 0,1 12,11.5M12,2A7,7 0 0,0 5,9C5,14.25 12,22 12,22C12,22 19,14.25 19,9A7,7 0 0,0 12,2Z"
                    />
                  </svg>
                </span>
              </div>

              <RemUpButton remType="building" />
            </div>
          </div>
        </header>

        <div class="flex flex-row w-full">
          <div
            v-if="propCurrentTab == 'premises'"
            class="w-full h-dvh m-2 px-6 cardBg rounded-lg"
          >
            <PropertyLists title="premises" :building="state.building" />
          </div>
          <!--Alapadatok-->
          <div
            v-if="propCurrentTab == 'baseDatas'"
            class="w-full m-2 px-6 cardBg rounded-lg"
          >
            <PropertyLists
              title="buildingBaseData"
              :building="state.building"
            />
          </div>
          <!--Dokumentumok-->
          <div
            v-if="propCurrentTab == 'buildingDocuments'"
            class="w-full m-2 px-6 cardBg rounded-lg"
          >
            <PropertyLists title="documents" :building="state.building" />
          </div>
          <!--MT Felelősök-->
          <div
            v-if="propCurrentTab == 'mtResponsibles'"
            class="w-full m-2 px-6 cardBg rounded-lg"
          >
            <PropertyLists title="mtResponsibles" :building="state.building" />
          </div>
          <div
            v-if="propCurrentTab == 'structureTypes'"
            class="w-full m-2 px-6 cardBg rounded-lg"
          >
            <PropertyLists title="structureTypes" :building="state.building" />
          </div>
          <!--Jogi helyzet-->
          <div
            v-if="propCurrentTab == 'buildingLegalSituations'"
            class="w-full m-2 px-6 cardBg rounded-lg"
          >
            <LegalSituations />
          </div>

          <!--Hasznosítások-->
          <div
            v-if="propCurrentTab == 'buildingUtilizations'"
            class="w-full m-2 px-6 cardBg rounded-lg"
          >
            <UtilizationsList />
          </div>

          <!--Mérőórák-->
          <div
            v-if="propCurrentTab == 'buildingMeters'"
            class="w-full m-2 px-6 cardBg rounded-lg"
          >
            <BuildingMeters />
          </div>
        </div>
      </template>
    </BaseLayout>
  </div>
</template>

<script setup>
import { onMounted, reactive, computed } from "vue";
import BaseLayout from "../../layouts/BaseLayout.vue";
import PropertyManagementNavbar from "../../components/REM/PropertyManagementNavbar.vue";
import PropertyLists from "../../components/REM/PropertyLists.vue";
import { useRoute } from "vue-router";
import router from "@/misc/router.js";
import Utils from "../../misc/Utils";
import store from "@/misc/vuex-store.js";
// import toast from "@/misc/toast.js";
import RemUpButton from "../../components/REM/RemUpButton.vue";
import LegalSituations from "../PropertyPageComponents/LegalSituations.vue";
import UtilizationsList from "../PropertyPageComponents/UtilizationsList.vue";
import BuildingMeters from "./BuildingMeters.vue";

const state = reactive({
  building: null,
});

const route = useRoute();
const buildingId = route.params.buildingId;

const getBuilding = async () => {
  const response = await Utils.fetchBuildingById(buildingId);
  state.building = response;
  console.log(state.building);
};

const propCurrentTab = computed(() => {
  return store.getters.getPropCurrentTab;
});

const toLevelSelector = () => {
  store.commit("setSelectedSiteDesign", state.building.siteDesign);
  store.commit("setSiteDesignSidebarCurrentTab", "levels");
  store.commit("setIsFromRem", true);

  // store.commit("showSidebar", {
  //   type: "siteDesign",
  //   entity: state.building.siteDesign,
  // });

  router.push({
    name: "siteDesignSelector",
    params: { siteDesignId: state.building.siteDesign.id },
  });
};

onMounted(() => {
  getBuilding();
});
</script>

<style scoped>
.cardBg {
  background-color: hsl(220, 17.647%, 20%);
}
</style>
