<template>
    <div id="BuildingPage" class="h-screen">
        <BaseLayout>
            <template #mainContent>
                <div class="p4 mt-4 rounded-xl px-8 w-full">
                    <!--Header-->
                    <header class="w-full shadow-sm body-font">
                        <div class="items-start px-6 pb-6 mx-auto md:flex-row mb-8">
                            <div class="flex justify-between">
                                <h1 class="flex mb-4 text-xl text-secondary md:mb-0 uppercase font-bold">
                                    Helyiség {{ premiseId }} / Energetika
                                </h1>


                                <RemUpButton remType="energetics" />
                            </div>
                        </div>
                    </header>
                    <PropertyManagementNavbar propEntity="premise" />
                    <div class="flex flex-col  w-full">
                        <div class="flex flex-row columns-2 im-main-card rounded-xl im-main-card-starter">
                            <div class="w-full">
                                <ImFormInput v-for="item in state.basicData" v-bind:key="item.title"
                                    v-model="item.value" :title="item.title" type="toggle">
                                </ImFormInput>

                            </div>

                        </div>



                    </div>
                </div>
            </template>
        </BaseLayout>
    </div>
</template>

<script setup>
// Components
import ImFormInput from "./PremisePageComponents/ImFormInput.vue";
import BaseLayout from "../layouts/BaseLayout.vue";
import PropertyManagementNavbar from "../components/REM/PropertyManagementNavbar.vue";
import RemUpButton from "../components/REM/RemUpButton.vue";

//Utils

import { onMounted, ref, watch, /*onMounted, reactive,*/ computed } from "vue";
import { useRoute } from "vue-router";
import store from "@/misc/vuex-store";
import { useI18n } from "vue-i18n";


/**
 * Setup
 */
const { t } = useI18n();
console.log(t)

const language = computed(() => store.getters.getLanguage);

const route = useRoute();
const premiseId = route.params.premiseId;


const state = ref({});
state.value.basicData = {
    lagging: {

        title: "hőszigetelés",
        value: false
    }, // hőszigetelés
    solarPanel: {

        title: "Napelem",
        value: false
    },
    centralAirConditionaling: {

        title: "Központi Klíma",
        value: false
    },
    generator: {

        title: "Generátor",
        value: false
    },
    dieselGenerator: {
        title: "Diesel Generátor",
        value: false
    },
    ups: {
        title: "Szünetmentes táp",
        value: false
    }, // szünetmentes táp
    centralHeating: {
        title: "Központi fűtés",
        value: false
    }

}

onMounted(() => {

});

/**
 *  RVGs
 */



/**
 *  Watchers
 */

watch(
    () => language.value,
    () => {

    }
);



/**
 * Savers
 */


</script>

<style scoped>
.cardBg {
    background-color: hsl(220, 17.647%, 20%);
}

.im-main-card {
    --tw-bg-opacity: 1;
    background-color: hsl(var(--b2, var(--b1)) / var(--tw-bg-opacity));
    padding: 3rem;
    width: 100%;
    max-width: 100%;
    max-height: 75vh;
    overflow: hidden;
}

.im-main-card-starter {
    animation: 0.5s ease-out 0s 1 scaleupY;
    overflow: auto;
}

.im-main-card-starter div {
    animation: 1.5s ease-out 0s 1 fadein;
}

@keyframes scaleupY {
    0% {
        transform-origin: left top;
        transform: scaleY(0);
    }

    100% {
        transform: scaleY(1);
    }
}

@keyframes fadein {
    0% {
        opacity: 0;
    }

    50% {
        opacity: 0;
    }

    100% {
        opacity: 100%;
    }
}

.im-sidemenu-contanier {
    animation: 0.5s ease-out 0s 1 scaleupY;
    padding: 1rem;

    margin-right: 2rem;
    height: fit-content;
    --tw-bg-opacity: 1;
    background-color: hsl(var(--b2, var(--b1)) / var(--tw-bg-opacity));
}

.im-sidemenu-contanier div {
    animation: 1.5s ease-out 0s 1 fadein;
}

.im-card-form {
    padding-top: 2rem;
}

.fade-enter-active {
    transition: opacity 1s ease 1s, padding 1s ease 1s, width 1s ease 1s;
}

.fade-leave-active {
    transition: opacity 0.5s ease, padding 0.5s ease, width 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
    padding: 0;
    width: 0;
    opacity: 0;
}

.im-round-btn {
    border-radius: 9999px;
}
</style>