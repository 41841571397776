<template>
  <div>
    <div class="space-y-6">
      <div class="flex flex-row justify-between">
        <h2 class="text-xl uppercase text-[#c6c6c6] font-bold">
          {{ $t("message.legalSituations") }}
        </h2>
        <button
          @click="
            store.commit('showSidebar', {
              type: 'addNewLegalSituation',
              params: {
                onSave: () => {
                  fetchLegalSituations();
                },
              },
            })
          "
          class="whitespace-nowrap rounded-md text-sm font-medium transition-colors focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:pointer-events-none disabled:opacity-50 btn btn-sm bg-magenta text-white font-light text-xs shadow hover:bg-nrgGray h-9 px-4 py-2 font-bold uppercase"
        >
          {{ $t("message.addLegalSituation") }}
        </button>
      </div>
      <RobberVueGrid
        ref="legalSituationsGrid"
        :config="legalSituationsGridConfigComputed"
      />
      <input
        type="checkbox"
        class="modal-toggle"
        v-model="state.showRemoveConfirmation"
      />
      <div class="modal">
        <div class="modal-box">
          <h3
            class="text-lg text-left font-normal hover:text-gray-100 text-gray-100"
          >
            {{ $t("message.deleteLegalSituationConfirm") }}
          </h3>
          <div class="modal-action">
            <label
              class="btn btn-sm text-white font-light text-xs"
              @click="
                () => {
                  state.showRemoveConfirmation = false;
                }
              "
              >{{ $t("message.deny") }}</label
            >
            <label
              class="btn btn-sm bg-magenta text-white font-light text-xs"
              @click="
                () => {
                  deleteLegalSituation(state.rvgLegalSituationId);
                  state.showRemoveConfirmation = false;
                }
              "
              >{{ $t("message.confirm") }}</label
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed, reactive, watch } from "vue";
import { useI18n } from "vue-i18n";
import { useRoute } from "vue-router";
import store from "@/misc/vuex-store.js";
import Axios from "@/misc/axios.js";
import legalSituationsGridConfig from "@/misc/gridConfigs/legalSituationsGridConfig.js";
import RobberVueGrid from "../../components/RVG/RobberVueGrid.vue";
import Utils from "../../misc/Utils";
import toast from "@/misc/toast.js";

//TODO:
//a kötelezettségek rögzítésével és szerkesztésével ki kell egészíteni
//az ingatlan rögzítő/szerkesztő formokat.

//TODO 2: css a rem pageken
const { t } = useI18n();
const route = useRoute();
const propertyId = route.params.propertyId;
const buildingId = route.params.buildingId;

const legalSituationsGridConfigComputed = computed(() => {
  return legalSituationsGridConfig(
    t,
    propCurrentTab.value,
    propertyId,
    buildingId,
    fetchLegalSituationById,
    setRemoveConfirmation
  );
});

const fetchLegalSituationById = async (id) => {
  const response = await Utils.fetchLegalSituationById(id);

  store.commit("setLegalSituationById", response);
  store.commit("showSidebar", {
    type: "editLegalSituation",
  });
};

const state = reactive({
  legalSituations: [],
  forceRefresh: false,
  showRemoveConfirmation: false,
  rvgLegalSituationId: null,
});

const setRemoveConfirmation = (id) => {
  state.rvgLegalSituationId = id;
  state.showRemoveConfirmation = true;
};

const fetchLegalSituations = async () => {
  let uri = "";

  if (propCurrentTab.value === "propLegalSituations") {
    uri =
      process.env.VUE_APP_FRONTENDV2_API_URL +
      `/rem/legal-situation/property/${propertyId}`;
  }

  if (propCurrentTab.value === "buildingLegalSituations") {
    uri =
      process.env.VUE_APP_FRONTENDV2_API_URL +
      `/rem/legal-situation/building/${buildingId}`;
  }
  
  try {
    const response = await Axios.get(uri, {
      onTokenRefreshed: () => {
        fetchLegalSituations();
      },
    });

    state.legalSituations = response.content;
  } catch (error) {
    console.log(error);
  }
};

const deleteLegalSituation = async (id) => {
  const uri =
    process.env.VUE_APP_FRONTENDV2_API_URL + `/rem/legal-situation/${id}`;
  try {
    await Axios.delete(uri);
    toast.success(t("message.deleteSuccessful"));
  } catch (error) {
    console.log(error);
    toast.error(t("message.deleteNotSuccessful"));
  }
};

const refreshGrid = () => {
  state.forceRefresh = true;
  setTimeout(() => {
    state.forceRefresh = false;
  }, 100);
};

watch(
  () => state.legalSituations,
  () => {
    refreshGrid();
  }
);

const propCurrentTab = computed(() => {
  return store.getters.getPropCurrentTab;
});
</script>

<style scoped></style>
